import {
  Assets,
  Container,
  Graphics,
  Sprite,
  Text,
} from '@growe/lightcore';
import { App } from '@growe/lightcore';

import Currency from '../../../game/models/Currency.js';
import SmallSettingsButtonView from './SmallSettingsButtonView.js';
import SoundButtonView from './SoundButtonView.js';
import SpinButtonView from './SpinButtonView.js';
import UIButtonView from './UIButtonView.js';

const fontStyle = {
  fill: 0xffffff,
  fontFamily: 'Impact',
};

const LAYOUTS = {
  PORTRAIT: 0,
  LANDSCAPE: 1,
};

export default class InputView extends Container {
  constructor(model) {
    super();

    this.model = model;
    this._create();
    this.layouts = {
      portrait: new PortraitLayout(this),
      landscape: new LandscapeLayout(this),
    };

    this.setLayout(App.device.orientation);
  }

  setLayout(layout) {
    this.currentLayout = this.layouts[layout];
    this.currentLayout.resize();
  }

  update() {
    this.spinBtn.update();
    this.autoBtn.activated = this.model.isAutoGameActivated();
    this.autoBtn.disabled = !this.model.isAutoGameAvailable();
    this.turboBtn.activated = this.model.isTurboGameActivated();
    this.turboBtn.disabled = !this.model.isTurboGameAvailable();
    this.balance.text = Currency.getFormattedValue(
      this.model.balance,
    );
    this.bet.text = Currency.getFormattedValue(this.model.bet);
    this.lastWinIndicator.text = Currency.getFormattedValue(
      this.model.lastWin,
    );
    this.minusBtn.disabled = this.model.isBetMin();
    this.plusBtn.disabled = this.model.isBetMax();
    this.soundBtn.activated = this.model.isSoundsOn();
  }

  resize() {
    this.setLayout(App.device.orientation);
  }

  _create() {
    this.overlay = new Graphics();
    this.overlay.beginFill(0x000000);
    this.overlay.lineStyle(1, 0x000000);
    this.overlay.drawRect(0, 0, 100, 100);
    this.overlay.alpha = 0.75;

    this.addChild(this.overlay);

    this.spinBtn = new SpinButtonView(this.model);
    this.minusBtn = new UIButtonView('minus');
    this.plusBtn = new UIButtonView('plus');
    this.turboBtn = new UIButtonView('turbo');
    this.autoBtn = new UIButtonView('auto');
    this.infoBtn = new UIButtonView('info');
    this.settingButton = new UIButtonView('settings');
    this.soundBtn = new SoundButtonView();
    this.smallSettingsButton = new SmallSettingsButtonView();

    this.balanceAndBetGroup = new Container();
    this.balanceLabel = new Text('CREDIT ', {
      ...fontStyle,
      fontSize: 10,
    });
    this.balanceLabel.anchor.set(1.05, 1);
    this.balanceLabel.y = 0;
    this.balance = new Text('0', {
      ...fontStyle,
      fontSize: 12,
    });
    this.balance.anchor.set(0, 1);
    this.balance.y = 0;
    this.betLabel = new Text('BET ', {
      ...fontStyle,
      fontSize: 10,
    });
    this.betLabel.anchor.set(1.05, -0.07);
    this.betLabel.y = 0;
    this.bet = new Text('0', {
      ...fontStyle,
      fontSize: 12,
    });
    this.bet.y = 0;
    this.balanceAndBetGroup.addChild(
      this.balance,
      this.bet,
      this.balanceLabel,
      this.betLabel,
    );

    this.lastWinGroup = new Container();
    this.lastWinLabel = new Text('LAST WIN', {
      ...fontStyle,
      fontSize: 12,
    });
    this.lastWinLabel.anchor.set(0.5, 1);
    this.lastWinGroup.addChild(this.lastWinLabel);
    this.lastWinIndicator = new Text('1000,00', {
      ...fontStyle,
      fontSize: 16,
    });
    this.lastWinIndicator.anchor.set(0.5, 0);
    this.lastWinGroup.addChild(this.lastWinIndicator);

    this.addChild(
      this.overlay,
      this.minusBtn,
      this.plusBtn,
      this.turboBtn,
      this.autoBtn,
      this.infoBtn,
      this.spinBtn,
      this.soundBtn,
      this.settingButton,
      this.smallSettingsButton,
      this.balanceAndBetGroup,
      this.lastWinGroup,
    );
  }
}

class LandscapeLayout {
  constructor(view) {
    this.view = view;
  }

  resize() {
    this.view.spinBtn.scale.set(1);
    this.view.minusBtn.scale.set(1);
    const uiTop = App.layout.getGridLineY('ui_top');
    const uiBottom = App.layout.getGridLineY('ui_bottom');
    const uiDarkTop = App.layout.getGridLineY('ui_dark_top');
    const uiDarkCenter = (uiDarkTop + uiBottom) / 2;
    const uiCenter = (uiTop + uiBottom) / 2;
    const uiButtonsTop = (uiTop + uiBottom) / 2 - 2;
    const uiButtonsY = (uiButtonsTop + uiBottom) / 2;
    const uiHeight = uiBottom - uiTop;
    const right = App.device.width;
    // todo: magic number
    const scaleSpinBtn = uiHeight / 224;

    this.view.spinBtn.scale.set(scaleSpinBtn);
    this.view.spinBtn.position.set(App.device.width * 0.5, uiCenter);
    const spinButtonWidth = 224 * scaleSpinBtn;

    const scaleUiButton =
      (uiBottom - uiButtonsTop) / this.view.minusBtn.height;
    this.view.minusBtn.scale.set(scaleUiButton);
    this.view.minusBtn.position.set(
      App.device.width * 0.5 - 55,
      uiButtonsY,
    );

    this.view.plusBtn.position.set(
      App.device.width * 0.5 + 55,
      uiButtonsY,
    );
    this.view.plusBtn.scale.set(scaleUiButton);

    this.view.turboBtn.position.set(
      App.device.width * 0.5 - 93,
      uiButtonsY,
    );
    this.view.turboBtn.scale.set(scaleUiButton);

    this.view.autoBtn.position.set(
      App.device.width * 0.5 + 93,
      uiButtonsY,
    );
    this.view.autoBtn.scale.set(scaleUiButton);

    this.view.infoBtn.position.set(
      App.device.width - 50,
      uiDarkCenter,
    );
    this.view.infoBtn.scale.set(scaleUiButton * 0.85);

    this.view.soundBtn.position.set(
      right - 20,
      App.device.height - 45,
    );
    this.view.soundBtn.visible = true;

    this.view.smallSettingsButton.position.set(
      right - 20,
      App.device.height - 15,
    );
    this.view.smallSettingsButton.visible = true;

    this.view.balanceAndBetGroup.position.set(
      (App.layout.getGridLineX('jackpots_center') +
        App.layout.getGridLineX('reels_left')) /
        2,
      uiDarkCenter,
    );
    this.view.balanceAndBetGroup.visible = true;

    this.view.lastWinGroup.position.set(
      (App.layout.getGridLineX('x2_center') +
        App.layout.getGridLineX('reels_right')) /
        2,
      uiDarkCenter,
    );
    this.view.lastWinGroup.visible = true;

    this.view.overlay.position.set(0, uiDarkTop);
    this.view.overlay.width = App.device.width + 5;
    this.view.overlay.height = uiHeight;

    this.view.settingButton.visible = false;
  }
}

class PortraitLayout {
  constructor(view) {
    this.view = view;
  }

  resize() {
    this.view.spinBtn.scale.set(1);
    this.view.spinBtn.spine.skeleton.setToSetupPose();
    const uiTop = App.layout.getGridLineY('ui_spin_top');
    const uiBottom = App.layout.getGridLineY('ui_bottom');
    const uiHeight = uiBottom - uiTop;
    const uiCenter = (uiTop + uiBottom) / 2;
    const scale = uiHeight / 224;

    this.view.spinBtn.scale.set(scale);
    this.view.spinBtn.position.set(App.device.width * 0.5, uiCenter);

    this.view.minusBtn.position.set(
      App.device.width / 3 - 40,
      uiCenter,
    );
    this.view.minusBtn.scale.set(0.3);

    this.view.plusBtn.position.set(
      (2 * App.device.width) / 3 + 40,
      uiCenter,
    );
    this.view.plusBtn.scale.set(0.3);

    this.view.turboBtn.position.set(
      (5 * App.device.width) / 6 + 20,
      uiCenter + 20,
    );
    this.view.turboBtn.scale.set(0.3);

    this.view.autoBtn.position.set(
      (5 * App.device.width) / 6 + 20,
      uiCenter - 20,
    );
    this.view.autoBtn.scale.set(0.3);

    this.view.infoBtn.position.set(
      App.device.width / 6 - 20,
      uiCenter - 20,
    );
    this.view.infoBtn.scale.set(0.3);

    this.view.settingButton.position.set(
      App.device.width / 6 - 20,
      uiCenter + 20,
    );
    this.view.settingButton.scale.set(0.3);

    this.view.soundBtn.visible = false;
    this.view.smallSettingsButton.visible = false;
    this.view.balanceAndBetGroup.visible = false;
    this.view.lastWinGroup.visible = false;
    this.view.settingButton.visible = true;

    // this.view.overlay.anchor.set(0, 1);
    this.view.overlay.position.set(0, App.device.height);
  }
}

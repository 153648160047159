/**
 * @typedef {Object} INetwork
 * @property {function(): string} baseAssetsUrl - A method that returns the base assets url.
 * @property {function(): string} baseUrl - A method that returns the base url.
 * @property {function(): Object} createRequestInit - A method that receives data for a request and creates a request initialization object.
 * @property {function(string, Object): Promise<Object>} createRequest - A method that sends a network request to given URL using the provided request parameters.
 * @property {function(Object): Promise<Object>} init - A method that initializes the network by sending a request.
 * @property {function(Object): Promise<Object>} bet - A method that sends a bet request.
 * @property {function(): Promise<Object>} freespin - A method that sends a freespin request.
 * @property {function(): Promise<Object>} respin - A method that sends a respin request.
 * @property {function(): Promise<Object>} buyFreespins - A method that sends a buying freespin request.
 * @property {function(): Promise<Object>} buyBonus - A method that sends a buying respin request.
 * @property {string} _token - The play_token parameter from the URL.
 * @property {string} _serverUrl - The server_url parameter from the URL.
 */

/**
 * A class representing a NullNetwork.
 * @class
 */
export default class NullNetwork {
  constructor() {
    this.name = 'NullNetwork';
    this.serviceType = 'network';
    this._token = '';
    this._serverUrl = '';
  }

  baseAssetsUrl() {
    return '';
  }

  baseUrl() {
    return '';
  }

  createRequestInit() {
    return {};
  }

  async createRequest(url, requestParams) {
    return {};
  }

  async get(url, data) {
    return {};
  }
}

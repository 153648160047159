export default class AutoSpinsWindow {
  constructor(model, view) {
    this.view = view;
    this.model = model;

    this.view.presets.forEach((preset, i) => {
      preset.on(
        'pointerup',
        this.handlePointerUp.bind(this, i),
        this,
      );
    });

    this.view.on('start', this.handleStart, this);
  }

  handlePointerUp(i) {
    console.log(i);
    this.model.selectedAutoSpinsPreset = i;
  }

  handleStart() {
    this.model.setAutoSpins(this.model.autoSpinsPresetAmount);
  }
}

import { Polygon } from '@growe/lightcore';

export default class EmbeddedSpineButton {
  constructor(spine, btnName, trackPressed = 0, trackHover = 1) {
    this.spine = spine;
    this.btnName = btnName;
    this.trackPressed = trackPressed;
    this.trackHover = trackHover;
    this.btnSlotIndex = this.spine.skeleton.findSlotIndex(
      this.btnName,
    );
    this.container = this.spine.slotContainers[this.btnSlotIndex];
    this.bbxSlot = this.spine.skeleton.findSlot(
      this.btnName + '_bbx',
    );
    this.container.eventMode = 'static';
    this.container.hitArea = new Polygon(
      Array.from(this.bbxSlot.attachment.vertices),
    );
    this.container.cursor = 'pointer';
    this.container.on('pointerup', () => {
      if (!this.isPressed) return;
      this.spine.state.setAnimation(
        trackPressed,
        this.btnName + '/up',
        false,
      );
    });
    this.container.on('pointerdown', () => {
      this.spine.state.setAnimation(
        trackPressed,
        this.btnName + '/down',
        false,
      );
      this.isPressed = true;
    });
    this.container.on('pointerupoutside', () => {
      if (!this.isPressed) return;
      this.spine.state.setAnimation(
        trackPressed,
        this.btnName + '/upoutside',
        false,
      );
    });
    this.container.on('pointerover', () => {
      this.spine.state.setAnimation(
        trackHover,
        this.btnName + '/hover',
        false,
      );
    });
    this.container.on('pointerout', () => {
      this.spine.state.setAnimation(
        trackHover,
        this.btnName + '/out',
        false,
      );
    });
    this.container.on('click', () => {});
  }

  updateHitArea() {
    this.container.hitArea = new Polygon(
      Array.from(this.bbxSlot.attachment.vertices),
    );
  }

  disable() {
    this.container.eventMode = 'none';
    this.spine.state.setAnimation(
      this.trackHover,
      this.btnName + '/disable',
    );
  }

  enable() {
    this.container.eventMode = 'static';
    this.spine.state.setAnimation(
      this.trackHover,
      this.btnName + '/out',
    );
  }
}

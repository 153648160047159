import {
  Container,
  Graphics,
  Text,
  TextStyle,
} from '@growe/lightcore';
import { App } from '@growe/lightcore';
import { isMobile } from '@pixi/core';

import { SETTINGS } from '../../../config/settings.js';
import SettingSwitcher from './SettingSwitcher.js';
import UIButtonView from './UIButtonView.js';

const WIDTH = 576;
const HEIGHT = 320;
const X = -WIDTH / 2;
const Y = -HEIGHT / 2;
const RADIUS = 0;

export default class SettingsWindow extends Container {
  constructor(model) {
    super();
    this.model = model;
    this.presets = [];
    this._create();
  }

  update() {
    this.fullscreenSwitcher.checked = this.model.isFullScreen();
    this.fullscreenSwitcher.disabled =
      !this.model.isFullScreenEnabled();
    this.fullScreenLabel.alpha = this.model.isFullScreenEnabled()
      ? 1
      : 0.3;
    this.quickSpinsSwitcher.checked =
      this.model.isTurboGameActivated();
    this.soundSwitcher.checked = this.model.isSoundsOn();
  }

  resize() {
    const w =
      SETTINGS.app.viewport.width * App.viewport.cropWidthRatio;
    if (App.device.orientation === 'portrait') {
      const factor = Math.min(w / WIDTH, 0.65);
      this.scale.set(factor);
      this.x = App.device.width / 2;
      this.y = App.device.height - (HEIGHT * factor) / 2 - 30;
    } else {
      this.x = App.device.width / 2;
      this.y = App.device.height / 2;
      this.scale.set(isMobile.phone ? 1 : 0.5);
    }
  }

  _create() {
    this._createBackground();

    const group = new Container();
    group.x = X;
    group.y = Y;
    this.addChild(group);

    const headerText = new Text('SETTINGS', {
      fontSize: 48,
      fill: 0xffffff,
      align: 'center',
      fontWeight: '600',
      fontFamily: 'Teko',
    });
    headerText.x = WIDTH / 2;
    headerText.y = 14;
    headerText.anchor.set(0.5, 0);
    group.addChild(headerText);

    const closeBtn = new UIButtonView('close_modal_window');
    closeBtn.position.set(530, 40);
    closeBtn.on('pointerup', () => {
      this.emit('close');
    });
    group.addChild(closeBtn);

    const quickSpinsSwitcher = new SettingSwitcher();
    quickSpinsSwitcher.position.set(500, 130);
    const soundSwitcher = new SettingSwitcher();
    soundSwitcher.position.set(500, 200);
    const fullscreenSwitcher = new SettingSwitcher();
    fullscreenSwitcher.position.set(500, 270);

    const labelStyle = new TextStyle({
      fontSize: 40,
      fill: 0xffffff,
      align: 'left',
      fontWeight: '500',
      fontFamily: 'Teko',
    });

    const quickSpinsLabel = new Text('Quick Spins', labelStyle);
    quickSpinsLabel.anchor.set(0, 0.5);
    quickSpinsLabel.position.set(30, 130);
    const soundLabel = new Text('Sounds', labelStyle);
    soundLabel.anchor.set(0, 0.5);
    soundLabel.position.set(30, 200);
    const fullScreenLabel = new Text('Full Screen', labelStyle);
    fullScreenLabel.anchor.set(0, 0.5);
    fullScreenLabel.position.set(30, 270);

    group.addChild(
      quickSpinsSwitcher,
      soundSwitcher,
      fullscreenSwitcher,
      quickSpinsLabel,
      soundLabel,
      fullScreenLabel,
    );

    this.quickSpinsSwitcher = quickSpinsSwitcher;
    this.soundSwitcher = soundSwitcher;
    this.fullscreenSwitcher = fullscreenSwitcher;
    this.fullScreenLabel = fullScreenLabel;
  }

  _createBackground() {
    const graphics = new Graphics();
    graphics.beginFill(0x19222f);
    graphics.drawRoundedRect(X, Y, WIDTH, HEIGHT, RADIUS);
    graphics.beginFill(0x4d607c);
    graphics.drawRoundedRect(X, Y, WIDTH, 81, RADIUS);

    this.addChild(graphics);
  }
}

import {
  App,
  Assets,
  Container,
  EVENTS,
  Graphics,
  Sprite,
  Texture,
} from '@growe/lightcore';
import { gsap } from 'gsap';

import Currency from '../../../game/models/Currency';
import Lines from '../../../game/models/Lines.js';
import {
  createThresholdCallback,
  delay,
  waitForEventOnce,
} from '../../../utils/utils.js';
import AnticipationFrame from '../AnticipationFrame.js';
import BasicWin from '../BasicWin.js';
import Reel from './Reel.js';
import BigWinPopup from './winPopups/BigWinPopup';
import MegaWinPopup from './winPopups/MegaWinPopup';
import SuperMegaWinPopup from './winPopups/SuperMegaWinPopup';

export const SPEED_MODES = {
  REGULAR: 0,
  TURBO: 1,
};

/**
 * Represents the slot machine
 */
export default class ReelsetView extends Container {
  constructor(model) {
    super();
    this.model = model;
    this.init();
  }

  init() {
    this.config = Assets.get('game_config');
    this.sortableChildren = true;

    this.pivot.set(245, 214);
    // this.forceStop = false;

    /**
     * Anticipation is checked if it's true
     * and off if it's false
     * @type {boolean}
     */
    // this.anticipation = true;

    this.speedModes = [
      new NormalSpeedMode(this),
      new TurboSpeedMode(this),
    ];

    this._createOverlay();
    this._createReels();
    this._createAnticipationFrames();
    this._createBasicWin();
    this._initMask();

    this.setTurboMode(this.model.turboMode);
    this.update();
  }

  setTurboMode(turboMode) {
    const speedMode = turboMode
      ? SPEED_MODES.TURBO
      : SPEED_MODES.REGULAR;
    this._speedMode = this.speedModes[speedMode];
    console.error('Speed Mode', speedMode);
  }

  // todo: model mustnt know about view
  set(map) {
    map.forEach((reel, i) => {
      reel.forEach((slot, j) => {
        this.model.map[i][j] = slot;
      });
      this.reels[i].updateSlotsProps();
    });
  }

  idle() {
    this.overlay.visible = false;
    this.reels.forEach((reel) => {
      reel.idle();
    });
    // this.basicWin.hide();
  }

  spin() {
    this.emit('start');
    App.events.emit(EVENTS.SPIN.START);

    const countReelStartedHandler = createThresholdCallback(
      this.reels.length,
      () => {
        this.emit('machineStarted', {
          reel: this,
        });
      },
    );

    const handleReelStarted = (i) => {
      this.reels[i].slotsVisibility = true;
    };

    this.reels.forEach((reel, i) => {
      reel.once('reelStarted', countReelStartedHandler, this);
      reel.once('reelStarted', () => handleReelStarted(i));
    });

    this.spinResult = null;
    this._speedMode.spin();
    this.isSpinning = true;
  }

  async stop(spinResult) {
    this.spinResult = spinResult;
    // await Promise.all([
    //   this.reels.map(
    //     async (reel, i) => await reel.isStoppedPromise(),
    //   ),
    // ]);
    //
    // this.emit('modelStopped');
    // this.isSpinning = false;

    return new Promise((resolve) => {
      const countHandler = createThresholdCallback(
        this.reels.length,
        () => {
          this.emit('modelStopped');
          this.isSpinning = false;
          resolve(true);
        },
        (e) => {
          this.emit('reelStopped', e);
        },
      );

      this.reels.forEach((reel, i) => {
        // reel.shouldAnticipate = spinResult.anticipation[i];
        reel.once('reelStopped', countHandler, this);
      });
    });
  }

  restore(spinResult) {
    this.spinResult = spinResult;
    this.restoreSlots(spinResult);
  }

  restoreSlots(spinResult) {
    for (let r = 0; r < this.config.reels; r++) {
      for (let s = 0; s < this.config.symbols; s++) {
        const { key, value } = spinResult.extractRecordFromPool(r);
        this.model.unshiftSymbol(r, key, value);
      }
    }
    this.reels.forEach((reel) => reel.updateSlotsProps());
  }

  handleInput(spinButton, quickButton) {
    this._speedMode.handleInput(spinButton, quickButton);
  }

  // todo: fully configurable reelset
  get symbolSize() {
    return {
      width: this.config.width / this.config.reels,
      height: this.config.height / this.config.slots,
    };
  }

  getSymbolPosition(reel, symbol) {
    return {
      x: this.symbolSize.width * (reel + 0.5),
      y: this.symbolSize.height * (symbol + 0.5),
    };
  }

  async showWins() {
    this.showWinsPromise = new Promise((resolve) => {});
    if (this.spinResult.winLines.length > 1) {
      this.showTotalWin();
    } else {
      this.showLineWin(this.spinResult.winLines[0]);
    }

    this.showAllLines(this.spinResult.winLines);
    this.animateAllWinSlots(this.spinResult.allWinSlots);

    await this.showWinsPromise;
  }
  startToggleWinLines() {
    this.isToggleWinLines = true;
    this.toggleWinLines();
  }

  stopToggleWinLines() {
    this.reels.forEach((reel) => reel.brightenSlots());
    this.isToggleWinLines = false;
  }

  async toggleWinLines() {
    if (!this.isToggleWinLines) return;
    for (const winLine of this.spinResult.winLines) {
      if (!this.isToggleWinLines) return;
      this.showLineWin(winLine);
      const allWinSlots = winLine[2].map((symbol) => [symbol]);
      this.animateAllWinSlots(allWinSlots);
      await delay(1000);
    }

    if (!this.isToggleWinLines) return;
    setTimeout(() => this.toggleWinLines(), 0);
  }

  hideWins() {
    this.basicWin.hide();
  }

  showTotalWin() {
    this.basicWin.value = Currency.getFormattedValue(
      this.spinResult.win,
    );
    this.basicWin.position.set(
      this.config.width * 0.5,
      this.config.height * 0.5,
    );
    this.basicWin.show();
  }

  showWin(value) {
    this.basicWin.value = Currency.getFormattedValue(value);
    this.basicWin.position.set(
      this.config.width * 0.5,
      this.config.height * 0.5,
    );
    this.basicWin.show();
  }

  /**
   *
   * @param {LineData} lineData
   */
  showLineWin(lineData) {
    this.basicWin.value = Currency.getFormattedValue(lineData[1]);
    this.basicWin.position.set(
      this.config.width * 0.5,
      // todo: find center of reels othervise
      this.getSymbolPosition(2, Lines.get(lineData[3])[1]).y,
    );
    this.basicWin.show();
  }

  handleShiftSymbols(i) {
    this._speedMode.handleShiftSymbols(i);
  }

  handleEndShiftSymbols(i) {
    this._speedMode.handleEndShiftSymbols(i);
  }

  /**
   * Animate win line based on provided win line data
   *
   * @param {LineData} winLineData - Array containing win line data
   * @return {void}
   */
  animateWinLine(winLineData) {
    const [, , map] = winLineData;
    this.animateAllWinSlots(map.map((slot) => [slot]));
    App.events.once(
      'SLOTS_SHOW_WINS_END',
      () => this.showWinsPromise,
    );
  }

  /**
   *
   * @param {number[][]} allWinSlots - Ex: [[0, 1, 2 ,3], [1, 3], [1]]
   */
  animateAllWinSlots(allWinSlots) {
    this._speedMode.win(allWinSlots);
  }

  anticipate(reelIndex) {
    this.reels.forEach((reel, i) => {
      if (i < reelIndex) {
        reel.highlightScatters();
        reel.highlightWilds();
        reel.darkenCommonSymbols();
      } else if (reelIndex === i) {
        this.reels[reelIndex].anticipate();
      }
    });
    this.overlay.visible = true;
  }

  skipAnticipation() {
    this.reels.forEach((reel) => {
      reel.shouldAnticipate = false;
      reel.skipAnticipation();
    });
  }

  /**
   * Show all lines on the lines container.
   *
   * @param {LineData[]} winLines - The array of win lines to be shown. ["line", 15, [2, 1, 2], 7]
   * @return {void}
   */
  showAllLines(winLines) {
    // winLines.forEach(record => {
    //   this.lines[record[3]].win()
    // })
  }

  _createReels() {
    this.reels = [];
    for (let i = 0; i < this.config.reels; i++) {
      const reel = new Reel(this.model.map[i], this);
      reel.distance = this.symbolSize.height;
      reel.zIndex = 0;
      const position = this.getSymbolPosition(i, 0);
      reel.position.set(position.x, position.y);
      this.reels.push(reel);
      reel.on('shiftSymbols', () => this.handleShiftSymbols(i), this);
      reel.on(
        'endShiftSymbols',
        () => this.handleEndShiftSymbols(i),
        this,
      );
    }
    this.addChild(...this.reels);
  }

  _createOverlay() {
    this.overlay = new Sprite(Texture.WHITE);
    this.addChild(this.overlay);
    this.overlay.width = this.config.width;
    this.overlay.height = this.config.height;
    this.overlay.anchor.set(0.5);
    this.overlay.x = this.config.width / 2;
    this.overlay.y = this.config.height / 2;
    this.overlay.tint = 0x0;
    this.overlay.alpha = 0.5;
    this.overlay.visible = false;
    this.overlay.zIndex = 2;
  }

  _createAnticipationFrames() {
    this.anticipationFrames = [];
    for (let i = 0; i < this.config.reels; i++) {
      const frame = new AnticipationFrame();
      const position = this.getSymbolPosition(
        i,
        (this.config.slots - 1) / 2,
      );
      frame.zIndex = 4;
      frame.x = position.x;
      frame.y = position.y;
      frame.hide();
      this.reels[i].on('anticipationStart', () => {
        this.emit('anticipationStart', { reelIndex: i });
        frame.show();
      });
      this.reels[i].on('anticipationEnd', () => {
        this.emit('anticipationEnd');
        frame.hide();
      });
      this.anticipationFrames.push(frame);
    }
    this.addChild(...this.anticipationFrames);
  }

  _createBasicWin() {
    this.basicWin = new BasicWin();
    this.basicWin.zIndex = 1000;
    this.basicWin.position.set(
      this.config.width * 0.5,
      this.config.height * 0.5,
    );
    this.addChild(this.basicWin);

    this.basicWin.hidden();
  }

  // todo: when will it be used?
  destroy(...args) {
    this.reels?.forEach((reel) => {
      reel.destroy();
    });
    super.destroy(...args);
  }

  _initMask() {
    const mask = new Graphics();
    mask.beginFill(0xffffff);
    mask.drawRect(-7, -10, 500, 436);
    mask.endFill();

    this.mask = mask;
    this.addChild(mask);

    // const mask2 = new Graphics();
    // mask2.beginFill(0xffffff);
    // mask2.drawRect(-7, -10, 500, 436);
    // mask2.endFill();
    // this.addChild(mask2);
  }

  resize() {
    const reelsTop = App.layout.getGridLineY('reels_top');
    const reelsBottom = App.layout.getGridLineY('reels_bottom');
    const availableHeight = (reelsBottom - reelsTop) * 0.92;
    const reelsCenterY = (reelsTop + reelsBottom) / 2 + 4;
    this.position.set(App.device.width * 0.5, reelsCenterY);
    this.scale.set(1);
    this.scale.set(availableHeight / this.mask.height);
    this.popup && this.popup.resize();
  }

  update(dt) {
    this.reels.forEach((reel) => reel.update(dt));
    this.basicWin.update(dt);
    this.popup && this.popup.update(dt);
  }

  showWinPopup(awardMultiplier, award) {
    if (this.popup) {
      // todo: need to have 3 popups and use them not to create new
      this.popup.destroy();
    }

    if (awardMultiplier > 1000) {
      return this.showSuperMegaWin(award);
    }

    if (awardMultiplier > 200) {
      return this.showMegaWin(award);
    }

    return this.showBigWin(award);
  }
  async showSuperMegaWin(award) {
    console.error('showSuperMegaWin', award);
    this.popup = new SuperMegaWinPopup();
    this.popup.resize();
    this.popup.value = award;
    App.layers.getLayer('win_popups').addChild(this.popup);
    await this.popup.show();
    this.popup.hide();

    // todo: use animation callbacks or promises
    await delay(2000);
  }
  async showMegaWin(award) {
    console.error('showMegaWin', award);
    this.popup = new MegaWinPopup();
    this.popup.resize();
    this.popup.value = award;
    App.layers.getLayer('win_popups').addChild(this.popup);
    await this.popup.show();
    this.popup.hide();
    // todo: use animation callbacks or promises
    await delay(2000);
  }
  async showBigWin(award) {
    console.error('showBigWin', award);
    this.popup = new BigWinPopup();
    this.popup.resize();
    this.popup.value = award;
    App.layers.getLayer('win_popups').addChild(this.popup);
    await this.popup.show();
    this.popup.hide();
    // todo: use animation callbacks or promises
    await delay(2000);
  }
}

/**
 * The class represents 'regular' speed mode
 */
class NormalSpeedMode {
  constructor(view) {
    this.view = view;
  }

  spin() {
    this.view.reels.forEach((reel, i) => {
      reel.spin(0.15 * i);
    });
  }

  handleShiftSymbols(i) {
    App.logger.debug(`Reel handle: ${i}`, this.view.model.spinResult);
    if (!this.view.model.spinResult) {
      this.view.model.unshiftSymbol(i);
      return;
    }

    if (i !== 0 && !this.view.reels[i - 1].isStopped) {
      App.logger.debug(`Reel stopped: ${i}`);
      this.view.model.unshiftSymbol(i);
      return;
    }

    if (this.anticipation(i)) return;

    if (this.view.model.spinResult.isReelPoolEmpty(i)) {
      return;
    }
    const { key, value, stuck } =
      this.view.spinResult.extractRecordFromPool(i);
    this.view.model.unshiftSymbol(i, key, value, stuck);

    App.logger.debug(`Reel set ${i}`, key, value, stuck);
  }

  handleEndShiftSymbols(i) {
    if (!this.view.model.spinResult) return;
    this.view.emit('endShiftSymbols', {
      reel: this.view.reels[i],
    });
    if (this.view.model.spinResult.isReelPoolEmpty(i)) {
      this.view.reels[i].stop();
      this.view.emit('reelStop', {
        reel: this.view.reels[i],
      });
    }
  }

  anticipation(i) {
    if (!this.view.anticipation) return false;
    if (this.view.reels[i].shouldAnticipate) {
      this.view.anticipate(i);
    }
    if (this.view.reels[i].isAnticipating) {
      this.view.model.unshiftSymbol(i);
      return true;
    }
    return false;
  }

  handleInput(spinButton, quickButton) {
    if (this.view.forceStop) {
      this.view.setSpeedMode(SPEED_MODES.TURBO);
    } else if (quickButton.enable) {
      this.view.setSpeedMode(SPEED_MODES.TURBO);
    } else if (spinButton.isPress) {
      this.view.setSpeedMode(SPEED_MODES.TURBO);
    }
  }

  win(allWinSlots) {
    const map = new Array(this.view.config.reels)
      .fill(0)
      .map((_, i) => {
        return allWinSlots[i] ? allWinSlots[i] : [];
      });
    const n = allWinSlots.filter((slots) => slots.length > 0).length;
    const handler = createThresholdCallback(1, () => {
      App.events.emit('SLOTS_SHOW_WINS_END');
    });

    this.view.reels.forEach((reel) => reel.brightenSlots());
    this.view.overlay.visible = true;

    const darkenMap = map.map((slots) => {
      const arr = [];
      for (let i = 0; i < this.view.config.slots; i++) {
        if (!slots.includes(i)) {
          arr.push(i);
        }
      }
      return arr;
    });

    this.view.reels.forEach((reel, i) => {
      reel.zIndex = 0;
      if (darkenMap[i].length > 0) {
        reel.darkenSlots(darkenMap[i]);
      }
      if (map[i].length > 0) {
        reel.zIndex = 4;
        reel.animateWinSlots(map[i]);
        reel.once('slotsWinEnd', handler, this);
      }
    });
  }
}

/**
 * The class represents 'turbo' speed mode
 */
class TurboSpeedMode {
  constructor(model) {
    this.view = model;
  }

  spin() {
    this.view.reels.forEach((reel) => {
      reel.quickSpin();
    });
  }

  handleShiftSymbols(i) {
    if (!this.view.spinResult) {
      this.view.model.unshiftSymbol(i);
      return;
    }

    // sync reelset
    if (
      i !== 0 &&
      this.view.spinResult.pool[i - 1].length ===
        this.view.config.symbols
    ) {
      this.view.unshiftSymbol(i);
      return;
    }

    if (this.view.spinResult.isReelPoolEmpty(i)) {
      return;
    }

    if (this.anticipation(i)) return;

    const { key, value, stuck } =
      this.view.spinResult.extractRecordFromPool(i);
    this.view.model.unshiftSymbol(i, key, value, stuck);
  }

  handleEndShiftSymbols(i) {
    this.view.emit('handleEndShiftSymbols');
    if (!this.view.spinResult) return;
    if (this.view.spinResult.isReelPoolEmpty(i)) {
      this.view.reels[i].quickStop();
      this.view.emit('quickStop: reel', i);
    }
  }

  anticipation(i) {
    if (!this.view.anticipation) return false;

    if (i !== 0 && this.view.reels[i - 1].shouldAnticipate) {
      this.view.unshiftSymbol(i);
      return true;
    }

    if (i !== 0 && this.view.reels[i - 1].isAnticipating) {
      this.view.unshiftSymbol(i);
      return true;
    }

    if (
      i !== 0 &&
      !this.view.reels[i - 1].isStopped &&
      this.view.reels[i].shouldAnticipate
    ) {
      this.view.unshiftSymbol(i);
      return true;
    }

    if (this.view.reels[i].shouldAnticipate) {
      this.view.anticipate(i);
    }

    if (this.view.reels[i].isAnticipating) {
      this.view.unshiftSymbol(i);
      return true;
    }

    return false;
  }

  handleInput(spinButton, quickButton) {
    if (
      !quickButton.enable &&
      !spinButton.isPress &&
      !this.view.forceStop &&
      !this.view.isSpinning
    ) {
      this.view.setSpeedMode(SPEED_MODES.REGULAR);
    }
  }

  win(allWinSlots) {
    const map = new Array(this.view.config.reels)
      .fill(0)
      .map((_, i) => {
        return allWinSlots[i] ? allWinSlots[i] : [];
      });

    this.view.reels.forEach((reel) => reel.brightenSlots());
    this.view.overlay.visible = true;

    const darkenMap = map.map((slots) => {
      const arr = [];
      for (let i = 0; i < this.view.config.slots; i++) {
        if (!slots.includes(i)) {
          arr.push(i);
        }
      }
      return arr;
    });

    this.view.reels.forEach((reel, i) => {
      if (darkenMap[i].length > 0) {
        reel.darkenSlots(darkenMap[i]);
      }
    });

    gsap.to({}, { duration: 0.5 }).eventCallback('onComplete', () => {
      App.events.emit('SLOTS_SHOW_WINS_END');
    });
  }
}

import { App, Assets } from '@growe/lightcore';
import { Spine } from 'pixi-spine';

export default class Jackpot extends Spine {
  constructor(skin) {
    super(Assets.get('jackpots').spineData);
    this.resize();
    this.setSkin(skin);
    this.setAnimation('idle');
  }

  setSkin(skin) {
    this.skeleton.setSkin(null);
    this.skeleton.setSkinByName(skin);
    this.skeleton.setToSetupPose();
  }

  setState(state) {
    this.currentState = this.states[state];
    this.currentState.enter();
  }

  setMainEnvironment() {
    this.state.setAnimation(0, 'idle', true);
    this.resize();
  }

  setAnimation(animation) {
    App.logger.debug('LogoView:', animation);
    if (!animation) return;
    this.state.setAnimation(1, animation, true);
  }

  handleInput(inputs) {
    this.currentState.handleInput(inputs);
    this.visibility.handleInput(inputs);
  }

  resize() {
    if (App.device.orientation === 'landscape') {
      this.visible = true;
      // this.scale.set(0.2);
    }

    this.setAnimation('idle');
  }
}

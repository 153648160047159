import EventEmitter from 'eventemitter3';

/**
 * Represents Clock
 * @event tick
 */
export default class Clock extends EventEmitter {
  constructor() {
    super();
    this.name = 'NullClock';
    this.serviceType = 'clock';
    this.elapsedTime = 0;
    this.deltaMS = 0;
    this.start = 0;
    this.fps = 0;
  }

  run() {
    this.tick(performance.now());
  }

  tick(timestamp) {
    if (this.start === 0) {
      this.start = timestamp;
      this.previousTimeStamp = timestamp;
    }
    this.elapsedTime = timestamp - this.start;
    this.deltaMS = timestamp - this.previousTimeStamp;
    this.previousTimeStamp = timestamp;
    this.emit('tick');
    requestAnimationFrame((delta) => this.tick(delta));
  }
}

import { App, Assets } from '@growe/lightcore';

import Lines from './Lines';
import SpinResult from './SpinResult';

export default class ReelsetModel {
  constructor() {
    this.config = Assets.get('game_config');
    const { symbols, reels, slots } = this.config;
    this.keys = symbols;
    this.size = { reels, slots };
    this.map = this._create(reels, slots);

    Lines.init();
  }

  unshiftSymbol(reel, key, value = 0, stuck = false) {
    const record = {
      key,
      value,
      stuck,
    };
    if (!key) {
      record.key = this._getRandomKey();
    }
    this.map[reel].unshift(record);
    this.map[reel].pop();
  }

  _create(reels, slots) {
    const map = [];
    for (let reel = 0; reel < reels; reel++) {
      const reelMap = [];
      for (let symbol = 0; symbol <= slots; symbol++) {
        const record = {
          key: this._getRandomKey(),
          value: 0,
        };
        reelMap.push(record);
      }
      map.push(reelMap);
    }
    return map;
  }

  _getRandomKey() {
    return this.keys[Math.floor(Math.random() * this.keys.length)];
  }

  getSpinResult() {
    const rawData = App.repository.getCache('spinCurrent').get('raw');
    this.spinResult = new SpinResult(rawData);
    return this.spinResult;
  }

  clearSpinResult() {
    this.spinResult = null;
  }

  get turboMode() {
    return App.repository.getCache('ui').get('turboMode');
  }
}

import { Background } from './background/background';
import { BuyBonus } from './buyBonus/buyBonus';
import { FeaturePreview } from './featurePreview/featurePreview';
import { Gamble } from './gamble/gamble';
import { Init } from './init/init';
import { Jackpots } from './jackpots/jackpots';
import { Loader } from './loader/loader';
import { Logo } from './logo/logo';
import { Network } from './network/network';
import { Overlay } from './overlay/overlay';
import { ReelsBackground } from './reelsBackground/reelsBackground';
import { Reelset } from './reelset/reelset';
import { Ui } from './ui/ui';

export const createComponents = () => {
  new Loader({ name: 'Loader' });
  new Init({ name: 'Init' });
  new FeaturePreview({ name: 'FeaturePreview' });
  new Background({ name: 'Background' });
  new Logo({ name: 'Logo' });
  new ReelsBackground({ name: 'ReelsBackground' });
  new Reelset({ name: 'Reelset' });
  new Ui({ name: 'Ui' });
  new Network({ name: 'Network' });
  new BuyBonus({ name: 'BuyBonus' });
  new Gamble({ name: 'Gamble' });
  new Overlay({ name: 'Overlay' });
  new Jackpots({ name: 'Jackpots' });
};

import { App } from '@growe/lightcore';
import 'pixi-spine';

import '../public/main.css';
import { addLayoutGridLines } from './app/LayoutGridSetup';
import MockNetwork from './app/MockNetwork';
import { createComponents } from './components';
import { SETTINGS } from './config/settings';
import './extensions/BasePathInjector.js';
import './extensions/GlobalPointerEvents.js';
import './extensions/loadHtml.js';

App.init(SETTINGS);
App.logger.info(`Name: ${__GAME_NAME}, Version: ${__VERSION}`);
App.logger.info(`GL: ${__DEPENDENCIES['@growe/lightcore']}`);
App.logger.info(`Settings: `, SETTINGS);

const titleElement = document.querySelector('title');
titleElement.textContent = __GAME_NAME;
const versionElement = document.querySelector('meta[name="version"]');
versionElement.setAttribute('content', __VERSION);
const glVersionElement = document.querySelector(
  'meta[name="gl-version"]',
);
glVersionElement.setAttribute(
  'content',
  __DEPENDENCIES['@growe/lightcore'],
);

document.body.addEventListener('contextmenu', (e) =>
  e.preventDefault(),
);

const content = document.createElement('div');
content.classList.add('content');
document.body.appendChild(content);
const container = document.createElement('div');
container.classList.add('canvas-wrapper');
content.appendChild(container);
App.renderer.view.classList.add('webgl');
container.appendChild(App.renderer.view);

const spacer = document.createElement('div');
spacer.classList.add('spacer');
document.body.appendChild(spacer);
App.provide(new MockNetwork());
App.settings.init(SETTINGS, 'game');

App.fsm.init();
createComponents();
addLayoutGridLines();
App.fsm.start().then(() => {
  App.start();
});

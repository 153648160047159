import { Assets } from '@growe/lightcore';

import WinPopup from './WinPopup.js';

export default class MegaWinPopup extends WinPopup {
  constructor() {
    super('mega_win_popup');
  }

  getStyle() {
    return {
      fontFamily: 'Inter-Bold',
      align: 'center',
      dropShadow: true,
      dropShadowAlpha: 16,
      dropShadowAngle: 0,
      dropShadowBlur: 8,
      dropShadowColor: '#fe4416',
      dropShadowDistance: '',
      // "fill": [
      //   "#ffbc19",
      //   "#fff499",
      //   "#fff499",
      //   "#ffbc19",
      //   "#ffbb16",
      //   "#eba400",
      //   "#ffc64d"
      // ],
      fontSize: 200,
      lineJoin: 'round',
      miterLimit: 22,
      stroke: '#ffd97a',
      strokeThickness: 3,
      // dropShadowAlpha: 0.4,
      // dropShadowAngle: 0,
      // dropShadowBlur: 38,
      // dropShadowColor: '#1f0900',
      // dropShadowDistance: 0,
      fill: ['#09c700', '#ffffff', '#07da1b', '#006e08', '#71ef9b'],
      fillGradientStops: [0.1, 0.4, 0.5],
      // fontFamily: 'CherryBombOne Regular',
      // fontSize: 176,
      // stroke: '#005d0d',
      // strokeThickness: 9,
    };
  }
}

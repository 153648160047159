import { App, Graphics, SpineScene } from '@growe/lightcore';

export default class ReelsBackgroundView extends SpineScene {
  constructor() {
    super('reels_background');
    this.name = 'reels_background';
    this.state.setAnimation(0, 'idle', true);
    this.skeleton.setSkinByName('gold');
    this.darkBackground = new Graphics();
    const bounds = this.getLocalBounds();
    this.darkBackground.beginFill(0x000000);
    this.darkBackground.drawRect(
      bounds.x + 0.03 * bounds.width,
      bounds.y + 0.03 * bounds.height,
      bounds.width * 0.94,
      bounds.height * 0.94,
    );
    this.darkBackground.endFill();
    this.darkBackground.alpha = 0.52;

    App.layers.getLayer('reels_frame').addChild(this);
    App.layers
      .getLayer('reels_background')
      .addChild(this.darkBackground);
  }

  setMainEnvironment() {
    this.skeleton.setSkin(null);
    this.skeleton.setSkinByName('default');
    this.skeleton.setSlotsToSetupPose();
    this.update(0);
  }

  setBonusEnvironment() {
    this.skeleton.setSkin(null);
    this.skeleton.setSkinByName('freespins');
    this.skeleton.setSlotsToSetupPose();
    this.update(0);
    // this._freespins.attachTo(this, 'freespins_indicator');
  }

  // resize() {
  //   let factor = 1;
  //   if (App.viewport.cropWidthRatio > 0.8) {
  //     factor = App.viewport.cropWidthRatio;
  //     this.y = SETTINGS.app.viewport.height * 0.5;
  //   } else if (App.viewport.cropWidthRatio >= 0.3) {
  //     factor = 0.5;
  //     this.y = SETTINGS.app.viewport.height * 0.5 - 50;
  //   } else {
  //     factor =
  //       (SETTINGS.app.viewport.width * App.viewport.cropWidthRatio) /
  //       650;
  //     this.y = SETTINGS.app.viewport.height * 0.5 - 50;
  //   }
  //
  //   this.scale.set(factor);
  //   this.position.x = SETTINGS.app.viewport.width * 0.5;
  // }

  resize() {
    this.position.set(
      App.device.width * 0.5,
      App.device.height * 0.5,
    );
    this.scale.set(1);

    if (
      App.device.type === 'mobile' &&
      App.device.orientation === 'landscape'
    ) {
      const bottomLine = App.layout.getGridLineY('reels_bottom');
      const topLine = App.layout.getGridLineY('reels_top');
      const scaleHeight = bottomLine - topLine;
      const scale = scaleHeight / this.height;
      this.scale.set(scale);
      this.darkBackground.scale.set(scale);
      const y = (bottomLine + topLine) / 2;
      this.position.y = y;
      this.darkBackground.y = y;
      this.darkBackground.x = App.device.width * 0.5;
    }

    if (
      App.device.type === 'mobile' &&
      App.device.orientation === 'portrait'
    ) {
      const bottomLine = App.layout.getGridLineY('reels_bottom');
      const topLine = App.layout.getGridLineY('reels_top');
      const scaleHeight = bottomLine - topLine;
      const scale = Math.min(
        scaleHeight / this.height,
        App.device.width / this.width,
      );
      this.scale.set(scale);
      this.darkBackground.scale.set(scale);
      const y = (bottomLine + topLine) / 2;
      this.position.y = y;
      this.darkBackground.y = y;
      this.darkBackground.x = App.device.width * 0.5;
      // this.scale.set(App.device.width / this.width);
    }
  }
}

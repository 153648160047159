import { App, Component } from '@growe/lightcore';

import LogoView from './LogoView';

export class Logo extends Component {
  subscribeToFsm() {
    this.fsm.subscribeToState('FEATURE_PREVIEW', this);
    this.fsm.subscribeToState('MAIN_GAME.START', this);
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'FEATURE_PREVIEW') {
      await this.create();
      stateEvent.onFinish(this.name);
    }

    if (stateEvent.next === 'MAIN_GAME.START') {
      await this.show();
      stateEvent.onFinish(this.name);
    }
  }

  async create() {
    this.view = new LogoView();
    this.view.resize();
  }

  async show() {
    this.view = new LogoView();
    App.layers.getLayer('foreground').addChild(this.view);
    this.view.setMainEnvironment();
    this.view.resize();
  }
}

import { App } from '@growe/lightcore';
import {
  ExtensionType,
  LoaderParserPriority,
  utils,
} from '@growe/lightcore/pixi';
import { extensions } from '@pixi/core';

/**
 * @typedef {Object} BasePathInjector
 * @property {ExtensionType} extension - The type of extension (Asset)
 * @property {Object} loader - The loader object for BasePathInjector
 * @property {string} loader.name - The name of the loader (BasePathInjector)
 * @property {Object} loader.extension - The extension object for the loader
 * @property {ExtensionType} loader.extension.type - The type of extension (LoadParser)
 * @property {LoaderParserPriority} loader.extension.priority - The priority of the extension (Normal)
 * @property {Function} loader.testParse - The function that tests and modifies the asset object
 * @property {Function} loader.parse - The function that parses the asset object
 * @property {Function} loader.unload - The function that unloads the assets
 */
export const BasePathInjector = {
  extension: ExtensionType.Asset,
  loader: {
    name: 'BasePathInjector',
    extension: {
      type: ExtensionType.LoadParser,
      priority: LoaderParserPriority.Normal,
    },

    async testParse(asset, options) {
      if (
        utils.path.extname(options.src).toLowerCase() === '.json' &&
        !!asset.bundles
      ) {
        asset.bundles.forEach((bundle) => {
          bundle.assets.forEach((asset) => {
            if (asset.data?.spineAtlasFile) {
              let path;
              if (
                App.network.baseAssetsUrl[
                  App.network.baseAssetsUrl.length - 1
                ] !== '/'
              ) {
                path =
                  App.network.baseAssetsUrl +
                  '/' +
                  asset.data?.spineAtlasFile;
              } else {
                path =
                  App.network.baseAssetsUrl +
                  asset.data?.spineAtlasFile;
              }
              asset.data.spineAtlasFile = path;
            }
          });
        });
      }
      return false;
    },

    async parse(asset, options, loader) {},

    unload(assets) {},
  },
};

extensions.add(BasePathInjector);

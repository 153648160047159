import { App } from '../../app';

export class Component {
  #enabled = true;
  constructor(options) {
    this.fsm = App.fsm;
    this.events = App.events;
    this.name = options.name;
    this.view = null;
    this.model = null;
    this.controller = null;
    this.subscribeToFsm();
    this.subscribeToEvents();

    // todo: maybe other way to handle event
    App.viewport.on('resize', () => {
      this.#enabled && this.resize();
    });

    // todo: maybe other way to handle event
    App.clock.on('tick', () => {
      this.#enabled && this.update(App.clock.deltaMS * 0.001);
    });
  }

  subscribeToFsm() {
    App.logger.debug(`Component ${this.name} does not subscribe to states`);
  }

  subscribeToEvents() {
    App.logger.debug(`Component ${this.name} does not subscribe to events`);
  }

  onStateChange(stateEvent) {
    App.logger.warn(
      `Component ${this.name} onStateChange state changed to state ${stateEvent}`,
    );
  }

  set name(name) {
    if (App.components[name]) {
      const errorMessage = `Component has already been created, component name: ${name}`;
      App.logger.error(errorMessage);
      throw new Error(errorMessage);
    }
    this._name = name;
    App.components[name] = this;
  }

  get name() {
    return this._name;
  }

  get enabled() {
    return this.#enabled;
  }

  set enabled(value) {
    App.logger.debug(
      `Component ${this.name} has been ${value ? 'enabled' : 'disabled'}`,
    );
    this.#enabled = value;
  }

  update(deltaMS) {
    this.view && this.view.update(deltaMS * 0.001);
  }

  resize() {
    this.view && this.view.resize();
  }
}

import EventEmitter from 'eventemitter3';

import NullAudio from './audio/NullAudio.js';
import Clock from './clock/Clock';
import DebugClock from './clock/DebugClock';
import NullClock from './clock/NullClock.js';
import Debug from './debug/Debug';
import Device from './device/Device';
import Events from './events/Events';
import Fsm from './fsm/fsm';
import Layers from './layers/layers';
import Layout from './layout/layout';
import Logger from './logger/Logger';
import NullLogger from './logger/NullLogger.js';
import NullNetwork from './network/NullNetwork.js';
import NullRenderer from './renderer2D/NullRenderer.js';
import Renderer2D from './renderer2D/Renderer2D';
import Repository from './repository/repository';
import { SETTINGS } from './settings';
import Settings from './settings/settings';
import NullViewport from './viewport/NullViewport.js';
import Viewport from './viewport/Viewport';

export * from './events/Events';

/**
 * The class represents the service locator
 * It creates and provides "null" app that can be replaced  with others.
 * Example:
 *      Index.provide(Clock)
 *      Index.provide(LogAudio)
 *
 *      const audio = Index.audio;
 *      audio.playSound('sound')
 */
export class App {
  static _services = {
    logger: new Logger(),
    game: null,
    clock: new NullClock(),
    renderer2D: new NullRenderer(),
    network: new NullNetwork(),
    viewport: new NullViewport(),
    events: new EventEmitter(),
    pubSub: null,
    audio: new NullAudio(),
    fsm: new Fsm(),
    layers: null,
    components: [],
    repository: null,
    settings: null,
    device: null,
    debug: null,
    layout: null,
  };

  static provide(service) {
    if (!service) return;
    if (!this._services.hasOwnProperty(service.serviceType)) {
      throw new Error(
        `Locator doesn't provide for ${service}` + service.serviceType,
      );
    }
    this._services[service.serviceType] = service;
  }

  /**
   *
   * @return {Game}
   */
  static get game() {
    return this._services.game;
  }

  /**
   *
   * @return {Clock}
   */
  static get clock() {
    return this._services.clock;
  }

  /**
   * Returns a renderer
   * @return {IRenderer}
   */
  static get renderer() {
    return this._services.renderer2D;
  }

  /**
   *
   * @return {INetwork}
   */
  static get network() {
    return this._services.network;
  }

  /**
   *
   * @return {Viewport}
   */
  static get viewport() {
    return this._services.viewport;
  }

  /**
   *
   * @return {EventEmitter}
   */
  static get events() {
    return this._services.events;
  }

  /**
   *
   * @return {Logger}
   */
  static get logger() {
    return this._services.logger;
  }

  static get audio() {
    return this._services.audio;
  }

  static get fsm() {
    return this._services.fsm;
  }

  static get layers() {
    return this._services.layers;
  }

  static get components() {
    return this._services.components;
  }

  static get repository() {
    return this._services.repository;
  }

  static get settings() {
    return this._services.settings;
  }

  static get device() {
    return this._services.device;
  }

  static get debug() {
    return this._services.debug;
  }

  static get layout() {
    return this._services.layout;
  }

  static initDefault() {
    App.provide(new Logger());
    // Index.provide(new Clock());
    App.provide(new DebugClock());
    App.provide(new Renderer2D(SETTINGS.renderer));
    App.provide(new Viewport(SETTINGS.viewport));
    // Index.provide(new LocalNetwork());
    // Index.provide(new MockNetwork());
    App.provide(new Events());
    App.provide(new Layers(this.viewport));
  }

  static init(settings) {
    App.provide(new Logger(settings.app.logger));
    // Index.provide(new Clock());
    App.provide(new DebugClock());
    App.provide(new Renderer2D(settings.app.renderer));
    App.provide(new Viewport(settings.app.viewport));
    // Index.provide(new LocalNetwork());
    // Index.provide(new MockNetwork());
    App.provide(new Events());
    App.provide(new Layers(this.viewport));
    App.provide(new Repository());
    App.provide(new Settings());
    App.provide(new Device());
    App.provide(new Debug(settings.debug));
    App.provide(new Layout());
  }

  static start() {
    // todo: all the events handling logic goes here for update, resize etc.
    this.clock.run();
  }
}

window.app = App;

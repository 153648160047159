import EventEmitter from 'eventemitter3';

export default class SoundButton extends EventEmitter {
  constructor(model, view) {
    super();
    this.model = model;
    this.view = view;

    this.view.on('pointerup', this.handlePointerUp, this);
  }

  handlePointerUp() {
    this.model.toggleSound();
  }
}

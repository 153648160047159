import { Container } from 'pixi.js';

import { App } from '../index';

const defaultConfig = [
  { name: 'init' },
  { name: 'spinPrevious' },
  { name: 'spinCurrent' },
  { name: 'ui' },
];

// todo: consider renaming to Cache
export default class Repository {
  #cache = {};

  constructor(config = defaultConfig) {
    this.serviceType = 'repository';
    this.config = config;
    this.setup();
  }

  setup() {
    this.config.forEach((cacheConfig) => {
      this.#cache[cacheConfig.name] = new Map();
    });
    App.logger.debug(
      `Repository Cache: ${JSON.stringify(Object.keys(this.#cache))}`,
    );
  }

  getCache(name) {
    return this.#cache[name];
  }
}

import { App, EVENTS } from '@growe/lightcore';
import EventEmitter from 'eventemitter3';

import { SPACE_KEY } from '../../../../config/constants.js';

export default class SpinButton extends EventEmitter {
  constructor(model, view) {
    super();
    this.model = model;
    this.view = view;

    this._isDown = false;
    this._isUp = false;
    this._isDownFront = false;
    this._isUpFront = false;
    this._isPress = false;
    this._time = 0;
    this._skipResetFronts = false;
    this._disabled = false;
    this.spaceBar = true;
    this.view.on('pointerdown', this.handleSpinBtnDown, this);
    this.view.on('pointerup', this.handleSpinBtnUp, this);
    this.view.on('pointerupoutside', this.handleSpinBtnUp, this);
    window.addEventListener('keydown', this.handleKeyDown.bind(this));
    window.addEventListener('keyup', this.handleKeyUp.bind(this));
  }

  disable() {
    this.resetState();
    this._disabled = true;
    App.events.emit('keyboard/disable', { button: 'spin_btn' });
  }

  enable() {
    this._disabled = false;
    App.events.emit('keyboard/enable', {
      button: 'spin_btn',
    });
  }

  handleKeyDown(e) {
    if (this._disabled) return;
    if (e.keyCode === SPACE_KEY) {
      if (!this.spaceBar) return;
      if (this._isDown) return;
      this.handleSpinBtnDown();
    }
  }

  handleKeyUp(e) {
    if (this._disabled) return;
    if (e.keyCode === SPACE_KEY) {
      if (!this.spaceBar) return;
      if (this._isUp) return;
      this.handleSpinBtnUp();
    }
  }

  handleSpinBtnDown() {
    if (this._disabled) return;
    this._isDown = true;
    this._isUp = false;
    this._isDownFront = true;
    this._skipResetFronts = true;
    this._time = 0;
    App.events.emit(EVENTS.UI.SPIN_BUTTON_DOWN);
  }

  handleSpinBtnUp() {
    if (this._disabled) return;
    this._isDown = false;
    this._isUp = true;
    this._isDownUp = false;
    this._isPress = false;
    this._skipResetFronts = true;
    App.events.emit(EVENTS.UI.SPIN_BUTTON_UP);
  }

  get isDownFront() {
    return this._isDownFront;
  }

  get isUpFront() {
    return this._isUpFront;
  }

  get isPress() {
    return this._isPress;
  }

  update() {
    if (this._isDown) {
      this._time += App.clock.deltaMS;
      this._isPress = this._time >= 500;
    }

    if (this._skipResetFronts) {
      this._skipResetFronts = false;
      return;
    }
    this._isDownFront = false;
    this._isUpFront = false;
  }

  resetState() {
    this._isDown = false;
    this._isUp = false;
    this._isDownFront = false;
    this._isUpFront = false;
    this._isPress = false;
    this._time = 0;
  }

  animateStartAnimation() {
    this.view.start();
  }

  stop() {
    this.view.stop();
  }
}

import { App } from '@growe/lightcore';

const reels = {
  width: 1071,
  height: 933,
};

export const addLayoutGridLines = () => {
  addMobileLandscapeLines();
  addMobilePortraitLines();
};

const addMobileLandscapeLines = () => {
  App.layout.addGridLine('mobile', 'landscape', 'ui_top', () => ({
    start: { x: 0, y: App.device.height * 0.81 },
    end: { x: App.device.width, y: App.device.height * 0.81 },
  }));
  App.layout.addGridLine('mobile', 'landscape', 'ui_bottom', () => ({
    start: { x: 0, y: App.device.height * 0.99 },
    end: { x: App.device.width, y: App.device.height * 0.99 },
  }));
  App.layout.addGridLine(
    'mobile',
    'landscape',
    'ui_dark_top',
    () => ({
      start: { x: 0, y: App.device.height * 0.86 },
      end: { x: App.device.width, y: App.device.height * 0.86 },
    }),
  );

  App.layout.addGridLine(
    'mobile',
    'landscape',
    'reels_bottom',
    () => ({
      start: { x: 0, y: App.device.height * 0.84 },
      end: { x: App.device.width, y: App.device.height * 0.84 },
    }),
  );

  App.layout.addGridLine('mobile', 'landscape', 'reels_3/4', () => ({
    start: { x: 0, y: App.device.height * 0.61 },
    end: { x: App.device.width, y: App.device.height * 0.61 },
  }));

  App.layout.addGridLine('mobile', 'landscape', 'reels_top', () => ({
    start: { x: 0, y: App.device.height * 0.02 },
    end: { x: App.device.width, y: App.device.height * 0.02 },
  }));

  // 1071, 933
  App.layout.addGridLine('mobile', 'landscape', 'reels_left', () => {
    const reelsScale = (App.device.height * 0.82) / 933;
    const x = App.device.width / 2 - (1071 / 2) * reelsScale;
    return {
      start: { y: 0, x },
      end: { y: App.device.height, x },
    };
  });

  App.layout.addGridLine('mobile', 'landscape', 'reels_right', () => {
    const reelsScale = (App.device.height * 0.82) / 933;
    const x = App.device.width / 2 + (1071 / 2) * reelsScale;
    return {
      start: { y: 0, x },
      end: { y: App.device.height, x },
    };
  });

  App.layout.addGridLine(
    'mobile',
    'landscape',
    'jackpots_center',
    () => {
      const reelsScale = (App.device.height * 0.82) / 933;
      const x = (App.device.width / 2 - (1071 / 2) * reelsScale) / 2;
      return {
        start: { y: 0, x },
        end: { y: App.device.height, x },
      };
    },
  );

  App.layout.addGridLine('mobile', 'landscape', 'logo_center', () => {
    const reelsScale = (App.device.height * 0.82) / 933;
    const x = (3 * App.device.width) / 4 + (1071 / 4) * reelsScale;
    return {
      start: { y: 0, x },
      end: { y: App.device.height, x },
    };
  });

  App.layout.addGridLine('mobile', 'landscape', 'x2_center', () => {
    const reelsScale = (App.device.height * 0.82) / 933;
    const x =
      App.device.width / 2 + ((3 * 1071) / 5) * reelsScale + 10;
    return {
      start: { y: 0, x },
      end: { y: App.device.height, x },
    };
  });
};

const addMobilePortraitLines = () => {
  App.layout.addGridLine('mobile', 'portrait', 'ui_top', () => {
    let y = App.device.height * 0.81;
    if (App.device.aspectRatio > 0.6) {
      y = App.device.height * 0.73;
    }
    return {
      start: { x: 0, y },
      end: { x: App.device.width, y },
    };
  });
  App.layout.addGridLine('mobile', 'portrait', 'ui_spin_top', () => {
    let y = App.device.height * 0.86;
    if (App.device.aspectRatio > 0.6) {
      y = App.device.height * 0.78;
    }
    return {
      start: { x: 0, y },
      end: { x: App.device.width, y },
    };
  });
  App.layout.addGridLine('mobile', 'portrait', 'ui_bottom', () => {
    let y = App.device.height * 0.98;
    if (App.device.aspectRatio > 0.6) {
      y = App.device.height;
    }
    return {
      start: { x: 0, y },
      end: { x: App.device.width, y },
    };
  });
  App.layout.addGridLine('mobile', 'portrait', 'reels_left', () => {
    const x = App.device.width * 0.02;
    return {
      start: { x, y: 0 },
      end: { x, y: App.device.height },
    };
  });
  App.layout.addGridLine('mobile', 'portrait', 'reels_right', () => {
    const x = App.device.width * 0.98;
    return {
      start: { x, y: 0 },
      end: { x, y: App.device.height },
    };
  });
  App.layout.addGridLine('mobile', 'portrait', 'reels_bottom', () => {
    const possibleHeight =
      Math.ceil(
        (((App.device.width / reels.width) * reels.height * 0.96) /
          App.device.height) *
          100,
      ) / 100;

    let y = App.device.height * (0.21 + possibleHeight);
    if (App.device.aspectRatio > 0.6) {
      y = Math.min(
        App.device.height * 0.65,
        App.device.height * (0.08 + possibleHeight),
      );
    }
    return {
      start: { x: 0, y },
      end: { x: App.device.width, y },
    };
  });
  App.layout.addGridLine('mobile', 'portrait', 'reels_top', () => {
    let y = App.device.height * 0.21;
    if (App.device.aspectRatio > 0.6) {
      y = App.device.height * 0.08;
    }
    return {
      start: { x: 0, y },
      end: { x: App.device.width, y },
    };
  });
  App.layout.addGridLine('mobile', 'portrait', 'logo_bottom', () => {
    let y = App.device.height * 0.15;
    if (App.device.aspectRatio > 0.6) {
      y = 0;
    }
    return {
      start: { x: 0, y },
      end: { x: App.device.width, y },
    };
  });
};

import {
  App,
  Assets,
  Container,
  Sprite,
  Texture,
} from '@growe/lightcore';

import { createThresholdCallback } from '../../../utils/utils.js';
import Symbol from './Symbol.js';

export default class Reel extends Container {
  constructor(map, container) {
    super();
    this._container = container ? container : this;
    this._config = Assets.get('game_config');
    this.map = map;
    this.isStopped = true;
    this.shouldAnticipate = false;
    this.isAnticipating = false;
    this._createSlots();
  }

  set symbolsVisibility(value) {
    this.symbols.forEach((symbol) => {
      symbol.visible = value;
    });
  }

  set distance(value) {
    this._distance = value;
  }

  get numberOfScatters() {
    const mapCopy = this.map.slice();
    mapCopy.pop();
    return mapCopy.filter((record) =>
      this._config.scatters.includes(record.key),
    ).length;
  }

  createSymbol(config) {
    App.logger.debug('createSymbol record', config);
    const symbol = new Symbol();
    symbol.symbol = config.key;
    return symbol;
  }

  update(dt) {
    this.symbols.forEach((symbol, i) => {
      symbol.x = this.x;
      symbol.y = this._distance * i + this.y;
      symbol.update(dt);
    });
  }

  idle() {
    this.symbols.forEach((symbol) => {
      symbol.idle();
      symbol.brighten();
    });
  }

  spin(delay) {
    this.isStopped = false;
    this.symbols[this.symbols.length - 1].visible = true;
    const countStartedHandler = createThresholdCallback(
      this.symbols.length,
      () => {
        this.emit('reelStarted', {
          reel: this,
        });
      },
    );
    this.symbols.forEach((symbol) => {
      symbol.spin(delay);
      symbol.once('started', countStartedHandler, this);
    });
  }

  quickSpin() {
    this.isStopped = false;
    this.symbols[this.symbols.length - 1].visible = true;
    const countStartedHandler = createThresholdCallback(
      this.symbols.length,
      () => {
        this.emit('reelStarted', {
          reel: this,
        });
      },
    );
    this.symbols.forEach((symbol) => {
      symbol.quickSpin();
      symbol.once('started', countStartedHandler, this);
    });
  }

  stop() {
    const countStartBounceHandler = createThresholdCallback(
      this.symbols.length,
      () => {
        this.updateSlotsProps();
      },
    );
    const countEndBounceHandler = createThresholdCallback(
      this.symbols.length,
      () => {
        this.isStopped = true;
        this.emit('reelStopped', {
          reel: this,
        });
        this.symbols[this.symbols.length - 1].visible = false;
        // this.isStoppedResolver(true);
      },
    );

    this.symbols.forEach((symbol) => {
      symbol.stop();
      symbol.once('startBounce', countStartBounceHandler, this);
      symbol.once('endBounce', countEndBounceHandler, this);
    });
  }

  isStoppedPromise() {
    return new Promise((resolve) => {
      this.isStoppedResolver = resolve;
    });
  }

  quickStop() {
    const countStartBounceHandler = createThresholdCallback(
      this.symbols.length,
      () => {
        this.updateSlotsProps();
      },
    );
    const countEndBounceHandler = createThresholdCallback(
      this.symbols.length,
      () => {
        this.isStopped = true;
        this.emit('reelStopped', {
          reel: this,
        });
        this.symbols[this.symbols.length - 1].visible = false;
      },
    );

    this.symbols.forEach((symbol, i) => {
      symbol.quickStop();
      symbol.once('startBounce', countStartBounceHandler, this);
      symbol.once(
        'endBounce',
        countEndBounceHandler.bind(this, i),
        this,
      );
    });
  }

  cancelSpinning() {
    this.symbols.forEach((symbol) => {
      symbol.cancelSpin();
    });
  }

  anticipate() {
    this.symbols.forEach((symbol) => {
      symbol.anticipate();
    });
    this.shouldAnticipate = false;
    this.isAnticipating = true;

    const handler = createThresholdCallback(40, () => {
      this.isAnticipating = false;
      this.emit('anticipationEnd', {
        reel: this,
      });
      this.removeListener('endShiftSymbols', handler, this);
    });

    this._skipAnticipation = () => {
      this.isAnticipating = false;
      this.removeListener('endShiftSymbols', handler, this);
      this.emit('anticipationEnd', {
        reel: this,
      });
      this._skipAnticipation = () => {};
    };

    this.on('endShiftSymbols', handler, this);
    this.emit('anticipationStart', {
      reel: this,
    });
  }

  skipAnticipation() {
    this._skipAnticipation();
  }

  _skipAnticipation() {}

  animateWinSlots(map) {
    const handler = createThresholdCallback(map.length, () => {
      this.emit('symbolsWinEnd', map);
    });
    map.forEach((index) => {
      this.symbols[index].win();
      this.symbols[index].once('symbolWinEnd', handler, this);
    });
  }

  darkenSlots(map) {
    map.forEach((symbolIndex) => {
      this.symbols[symbolIndex].darken();
    });
  }

  brightenSlots() {
    this.symbols.forEach((symbol) => {
      symbol.brighten();
    });
  }

  /**
   * Makes symbols darker except scatters
   */
  highlightScatters() {
    this.symbols.forEach((symbol) => {
      if (symbol.isScatter()) {
        symbol.waiting();
      }
    });
  }

  highlightWilds() {
    this.symbols.forEach((symbol) => {
      if (symbol.isWild()) {
        symbol.waiting();
      }
    });
  }

  darkenCommonSymbols() {
    this.symbols.forEach((symbol) => {
      if (!symbol.isWild() && !symbol.isScatter()) {
        symbol.darken();
      }
    });
  }

  handleSymbolsShift() {
    /**
     * Emit 'shiftSymbols' before update symbol's props to update data in handlers
     */
    this.emit('shiftSymbols', {
      reel: this,
    });
    this.updateSlotsProps();
  }

  handleSymbolsShifted() {
    this.emit('endShiftSymbols', {
      reel: this,
    });
  }

  updateSlotsProps() {
    this.symbols.forEach((symbol, index) => {
      symbol.symbol = this.map[index].key;
    });
  }

  _createSlots() {
    /**
     * Slots
     * @type {Symbol[]}
     */
    this.symbols = [];
    const countShiftSymbolHandler = createThresholdCallback(
      this.map.length,
      () => {
        this.handleSymbolsShift();
      },
    );
    const countEndShiftSymbolHandler = createThresholdCallback(
      this.map.length,
      () => {
        this.handleSymbolsShifted();
      },
    );
    this.map.forEach((record, i) => {
      const symbol = this.createSymbol(record, i);
      symbol.zIndex = 2;
      symbol.on('shiftSymbol', countShiftSymbolHandler, this);
      symbol.on('endShiftSymbol', countEndShiftSymbolHandler, this);
      this.symbols.push(symbol);
    });
    this._container.addChild(...this.symbols);
  }
}

import { App, Assets, TextAttachment } from '@growe/lightcore';
import { Spine } from 'pixi-spine';

import EmbeddedSpineButton from '../../ui/view/EmbeddedSpineButton.js';

export default class BuyBonusButton extends Spine {
  constructor() {
    super(Assets.get('buy_bonus_button').spineData);
    this.skeleton.setSkinByName('portrait');
    this.text = new TextAttachment({
      width: 90,
      height: 70,
    });
    this.text.text = 'BUY\nBONUS';

    this.text.style = {
      fontFamily: 'Inter-Bold',
      align: 'center',
      dropShadow: true,
      dropShadowAlpha: 16,
      dropShadowAngle: 0,
      dropShadowBlur: 8,
      dropShadowColor: '#fe4416',
      dropShadowDistance: '',
      fill: [
        '#ffbc19',
        '#fff499',
        '#fff499',
        '#ffbc19',
        '#ffbb16',
        '#eba400',
        '#ffc64d',
      ],
      fontSize: 50,
      lineJoin: 'round',
      miterLimit: 22,
      stroke: '#ffd97a',
      strokeThickness: 3,
    };
    this.text.center();
    this.text.boundaries = false;
    this.text.autoScaleToFit = true;
    this.text.attachTo(this, 'text');
    this.text.update();

    this.controller = new EmbeddedSpineButton(
      this,
      'buy_bonus_btn',
      0,
      1,
    );
    this.state.addListener({
      event: (_, e) => {
        console.log(e);
        if (e.data.name === 'buy_bonus_button/down') {
          this.emit('pointerdown');
        } else if (e.data.name === 'buy_bonus_button/up') {
          this.emit('pointerup');
        }
      },
    });
    this.resize();
  }

  disable() {
    this.controller.disable();
  }

  enable() {
    this.controller.enable();
  }

  resize() {
    this.scale.set(1);
    this.skeleton.setToSetupPose();
    this.update(1);

    let scaleFactor;
    if (App.device.orientation === 'landscape') {
      this.skeleton.setSkinByName('landscape');
      const top = App.layout.getGridLineY('reels_3/4');
      const bottom = App.layout.getGridLineY('reels_bottom');
      scaleFactor = Math.min(
        (0.75 * App.layout.getGridLineX('reels_left')) / this.width,
        (0.75 * (bottom - top)) / this.height,
      );
      this.controller.updateHitArea();
      this.position.y = (bottom + top) / 2;
      this.position.x = App.layout.getGridLineX('jackpots_center');

      this.text.resize({
        width: 360,
        height: 280,
      });
      this.text.text = 'BUY\nBONUS';
      this.text.center();
    }

    if (App.device.orientation === 'portrait') {
      this.skeleton.setSkinByName('portrait');
      const buttonTop = App.layout.getGridLineY('reels_bottom');
      const buttonBottom = App.layout.getGridLineY('ui_top');
      scaleFactor = Math.min(
        App.device.width / 2 / this.width,
        (0.9 * (buttonBottom - buttonTop)) / this.height,
      );
      this.y = (buttonTop + buttonBottom) / 2;

      this.controller.updateHitArea();
      this.text.resize({
        width: 730,
        height: 205,
      });
      this.text.center();
      this.text.text = 'BUY BONUS';
      this.x = App.device.width / 2;
    }

    this.scale.set(scaleFactor);
    this.text.maxScale = 1 / scaleFactor;
    this.text.update();
  }

  destroy(options) {
    super.destroy(options);
    this.text.destroy();
  }
}

import { App } from '@growe/lightcore';

import Numbers from '../../utils/Numbers.js';

export default class Currency {
  static #isInitialised = false;

  static init() {
    const { code, symbol, subunits, exponent } = App.repository
      .getCache('init')
      .get('currency');
    this.code = code;
    this.symbol = symbol;
    this.subunits = subunits;
    this.exponent = exponent;
    this.#isInitialised = true;
  }

  static getFormattedValue(value) {
    if (!this.#isInitialised) {
      throw new Error('Currency has to be init');
    }
    if (!this.symbol || this.symbol.length > 1) {
      return (
        this.formatDecimal(
          '' + Numbers.format(value / this.subunits, this.exponent),
        ) +
        ' ' +
        this.code
      );
    }
    return (
      this.symbol +
      this.formatDecimal(
        '' + Numbers.format(value / this.subunits, this.exponent),
      )
    );
  }

  static formatDecimal(str) {
    // Parse the string to a number and then to a string to remove trailing zeros
    let temp = parseFloat(str).toString();

    // Check if there are any decimals
    let idx = temp.indexOf('.');
    if (idx === -1) {
      // If there are no decimals, add .00
      return temp + '.00';
    } else {
      // If there are decimals, ensure there's at least one zero after the first non-zero digit
      let decimalPart = temp.split('.')[1]; // get the part after decimal
      if (decimalPart.length === 1) {
        return temp + '0'; // add a zero if only one digit after decimal
      } else {
        return temp; // else return the number without modification
      }
    }
  }
}

import { App, Component } from '@growe/lightcore';

import Activity from '../../game/activities/Activity.js';
import { GAMEPLAY_MODES } from '../../game/activities/gameplay/GameplayModes.js';
import { GAMEPLAY_STATES } from '../../game/activities/gameplay/GameplayStates.js';
import BuyBonusPopup from '../../game/scene/BuyBonusPopup.js';
import { waitForEventOnce } from '../../utils/utils.js';
import ReelsBackgroundView from '../reelsBackground/ReelsBackgroundView';
import BuyBonusButton from './view/BuyBonusButton';

export class BuyBonus extends Component {
  subscribeToFsm() {
    this.fsm.subscribeToState('MAIN_GAME.START', this);
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'MAIN_GAME.START') {
      await this.showButton();
      stateEvent.onFinish(this.name);
    }
  }

  async showButton() {
    this.view = new BuyBonusButton();
    App.layers.getLayer('reels_background').addChild(this.view);
    this.view.enable();
    this.view.resize();
  }

  // async execute(gameplay) {
  //   this.logName();
  //   Activity.add(this);
  //   gameplay.buyBonusBtn.disable();
  //   this.popup = new BuyBonusPopup();
  //   App.viewport.addChild(this.popup);
  //   this.popup.resize();
  //   this.popup.show();
  //   await waitForEventOnce(this.popup, 'startIdle');
  //   this.popup.on('accept', async () => {
  //     this.popup.removeAllListeners();
  //     this.popup.hide();
  //     await waitForEventOnce(this.popup, 'endOut');
  //     this.popup.destroy();
  //     this.popup = undefined;
  //     gameplay.setMode(GAMEPLAY_MODES.BUY_BONUS);
  //     gameplay.machine.idle();
  //     gameplay.setState(GAMEPLAY_STATES.SPIN);
  //     super.execute();
  //   });
  //   this.popup.on('reject', async () => {
  //     this.popup.removeAllListeners();
  //     this.popup.hide();
  //     await waitForEventOnce(this.popup, 'endOut');
  //     this.popup.destroy();
  //     this.popup = undefined;
  //     gameplay.buyBonusBtn.enable();
  //     super.execute();
  //   });
  // }
  //
  // update() {
  //   this.popup && this.popup.update(App.clock.deltaMS * 0.001);
  // }
  //
  // resize() {
  //   this.popup && this.popup.resize();
  // }
}

import { App, Component } from '@growe/lightcore';

import InitService from './InitService';
import InitView from './InitView';

export class Init extends Component {
  subscribeToFsm() {
    this.fsm.subscribeToState('INIT', this);
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'INIT') {
      App.logger.debug('Init state change', stateEvent);
      this.create();
      await this.load();
      stateEvent.onFinish(this.name);
      this.enabled = false;
      this.view.destroy();
    }
  }

  create() {
    this.view = new InitView();
    this.service = new InitService(this.view);
    this.view.show();
  }

  async load() {
    await this.service.initTextures(); // todo it loads assets where configs are. need to decouple
    await this.service.getInitData(); // todo promise all or other way
    this.service.initEntities();
  }
}

import { App, EVENTS } from '../index';

export default class DebugActivator {
  constructor(codes = secretCodes) {
    this.codes = codes;
    this.inputSequence = '';
    this.timer = null;
    this.resetDelay = 5000; // 5 seconds

    // Listen for keydown events
    document.addEventListener('keydown', (event) => this.handleKeyPress(event));
  }

  handleKeyPress(event) {
    // Reset the timer
    clearTimeout(this.timer);

    // Append the pressed key to the sequence
    this.inputSequence += event.key;

    // Check if the input sequence matches any of the secret codes
    for (let code of this.codes) {
      if (this.inputSequence.endsWith(code.sequence)) {
        code.action();
        this.inputSequence = '';
        break;
      }
    }

    // If the input sequence length exceeds the longest code, trim it
    const maxLength = Math.max(
      ...this.codes.map((code) => code.sequence.length),
    );
    if (this.inputSequence.length > maxLength) {
      this.inputSequence = this.inputSequence.substring(
        this.inputSequence.length - maxLength,
      );
    }

    // Set the timer to clear the input sequence after 5 seconds of inactivity
    this.timer = setTimeout(() => this.clearInputSequence(), this.resetDelay);
  }

  clearInputSequence() {
    this.inputSequence = '';
  }
}

// Define the secret codes and their actions
const secretCodes = [
  {
    sequence: 'iddqd',
    action: () => {
      App.logger.setLevel('debug');
      App.logger.debug('Debug mode activated!');
    },
  },
  {
    sequence: 'idkfa',
    action: () => {
      App.logger.debug('All keys acquired!');
    },
  },
  {
    sequence: 'grid',
    action: () => {
      App.events.emit(EVENTS.LAYOUT.TOGGLE_GRID);
    },
  },
];

export default class Numbers {
  static format(number, exponent = 2) {
    if (typeof number !== 'number') return number;
    if (number === 0) {
      return '0';
    }
    return number.toFixed(exponent);
  }

  static getInfinityInsteadOfNegative(number) {
    if (number < 0) {
      return '∞';
    }
    return number;
  }
}

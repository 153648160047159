import { Assets, Container, Sprite, Texture } from '@growe/lightcore';
import { App, SlotAttachment } from '@growe/lightcore';
import { Spine } from 'pixi-spine';

import { SYMBOL_TEXTURES_PREFIX } from '../../../config/constants.js';

export default class Symbol extends Container {
  constructor() {
    super();
    this.sortableChildren = true;
    this.symbolsConfig = Assets.get('symbols');
    this._symbol = null;
    this.symbolSpine = null;
    this.slotSpine = null;
    this._stuck = false;
    this.currentPrefix = SYMBOL_TEXTURES_PREFIX.STATIC;
    this._create();
  }

  set symbol(key) {
    this._symbol = key;
    this.updateSymbolPlaceHolder();
  }

  get symbol() {
    return this._symbol;
  }

  _create() {
    this.slotAttachment = new SlotAttachment({
      width: 248,
      height: 248,
    });
    this.slotSpine = new Spine(Assets.get('slot').spineData);
    this.slotAttachment.attachTo(this.slotSpine, 'symbol');
    this.slotSpine.zIndex = 0;
    this.slotSpine.autoUpdate = false;
    this.symbolBone = this.slotSpine.skeleton.findBone('symbol');
    this.addChild(this.slotSpine);

    this.slotSpine.state.addListener({
      event: (entry, event) => {
        if (event.data.name === 'switchToBlur') {
          this.handleSwitchToBlur();
        } else if (event.data.name === 'switchToIn') {
          this.handleStartBounce();
        } else if (event.data.name === 'switchToStatic') {
          this.handleSwitchToStatic();
        }
        this.emit(event.data.name, {
          slot: this,
        });
      },
    });
  }

  _createSymbolSpine() {
    if (this.symbolSpine) {
      this.symbolSpine.destroy();
    }
    this.symbolSpine = new Spine(
      Assets.get(this.symbolsConfig[this._symbol].spine).spineData,
    );
    this.symbolSpine.skeleton.setSkinByName(
      this.symbolsConfig[this._symbol].skin,
    );
    this.symbolSpine.autoUpdate = false;
    this.symbolSpine.zIndex = 1;
    this.addChild(this.symbolSpine);
    this.symbolSpine.position.set(
      this.symbolBone.worldX,
      this.symbolBone.worldY,
    );
    this.symbolSpine.visible = !this._stuck;
  }

  updateSymbolPlaceHolder() {
    if (this.currentPrefix === null) {
      this.slotAttachment.image = null;
    } else {
      this.slotAttachment.image = this.currentPrefix + this._symbol;
    }
  }

  update(dt) {
    this.slotSpine.update(dt);
    this.slotSpine.visible = true;
    if (this.symbolSpine) {
      this.symbolSpine.update(dt);
      this.symbolSpine.position.set(
        this.symbolBone.worldX,
        this.symbolBone.worldY,
      );
    }
  }

  // happens when symbols appear to reel on reel stop
  in() {
    if (!this.symbolSpine) this._createSymbolSpine();
    this.symbolSpine.skeleton.setToSetupPose();
    this.handleSwitchToStatic();
    this.symbolSpine.state.addListener({
      event: (entry, event) => {
        if (event.data.name === 'inEnd') {
          this.emit('slotInEnd');
          this.symbolSpine.state.clearTracks();
          this.symbolSpine.state.clearListeners();
        } else if (event.data.name === 'inStart') {
          this.currentPrefix = null;
          this.updateSymbolPlaceHolder();
        }
      },
    });
    let duration = 0;
    if (this.skipInAnimation) {
      duration = this.symbolSpine.spineData.animations.find(
        (a) => a.name === 'in',
      ).duration;
    }
    this.symbolSpine.update(duration);
  }

  idle() {
    this.slotSpine.state.clearTracks();
    this.currentPrefix = null;
    this.updateSymbolPlaceHolder();
    if (!this.symbolSpine) {
      this._createSymbolSpine();
    }
    this.symbolSpine.state.setAnimation(0, 'idle', true);
  }

  win() {
    if (!this.symbolSpine) {
      this._createSymbolSpine();
    }
    this.symbolSpine.state.addListener({
      event: (entry, event) => {
        if (event.data.name === 'winEnd') {
          if (this.symbolSpine) {
            this.symbolSpine.state.clearTracks();
            this.symbolSpine.state.clearListeners();
          }
          this.emit('slotWinEnd');
        }
      },
    });
    this.symbolSpine.state.setAnimation(0, 'win');
    this.currentPrefix = null;
    this.updateSymbolPlaceHolder();
  }

  waiting() {
    if (!this.symbolSpine) {
      this._createSymbolSpine();
    }
    this.symbolSpine.state.setAnimation(0, 'waiting', true);
    this.currentPrefix = null;
    this.updateSymbolPlaceHolder();
  }

  spin(delay) {
    App.logger.debug('Symbol spin', delay);
    this.slotSpine.state.setEmptyAnimation(0);
    this.slotSpine.state.addAnimation(0, 'start_spin', false, delay);
    this.slotSpine.state.addAnimation(0, 'spin', true);
    this.slotSpine.state.timeScale = 2;
  }

  quickSpin() {
    this.emit('started');
    this.slotSpine.state.setAnimation(0, 'start_spin', false);
    this.slotSpine.state.addAnimation(0, 'spin', true);
    this.slotSpine.state.timeScale = 2;
  }

  cancelSpin() {
    this.slotSpine.state.clearTracks();
    this.slotSpine.skeleton.setToSetupPose();
  }

  stop() {
    this.slotSpine.state.clearTracks();
    this.slotSpine.state.setEmptyAnimation(0);
    this.slotSpine.state.addAnimation(0, 'bounce', false);
    this.skipInAnimation = false;
  }

  isScatter() {
    return this.symbolsConfig[this._symbol].scatter;
  }

  isWild() {
    return this.symbolsConfig[this._symbol].wild;
  }

  quickStop() {
    this.slotSpine.state.clearTracks();
    this.slotSpine.state.setEmptyAnimation(0);
    this.slotSpine.state.addAnimation(0, 'quick_stop', false);
    this.skipInAnimation = true;
  }

  anticipate() {
    const { timeScale } = this.slotSpine.state.tracks[0];
    this.slotSpine.state.tracks[0].timeScale = timeScale * 1.5;
  }

  darken() {
    this.zIndex = 0;
  }

  brighten() {
    this.zIndex = 3;
  }

  handleSwitchToBlur() {
    this.symbolSpine && this.symbolSpine.destroy();
    this.symbolSpine = null;
    this.currentPrefix = SYMBOL_TEXTURES_PREFIX.BLUR;
    this.updateSymbolPlaceHolder();
  }

  handleSwitchToStatic() {
    // this.symbolSpine && this.symbolSpine.destroy();
    // this.symbolSpine = null;
    this.currentPrefix = SYMBOL_TEXTURES_PREFIX.STATIC;
    this.updateSymbolPlaceHolder();
  }

  handleStartBounce() {
    this.in();
  }

  destroy(_options) {
    this.slotSpine.destroy();
    this.slotAttachment.destroy();
    super.destroy(_options);
  }
}

export default class PlusButton {
  constructor(model, view) {
    this.model = model;
    this.view = view;
    this.view.on('pointerup', this.handlePointerUp, this);
  }

  handlePointerUp() {
    this.model.nextBet();
  }
}

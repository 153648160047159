export default class NullAudio {
  get mute() {
    return this._mute;
  }

  set mute(value) {
    this._mute = !!value;
  }

  constructor() {
    this.name = 'NullAudio';
    this.serviceType = 'audio';
    this._mute = false;
  }

  play() {}

  stop() {}
}

import { Assets, Container } from '@growe/lightcore';
import { Spine } from 'pixi-spine';

export default class AnticipationFrame extends Container {
  constructor() {
    super();
    this.spine = new Spine(Assets.get('anticipator').spineData);
    this.spine.scale.y = 0.95;
    this.addChild(this.spine);
  }

  show() {
    this.visible = true;
    this.spine.state.setAnimation(0, 'idle', true);
  }

  hide() {
    this.visible = false;
  }
}

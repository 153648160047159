import { App, Assets, Circle, Container } from '@growe/lightcore';

import GambleButton from './GambleButton';
import GamblePopup from './GamblePopup';

export default class GambleView {
  constructor() {
    this.button = new GambleButton();
    this.popup = new GamblePopup();

    App.layers.getLayer('game_ui').addChild(this.button);
    App.layers.getLayer('game_ui').addChild(this.popup);
  }

  resize() {
    this.button && this.button.resize();
    this.popup && this.popup.resize();
  }

  update() {
    this.button && this.button.update(App.clock.deltaMS * 0.001);
    this.popup && this.popup.update(App.clock.deltaMS * 0.001);
  }

  showButton() {
    this.button && this.button.enable();
  }

  hideButton() {
    this.button && this.button.disable();
  }

  showPopup() {
    this.popup && this.popup.show();
  }

  async hidePopup() {
    await this.popup.finish();
  }

  closePopup() {
    this.popup.close();
  }
}

import { Container, Graphics } from 'pixi.js';

import { App } from '../../../index';

export class GridView extends Container {
  constructor(model) {
    super();
    this.model = model;
    this.visible = false;
  }

  drawLine({ start, end, color, alpha, width }) {
    const line = new Graphics();
    line.lineStyle(width || 1, color || 0x00ff00, alpha);
    line.moveTo(start.x, start.y);
    line.lineTo(end.x, end.y);
    this.addChild(line);
  }

  show() {
    App.layers.getLayer('grid').addChild(this);
    this.model
      .getLines(App.device.orientation)
      .forEach((line) => this.drawLine(line));
  }

  toggle() {
    this.visible = !this.visible;
  }

  update() {}

  resize() {
    this.removeChildren();
    this.model.recalculatePositions();

    this.show();
  }
}

import EventEmitter from 'eventemitter3';

import { App } from '../index';

export * from './constants/events';

export default class Events extends EventEmitter {
  constructor() {
    super();
    this.name = 'Events';
    this.serviceType = 'events';
  }

  emit(...args) {
    App.logger.event(...args);
    super.emit(...args);
  }

  on(...args) {
    // App.logger.event(...args);
    super.on(...args);
  }
}

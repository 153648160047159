import { App, Component } from '@growe/lightcore';

import FeaturePreviewView from './FeaturePreviewView';

export class FeaturePreview extends Component {
  subscribeToFsm() {
    this.fsm.subscribeToState('FEATURE_PREVIEW', this);
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'FEATURE_PREVIEW') {
      this.show();
      await this.waitPointerDown();
      this.destroy();
      stateEvent.onFinish(this.name);
    }
  }

  show() {
    this.view = new FeaturePreviewView();
    App.layers.getLayer('foreground').addChild(this.view);
    this.view.resize();
  }

  destroy() {
    this.enabled = false;
    this.view.visible = false;
    this.view.destroy();
  }

  waitPointerDown() {
    return new Promise((resolve) => {
      this.view.once('pointerdown', () => {
        resolve(true);
      });

      App.events.once('BACKGROUND.POINTER_DOWN', () => {
        resolve(true);
      });
    });
  }
}

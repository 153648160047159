import { App, Component } from '@growe/lightcore';
import { Assets } from '@growe/lightcore/pixi';

export class Loader extends Component {
  subscribeToFsm() {
    this.fsm.subscribeToState('LOAD', this);
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'LOAD') {
      app.logger.debug('Loaded state change', stateEvent);
      await this.load();
      stateEvent.onFinish(this.name);
    }
  }

  async load() {
    // this.logName();
    await Assets.init({
      basePath: App.network.baseAssetsUrl,
      manifest: 'manifest.json',
      defaultSearchParams: 'v' + __VERSION, // __VERSION will be replaced with version from the package.json by webpack
    });

    await Assets.loadBundle('preloader');
  }
}

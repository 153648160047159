import { App, Container } from '@growe/lightcore';

import Jackpot from './Jackpot';

export default class JackpotsView extends Container {
  constructor() {
    super();
    this.grand = this.createJackpot('grand');
    this.major = this.createJackpot('major');
    this.mini = this.createJackpot('mini');
    this.minor = this.createJackpot('minor');
    this.jackpotHeight = this.minor.height;
    this.jackpotWidth = this.grand.width;
    this.jackpotMargin = 0.1 * this.jackpotHeight;
    this.resize();
  }

  createJackpot(type) {
    const jackpot = new Jackpot(type);
    this.addChild(jackpot);
    return jackpot;
  }

  resize() {
    this.scale.set(1);
    this.grand.scale.set(1);
    this.major.scale.set(1);
    this.minor.scale.set(1);

    if (App.device.orientation === 'landscape') {
      this.grand.y =
        -this.jackpotHeight - (3 * this.jackpotMargin) / 2;
      this.major.y = -this.jackpotHeight / 3 - this.jackpotMargin / 2;
      this.minor.y = this.jackpotHeight / 3 + this.jackpotMargin / 2;
      this.mini.y = this.jackpotHeight + (3 * this.jackpotMargin) / 2;
      this.grand.x = 0;
      this.major.x = 0;
      this.minor.x = 0;
      this.mini.x = 0;
      const scale = Math.min(
        (App.layout.getGridLineY('reels_3/4') -
          App.layout.getGridLineY('reels_top')) /
          this.height,
        App.layout.getGridLineX('reels_left') / this.width,
      );
      this.scale.set(scale);
      this.position.y =
        (App.layout.getGridLineY('reels_top') +
          App.layout.getGridLineY('reels_3/4')) /
        2;
      this.position.x = App.layout.getGridLineX('jackpots_center');
    }

    if (App.device.orientation === 'portrait') {
      this.pivot.set(0, 0);
      this.grand.scale.set(1.5);
      this.major.scale.set(1.3);
      this.minor.scale.set(1.15);
      this.grand.x = this.grand.width / 2;
      this.major.x = this.grand.width + this.major.width * 0.38;
      this.minor.x =
        this.grand.width +
        this.major.width * 0.9 +
        this.minor.width * 0.36;
      this.mini.x =
        this.grand.width +
        this.major.width * 0.9 +
        this.minor.width * 0.9 +
        this.mini.width * 0.29;
      this.grand.y = -this.grand.height / 2;
      this.major.y = -this.major.height * 0.53;
      this.minor.y = -this.minor.height * 0.56;
      this.mini.y = -this.mini.height * 0.6;
      const left = App.layout.getGridLineX('reels_left');
      const right = App.layout.getGridLineX('reels_right');
      const scale = (1.04 * (right - left)) / this.width;
      // const scale =
      //   (App.layout.getGridLineY('reels_3/4') -
      //     App.layout.getGridLineY('reels_top')) /
      //   this.height;
      this.scale.set(scale);
      this.position.x = left - 0.02 * this.width - 1;
      this.position.y =
        App.layout.getGridLineY('reels_top') + this.height * 0.25;
      // this.position.x = App.layout.getGridLineX('jackpots_center');
    }
  }

  update() {
    this.grand && this.grand.update(App.clock.deltaMS * 0.001);
    this.major && this.major.update(App.clock.deltaMS * 0.001);
    this.mini && this.mini.update(App.clock.deltaMS * 0.001);
    this.minor && this.minor.update(App.clock.deltaMS * 0.001);
  }

  show() {
    // this.popup && this.popup.show();
  }
}

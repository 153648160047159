import { Container, EVENTS, Graphics, Text } from '@growe/lightcore';
import { App } from '@growe/lightcore';
import { isMobile } from '@pixi/core';

import { SETTINGS } from '../../../../config/settings.js';
import UIButtonView from '../UIButtonView.js';
import AutoSpinsNumberButton from './AutoSpinsNumberButton.js';

const WIDTH = 576;
const HEIGHT = 295;
const X = -WIDTH / 2;
const Y = -HEIGHT / 2;
const RADIUS = 0;

export default class AutoSpinsSettingsWindow extends Container {
  constructor(model) {
    super();
    this.model = model;
    this.presets = [];
    this._create();
  }

  update() {
    this.presets.forEach((preset, i) => {
      preset.active = i === this.model.selectedAutoSpinsPreset;
    });
  }

  resize() {
    const w =
      SETTINGS.app.viewport.width * App.viewport.cropWidthRatio;
    if (App.device.orientation === 'portrait') {
      const factor = Math.min(w / WIDTH, 0.65);
      this.scale.set(factor);
      this.x = App.device.width / 2;
      this.y = App.device.height - (HEIGHT * factor) / 2 - 30;
    } else {
      this.x = App.device.width / 2;
      this.y = App.device.height / 2;
      this.scale.set(isMobile.phone ? 1 : 0.5);
    }
  }

  _create() {
    this._createBackground();

    const group = new Container();
    group.x = X;
    group.y = Y;
    this.addChild(group);

    const headerText = new Text('AUTO SPINS', {
      fontSize: 48,
      fill: 0xffffff,
      align: 'center',
      fontWeight: '600',
      fontFamily: 'Teko',
    });
    headerText.x = WIDTH / 2;
    headerText.y = 14;
    headerText.anchor.set(0.5, 0);
    group.addChild(headerText);

    const numberText = new Text('Number of Auto Spins', {
      fontSize: 32,
      fill: 0xffffff,
      align: 'center',
      fontWeight: '600',
      fontFamily: 'Teko',
    });
    numberText.x = 12;
    numberText.y = 81;
    numberText.anchor.set(0, 0);
    group.addChild(numberText);

    const closeBtn = new UIButtonView('close_modal_window');
    closeBtn.position.set(530, 40);
    closeBtn.on('pointerup', () => {
      this.emit('close');
    });
    group.addChild(closeBtn);

    this.model.autoSpinsPresets.forEach((number, i) => {
      const btn = new AutoSpinsNumberButton(number);
      group.addChild(btn);
      btn.position.set(7 + (10 + 133) * i, 122);
      btn.active = false;
      this.presets.push(btn);
    });

    this._createStartBtn();
  }

  _createBackground() {
    const graphics = new Graphics();
    graphics.beginFill(0x2a3443);
    graphics.drawRoundedRect(X, Y, WIDTH, HEIGHT, RADIUS);
    graphics.beginFill(0x4d607c);
    graphics.drawRoundedRect(X, Y, WIDTH, 81, RADIUS);
    graphics.beginFill(0x19222f);
    graphics.drawRoundedRect(X, Y + 81, WIDTH, 113, RADIUS);

    this.addChild(graphics);
  }

  _createStartBtn() {
    const graphics = new Graphics();

    const draw = (color) => {
      graphics.beginFill(color);
      graphics.drawRoundedRect(-273, -30, 546, 60, 8);
    };
    draw(0x4d607c);
    graphics.position.set(0, 95);
    this.addChild(graphics);
    this.startBtn = graphics;

    this.startBtn.interactive = true;
    this.startBtn.buttonMode = true;
    this.startBtn.on('pointerover', () => {
      draw(0xffbc00);
    });
    this.startBtn.on('pointerout', () => {
      draw(0x4d607c);
    });
    this.startBtn.on('pointerdown', () => {
      graphics.scale.set(0.9);
    });
    this.startBtn.on('pointerup', () => {
      graphics.scale.set(1);
      this.emit('start');
      App.events.emit(EVENTS.UI.START_AUTOSPINS);
    });
    this.startBtn.on('pointerupoutside', () => {
      graphics.scale.set(1);
      draw(0x4d607c);
    });

    const text = new Text('Start Auto Spins', {
      fontSize: 48,
      fill: 0xffffff,
      align: 'center',
      fontWeight: '600',
      fontFamily: 'Teko',
    });
    text.anchor.set(0.5, 0.5);

    graphics.addChild(text);
  }
}

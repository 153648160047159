import { App } from '../index.js';

export default class Device {
  constructor() {
    this.name = 'Device';
    this.serviceType = 'device';
    App.logger.info('Device info', {
      width: this.width,
      height: this.height,
      aspectRatio: this.aspectRatio,
      orientation: this.orientation,
      type: this.type,
    });

    this._type = null;
  }

  get type() {
    if (this._type) {
      return this._type;
    }

    if (true) {
      this._type = 'mobile';
      return this._type;
    }

    if (this.width < 600 || this.height < 600) {
      this._type = 'mobile';
    } else if (this.width < 1200 || this.height < 1200) {
      this._type = 'tablet';
    } else {
      this._type = 'desktop';
    }

    return this._type;
  }

  get orientation() {
    return this.width > this.height ? 'landscape' : 'portrait';
  }

  get aspectRatio() {
    return this.width / this.height;
  }

  get width() {
    return window.innerWidth;
  }

  get height() {
    return window.innerHeight;
  }
}

import { App, SpineScene } from '@growe/lightcore';

export default class BackgroundView extends SpineScene {
  constructor() {
    super('background');
    this.name = 'BackgroundScene';
    this.stateData.defaultMix = 0.8;
    this.state.setAnimation(0, 'idle', false);
    this.eventMode = 'static';
  }

  setMainEnvironment() {
    this.state.setAnimation(0, 'idle', true);
    this.skeleton.setSkinByName('normal');
  }

  setBonusEnvironment() {
    this.state.setAnimation(0, 'idle_gold', true);
    this.skeleton.setSkinByName('gold');
  }

  resize() {
    this.scale.set(1);
    this.position.set(
      App.device.width * 0.5,
      App.device.height * 0.5,
    );

    if (
      App.device.type === 'mobile' &&
      App.device.orientation === 'landscape'
    ) {
      this.scale.set(App.device.width / this.width);
    }

    if (
      App.device.type === 'mobile' &&
      App.device.orientation === 'portrait'
    ) {
      this.scale.set(App.device.height / this.height);
    }
  }
}

import {
  App,
  Assets,
  Circle,
  Polygon,
  Rectangle,
} from '@growe/lightcore';
import { Spine } from 'pixi-spine';

import { delay } from '../../../utils/utils';

export default class GamblePopup extends Spine {
  constructor() {
    super(Assets.get('gamble_popup').spineData);
    this.skeleton.setSkinByName('heart');
    this.visible = false;
    this.scale.set(1);
    this.baseHeight = this.height;
    this.resize();
    this.autoUpdate = false;
    this.addButtons();

    // this.eventMode = 'static';
  }

  addButtons() {
    const heartSlotName = 'hearts';
    const spadeSlotName = 'hearts3';
    this.heartSlotIndex = this.skeleton.findSlotIndex(heartSlotName);
    // console.error('GP slot index', this.heartSlotIndex);
    this.heartContainer = this.slotContainers[this.heartSlotIndex];
    // this.bbxSlot = this.skeleton.findSlot(
    //   heartSlotName,
    // );
    this.heartContainer.eventMode = 'static';
    this.heartContainer.hitArea = new Circle(0, 0, 120);
    this.heartContainer.cursor = 'pointer';
    this.heartContainer.on('pointerup', () => {
      if (!this.isHeartPressed) return;
      this.state.setAnimation(1, 'button press heart', false);
      this.skeleton.setSkin(null);
      this.skeleton.setSkinByName('heart');
      this.skeleton.setToSetupPose();
      this.state.setAnimation(0, 'card_rotate', false);
    });
    this.heartContainer.on('pointerdown', () => {
      this.isHeartPressed = true;
    });

    this.spadeSlotIndex = this.skeleton.findSlotIndex(spadeSlotName);
    this.spadeContainer = this.slotContainers[this.spadeSlotIndex];
    // this.bbxSlot = this.skeleton.findSlot(
    //   spadeSlotName,
    // );

    console.error(
      'GP slot index',
      this.spadeSlotIndex,
      this.spadeContainer,
    );
    this.spadeContainer.eventMode = 'static';
    this.spadeContainer.hitArea = new Circle(0, 0, 120);
    this.spadeContainer.cursor = 'pointer';
    this.spadeContainer.on('pointerup', () => {
      if (!this.isSpadePressed) return;
      this.state.setAnimation(1, 'button press spade', false);
      this.skeleton.setSkin(null);
      this.skeleton.setSkinByName('spade');
      this.skeleton.setToSetupPose();
      this.state.setAnimation(0, 'card_rotate', false);
    });
    this.spadeContainer.on('pointerdown', () => {
      this.isSpadePressed = true;
    });
  }

  show() {
    this.state.setAnimation(0, 'in', false);
    this.visible = true;
  }

  async finish() {
    const isClosed = await this.isClosed();
    if (isClosed) {
      return;
    }
    await this.close();
  }

  async close() {
    this.state.setAnimation(0, 'out', false);
    await delay(1000);
    this.visible = false;
  }

  isClosed() {
    return new Promise((resolve) => {
      const checkVisibility = () => {
        if (!this.visible) {
          resolve(true);
        } else {
          setTimeout(checkVisibility, 40); // Check again after 100ms
        }
      };

      checkVisibility(); // Start checking immediately
    });
  }

  resize() {
    this.scale.set(1);

    const reelsTop = App.layout.getGridLineY('reels_top');
    const reelsBottom = App.layout.getGridLineY('reels_bottom');
    const scale = (0.92 * (reelsBottom - reelsTop)) / this.baseHeight;
    this.position.set(
      App.device.width * 0.5,
      (reelsBottom + reelsTop) / 2,
    );
    // this.hitArea = new Rectangle(0, 0, this.width, this.height);

    this.scale.set(scale);

    // if (App.device.orientation === 'landscape') {
    //
    //
    //   this.scale.set(0.4);
    // } else {
    //   this.position.set(
    //     App.device.width * 0.5,
    //     App.device.height * 0.5 - 50,
    //   );
    //
    //   this.scale.set(0.4);
    // }
  }
  //
  // update() {
  //   this.spine.update(App.clock.deltaMS * 0.001);
  // }
}

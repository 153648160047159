import { App, SpineScene, TextAttachment } from '@growe/lightcore';

export default class FeaturePreviewView extends SpineScene {
  constructor() {
    super('feature_preview');
    this.autoUpdate = true;
    this.state.setAnimation(0, 'in', false);
    this.state.addAnimation(0, 'idle', true, 0);

    this.eventMode = 'static';
    this.addText();
  }

  addText() {
    this.text = new TextAttachment({
      width: 700,
      height: 320,
    });
    this.text.text = 'PRESS ANYWHERE TO CONTINUE';
    this.text.style = {
      dropShadowAlpha: 0.8,
      dropShadowAngle: -4.7,
      dropShadowColor: '#382400',
      dropShadowDistance: 2,
      fill: '#ccd0ac',
      fillGradientStops: [0.2, 0.4, 0.5, 0.7, 0.6],
      fontFamily: 'Poppins-Regular',
      fontSize: 42,
      stroke: '#cdcfce',
    };
    this.text.center();
    this.text.boundaries = false;
    this.text.autoScaleToFit = true;
    this.text.attachTo(this, 'placeholder');
    this.text.update();
  }

  get resizeSkinPrefix() {
    return '>';
  }

  resize() {
    this.scale.set(1);
    this.position.set(
      App.device.width * 0.5,
      App.device.height * 0.5,
    );

    let scaleFactor;
    if (
      App.device.type === 'mobile' &&
      App.device.orientation === 'landscape'
    ) {
      this.skeleton.setSkinByName('landscape');
      // todo: magic numbers
      scaleFactor = App.device.width / 2048;
    }

    if (
      App.device.type === 'mobile' &&
      App.device.orientation === 'portrait'
    ) {
      this.skeleton.setSkinByName('portrait');
      // todo: magic numbers
      scaleFactor = App.device.height / 2048;
    }

    this.scale.set(scaleFactor);
    this.text.maxScale = 1 / scaleFactor;
  }
}

import { Assets, Container } from '@growe/lightcore';
import { App } from '@growe/lightcore';

import Currency from '../../../game/models/Currency.js';
import CompactIndicator from './CompactIndicator.js';

export default class PortraitIndicators extends Container {
  constructor(model) {
    super();
    this.model = model;
    this.balance = new CompactIndicator(Assets.get('wallet-icon'));
    this.bet = new CompactIndicator(Assets.get('bet-icon'));
    this.win = new CompactIndicator(Assets.get('win-icon'));
    this.addChild(this.balance, this.bet, this.win);
  }

  update() {
    this.balance.value = Currency.getFormattedValue(
      this.model.balance,
    );
    this.bet.value = Currency.getFormattedValue(this.model.bet);
    this.win.value = Currency.getFormattedValue(this.model.lastWin);
  }

  resize() {
    if (App.device.orientation === 'landscape') {
      this.visible = false;
      return;
    }

    if (App.device.orientation === 'portrait') {
      this.visible = true;
      const width = Math.min(App.device.width, 360);
      this.x = (App.device.width - width) / 2;
      const w = width / 3;
      this.balance.resize(w - 5, 25);
      this.bet.resize(w - 5, 25);
      this.bet.x = w;
      this.win.resize(w, 25);
      this.win.x = w * 2;
      const top = App.layout.getGridLineY('ui_top');
      const bottom = App.layout.getGridLineY('ui_spin_top');

      this.y = (top + bottom) / 2 - this.height / 2;
    }
  }
}

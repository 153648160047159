import { App, Component } from '@growe/lightcore';

export class Network extends Component {
  subscribeToFsm() {
    this.fsm.subscribeToState('LOAD', this);
    this.fsm.subscribeToState('MAIN_GAME.SPIN_START', this);
    this.fsm.subscribeToState('GAMBLE_GAME.GAMBLE_START', this);
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'LOAD') {
      await this.init();
      stateEvent.onFinish(this.name);
    }

    if (stateEvent.next === 'MAIN_GAME.SPIN_START') {
      const conditions = await this.spin();
      stateEvent.onFinish(this.name, conditions);
    }

    if (stateEvent.next === 'GAMBLE_GAME.GAMBLE_STAR') {
      const conditions = await this.gamble();
      stateEvent.onFinish(this.name, conditions);
    }
  }

  async init() {
    const query = App.settings.getSettings('query');
    const request = {
      integrator: query.get('integrator'),
      params: Object.fromEntries(query.entries()),
    };
    const response = await App.network.init(request);
    App.repository.getCache('init').set('raw', response);

    // App.repository.getCache('init').set('gameResult', response.game_result);
  }

  async spin() {
    // todo: need to emit the data for spin request
    // or take it from model
    const request = { bet: App.repository.getCache('ui').get('bet') };
    const response = await App.network.spin(request);
    const isCanGamble = !!response.game_result.can_gamble;
    const isWin = !!response.game_result.spin.award;
    App.repository
      .getCache('spinCurrent')
      .set('isCanGamble', isCanGamble);
    App.repository.getCache('spinCurrent').set('raw', response);
    App.repository
      .getCache('spinCurrent')
      .set('gameResult', response.game_result);
    App.repository.getCache('ui').set('balance', response.balance);
    if (isWin) {
      App.repository
        .getCache('spinCurrent')
        .set('linesAward', response.game_result.spin.award);
    }
    return {
      isWin,
      isCanGamble,
    };
  }

  async gamble() {
    // todo: need to emit the data for gamble request
    // or take it from model
    const request = { bet: App.repository.getCache('ui').get('bet') };
    const response = await App.network.gamble(request);
    const isCanGamble = !!response.game_result.can_gamble;
    // App.repository
    //   .getCache('spinCurrent')
    //   .set('isCanGamble', isCanGamble);
    // App.repository.getCache('spinCurrent').set('raw', response);
    // App.repository
    //   .getCache('spinCurrent')
    //   .set('gameResult', response.game_result);
    // App.repository.getCache('ui').set('balance', response.balance);
    return {
      // isWin: !!response.game_result.spin.award,
      isCanGamble,
    };
  }
}

import { Container } from 'pixi.js';

import { App } from '../index.js';

export default class Viewport extends Container {
  constructor(options) {
    super();
    this.name = 'Viewport';
    this.serviceType = 'viewport';
    // this._options = options;
    this.sortableChildren = true;
    this.resize = this.resize.bind(this);
    window.addEventListener('resize', this.resize);

    console.error('Viewport constructor', this);
  }

  resize() {
    console.error(
      'Viewport resize',
      App.device.orientation,
      App.device.width,
      App.device.height,
    );
    App.renderer.resize(App.device.width, App.device.height);
    App.renderer.logResize();
    App.layout.resize(App.device.width, App.device.height);
    App.events.emit('resize', App.device.width, App.device.height);
    this.emit('resize');
  }

  get cropWidthRatio() {
    return 1;
    const ratio = Math.min(1, this.widthRatio);
    if (ratio < 0.2) return 0.2;
    if (ratio > 1) return 1;
    return ratio;
  }

  get widthRatio() {
    return 1;
    return window.innerWidth / this.heightRatio / this._options.width;
  }

  get heightRatio() {
    return 1;
    return window.innerHeight / this._options.height;
  }

  get left() {
    return 0;
    return this._options.width * (1 - this.cropWidthRatio) * 0.5;
  }

  get right() {
    return this._options.width;
  }

  isLandscape() {
    return matchMedia('(orientation: landscape)').matches;
  }

  emit(event) {
    App.logger.debug('Viewport emit:', {
      event,
    });
    return super.emit(event);
  }
}

import { App, EVENTS } from '../../../index';
import { GridModel } from './GridModel';
import { GridView } from './GridView';

export class Grid {
  constructor() {
    this.name = 'grid';
    this.model = new GridModel();
    App.events.on(EVENTS.LAYOUT.TOGGLE_GRID, () => {
      this.view.toggle();
    });
    App.events.on(EVENTS.CORE.FSM_STARTED, () => {
      App.fsm.subscribeToState('LOAD', this);
    });
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'LOAD') {
      this.show();
    }

    stateEvent.onFinish(this.name);
  }

  show() {
    if (!this.view) {
      this.view = new GridView(this.model);
    }

    this.view.show();
  }

  addLine(device, orientation, lineType, cb) {
    this.model.addLine(device, orientation, lineType, cb);
  }

  getLineX(name) {
    return this.model.getLineX(name);
  }

  getLineY(name) {
    return this.model.getLineY(name);
  }

  resize() {
    this.view && this.view.resize();
  }
}

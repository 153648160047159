import { Renderer } from 'pixi.js';

import { App } from '../index';

export default class Renderer2D extends Renderer {
  constructor(options) {
    super(options);
    this.name = 'Render2D';
    this.serviceType = 'renderer2D';
    console.error('Renderer', options);

    // window.addEventListener('resize', this.onResize.bind(this));

    // todo: maybe other way to handle event
    App.clock.on('tick', () => {
      this.render(App.viewport);
    });

    console.error('Renderer constructor', this);
  }

  logResize() {
    console.error('Renderer resize', this);
    console.error(
      'Renderer size',
      this.screen.width,
      this.screen.height,
      this.view.width,
      this.view.height,
    );
  }
}

import { Assets } from '@growe/lightcore';

/**
 * Represents a collection of lines configs.
 * @example
 *   [0, 0, 0, 0, 0],
 *   [1, 1, 1, 1, 1],
 *   ...
 */
export default class Lines {
  /**
   * Initializes the data for the class.
   *
   * @param {Array<Array<number>>} lines - The data to be initialized.
   */
  static init(lines = null) {
    // todo: can init automatically when event occures that lines config is present
    if (lines) {
      this._lines = lines;
    }
    this._lines = Assets.get('game_config').winLines;
  }

  /**
   * Retrieves a copy of the line at the specified index.
   *
   * @param {number} lineIndex - The index of the line to retrieve.
   * @throws {Error} When the Lines data is not initialized or when the lineIndex is invalid.
   * @returns {Array<number>} A copy of the line at the specified index.
   */
  static get(lineIndex) {
    if (!this._lines) {
      throw new Error(
        "Please, provide the Lines with data. use 'Lines.init(your_data)'",
      );
    }
    if (!this._lines[lineIndex]) {
      throw new Error(
        "There isn't a line with the index " + lineIndex,
      );
    }
    return this._lines[lineIndex].slice();
  }

  static numberOfLines() {
    return this._lines.length;
  }
}

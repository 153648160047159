import { App } from '@growe/lightcore';
import _ from 'lodash';

import buyBonusResponse from '../../dataSets/bonusWon.json';
import gamblePool from '../../dataSets/gamble.json';
import initMock from '../../dataSets/init.json';
import pool from '../../dataSets/spins_new_big_win.json';
import { delay } from '../utils/utils';

/**
 * Represents a local network.
 * @implements {INetwork}
 */
export default class MockNetwork {
  constructor() {
    this.name = 'LocalNetwork';
    this.serviceType = 'network';
    this.url = 'http://localhost:8000';
    this.sessionToken = '';
    this.balance = 0;
    this.spinNumber = 0;
  }

  get baseAssetsUrl() {
    return './';
  }

  async init(request = {}) {
    const response = initMock;
    this.balance = response.balance;
    await delay(Math.random() * 3000 + 200);
    App.logger.network('Init:', request, response);
    return response;
  }

  async spin(request) {
    // const response = _.cloneDeep(pool[Math.floor(Math.random() * pool.length)]);
    const response = _.cloneDeep(pool[this.spinNumber]);
    this.spinNumber = (this.spinNumber + 1) % pool.length;
    response.game_result.spin.award *= request.bet;
    response.game_result.spin.total_award *= request.bet;
    if (response.game_result.spin.payouts && response.game_result.spin.payouts.length > 0) {
      response.game_result.spin.payouts.forEach(
        (payout) => (payout.amount *= request.bet),
      );
    }
    App.logger.network(
      'Spin balance:',
      this.balance,
      request.bet,
      response.game_result.spin.award,
    );
    this.balance -= request.bet;
    if (response.game_result.spin.award) {
      this.balance += response.game_result.spin.award;
    }
    response.balance = this.balance;

    await delay(Math.random() * 300 + 200);
    App.logger.network('Spin:', request, response);
    return response;
  }

  async gamble(request) {
    const response = gamblePool[0];
    App.logger.network(
      'Spin balance:',
      this.balance,
      request.bet,
      response.game_result.spin.award,
    );
    // this.balance -= request.bet;
    if (response.game_result.spin.award) {
      this.balance += response.game_result.spin.award;
    } else {
      this.balance -= response.game_result.spin.start_wager;
    }
    response.balance = this.balance;

    await delay(Math.random() * 300 + 200);
    App.logger.network('Gamble:', request, response);
    return response;
  }

  finish() {
    return this.createRequest(
      this.url + '/core/show',
      this.createRequestInit({
        session_token: this.sessionToken,
      }),
    );
  }

  buyBonus(bet) {
    return buyBonusResponse;
  }

  /**
   * Create Request
   * @async
   * @param {string} url - The URL for the request.
   * @param {Object} requestInit - The initialization for the request.
   * @return {Promise<Object>} The response from the request.
   */
  async createRequest(url, requestInit) {
    try {
      const response = await fetch(url, requestInit);
      return await response.json();
    } catch (error) {
      return new Error(error);
    }
  }

  createRequestInit(data) {
    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
  }
}

import { Container, Graphics, Text } from '@growe/lightcore';

export default class AutoSpinsNumberButton extends Container {
  constructor(number) {
    super();

    const back = new Graphics();
    back.beginFill(0x4d607c);
    back.drawRoundedRect(0, 0, 133, 60, 8);
    this.addChild(back);

    const border = new Graphics();
    border.lineStyle(2, 0xffbc00, 1, 0);
    border.drawRoundedRect(0, 0, 133, 60, 8);
    this.addChild(border);

    const text = new Text(number, {
      fill: 0xffffff,
      fontFamily: 'Impact',
      fontSize: 32,
    });

    text.anchor.set(0.5, 0.5);
    text.position.set(133 * 0.5, 60 * 0.5);
    this.addChild(text);

    this.eventMode = 'static';
    this.cursor = 'pointer';
  }

  set active(v) {
    this.children[1].visible = v;
    this.children[2].tint = !v ? 0xffffff : 0xffbc00;
  }
}

export class RulesWindow extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    this.render();
    const closeBtn = this.shadowRoot.querySelector('.close-btn');
    closeBtn.addEventListener('click', () => {
      const event = new Event('close');
      this.dispatchEvent(event);
    });
  }

  render() {
    this.shadowRoot.innerHTML = `
        <style>
       
    :host {
        position: relative;
        display: block;
        max-width: 100%;
        max-height: 100%;
        background: #19222F;
        box-sizing: border-box;
    }
    .header {
        height: 50px;
        background: #4D607C;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 0 30px;
        box-sizing: border-box;
        position: absolute;
    }
    .header h2 {
        font-size: 32px;
        font-weight: 500;
        line-height: 45px;
        color: white;
        font-family: 'Teko', sans-serif;
        margin: 0;
    }
    .close-btn {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 5%;
    }
    .close-btn svg {
        width: 70%;
        height: 70%;
        color: white;
        position: relative;
        top: 15%;
        left: 15%;
    }
    .close-btn:hover svg{
        filter: drop-shadow(0 0 10px #fff);
    }
    .content {
        padding: 65px 15px 15px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        height: 500px;
    }
    ::slotted(img) {
        display: block;
    }
    }
</style>
        <div class="header">
            <h2>INFO</h2>
            <div class="close-btn">
                <svg width="72" height="73" viewBox="0 0 72 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.9973 46.5833L26.0255 51.6116L36.082 41.5551L46.1385 51.6116L51.1668 46.5833L41.1103 36.5268L51.1668 26.4703L46.1385 21.4421L36.082 31.4986L26.0255 21.4421L20.9973 26.4703L31.0538 36.5268L20.9973 46.5833ZM10.9408 61.6681C7.4629 58.1902 4.8545 54.2619 3.11556 49.8831C1.37663 45.5044 0.507157 41.0523 0.507157 36.5268C0.507156 32.0014 1.37663 27.5493 3.11556 23.1705C4.8545 18.7918 7.4629 14.8635 10.9408 11.3856C14.4186 7.90771 18.347 5.2993 22.7257 3.56036C27.1045 1.82143 31.5566 0.951957 36.082 0.951958C40.6075 0.951957 45.0596 1.82143 49.4383 3.56036C53.8171 5.2993 57.7454 7.90771 61.2233 11.3856C64.7012 14.8635 67.3096 18.7918 69.0485 23.1705C70.7874 27.5493 71.6569 32.0014 71.6569 36.5268C71.6569 41.0523 70.7874 45.5044 69.0485 49.8831C67.3096 54.2619 64.7012 58.1902 61.2233 61.6681C57.7454 65.146 53.8171 67.7544 49.4383 69.4933C45.0596 71.2322 40.6075 72.1017 36.082 72.1017C31.5566 72.1017 27.1045 71.2322 22.7257 69.4933C18.347 67.7544 14.4187 65.146 10.9408 61.6681ZM15.969 56.6398C21.5839 62.2547 28.2882 65.0622 36.082 65.0622C43.8758 65.0622 50.5802 62.2547 56.195 56.6398C61.8099 51.025 64.6174 44.3206 64.6174 36.5268C64.6174 28.733 61.8099 22.0287 56.195 16.4138C50.5802 10.7989 43.8758 7.99151 36.082 7.99151C28.2882 7.99151 21.5839 10.7989 15.969 16.4138C10.3541 22.0287 7.54671 28.733 7.54671 36.5268C7.54671 44.3206 10.3541 51.025 15.969 56.6398Z" fill="currentColor"/>
                </svg>
            </div>
        </div>
        <div class="content">
            <slot></slot>
        </div>
    `;
  }
}

window.customElements.define('rules-window', RulesWindow);

import { Container } from 'pixi.js';

import { App } from '../index';

const defaultConfig = [
  { name: 'background', zIndex: 0 },
  { name: 'reels_background', zIndex: 1 },
  { name: 'reelset', zIndex: 10 },
  { name: 'reels_frame', zIndex: 20 },
  { name: 'foreground', zIndex: 100 },
  { name: 'overlay', zIndex: 250 },
  { name: 'game_ui', zIndex: 500 },
  { name: 'ui', zIndex: 1000 },
  { name: 'win_popups', zIndex: 2000 },
  { name: 'grid', zIndex: 10000 },
];

export default class Layers {
  constructor(app, config = defaultConfig) {
    this.serviceType = 'layers';
    this.app = app;
    this.layers = {};
    this.setupLayers(config);
    this.debug();
  }

  setupLayers(config) {
    config.forEach((layerConfig) => {
      const layer = new Container();
      layer.name = layerConfig.name;
      layer.zIndex = layerConfig.zIndex;
      this.app.addChild(layer);
      this.layers[layerConfig.name] = layer;
    });
    this.app.sortChildren();
    App.logger.debug(`Layers: ${JSON.stringify(Object.keys(this.layers))}`);
  }

  getLayer(name) {
    return this.layers[name];
  }

  debug() {
    Object.entries(this.layers).forEach(([key, value]) => {
      App.logger.debug(`Layer: ${key}, children: ${value.children.length}`);
    });
  }
}

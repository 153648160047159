import { App } from '@growe/lightcore';
import EventEmitter from 'eventemitter3';

export default class Activity extends EventEmitter {
  static activities = [];
  static update() {
    this.activities.forEach((activity) => {
      activity.update();
    });
  }
  static resize() {
    this.activities.forEach((activity) => {
      activity.resize();
    });
  }

  static add(activity) {
    this.activities.push(activity);
  }

  static remove(activity) {
    const i = this.activities.indexOf(activity);
    if (i >= 0) {
      this.activities.splice(i, 1);
    }
  }

  constructor(name) {
    super();
    this.name = name;
    this.isComplete = false;
  }

  async execute() {
    this.isComplete = true;
    Activity.remove(this);
    App.logger.log(
      `%c Activity is completed: ${this.name} %s`,
      'background: #00ff00',
    );
  }

  async undo() {}

  logName() {
    App.logger.log(
      `%c activity: ${this.name} %s`,
      'background: #FF0000',
    );
  }

  update() {}

  resize() {}
}

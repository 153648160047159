export default class TurboButton {
  constructor(model, view) {
    this.model = model;
    this.view = view;

    this.view.on('pointerup', this.handlePointerUp, this);
  }

  handlePointerUp() {
    this.model.toggleSpeedMode();
  }

  get enable() {
    return this.model.isTurboGameActivated();
  }

  set enable(value) {
    if (this.model.isTurboGameActivated() !== value) {
      this.model.toggleSpeedMode();
    }
  }
}

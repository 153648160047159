import { delay } from '../../utils';
import { App, EVENTS } from '../index';

const defaultConfig = {
  LOAD: {
    transitions: [{ to: 'INIT' }],
  },
  INIT: {
    transitions: [
      {
        to: 'FEATURE_PREVIEW',
      },
    ],
  },
  FEATURE_PREVIEW: {
    transitions: [
      {
        to: 'BONUS_GAME.START',
        condition: 'isBonusGameStart',
      },
      {
        to: 'MAIN_GAME.START',
        condition: 'isMainGameStart',
      },
    ],
  },
  'MAIN_GAME.START': {
    transitions: [
      {
        to: 'MAIN_GAME.IDLE',
      },
    ],
  },
  'MAIN_GAME.IDLE': {
    transitions: [
      {
        to: 'MAIN_GAME.SPIN_START',
      },
    ],
    clearConditions: true,
  },
  'GAMBLE_GAME.START': {
    transitions: [
      {
        to: 'GAMBLE_GAME.IDLE',
      },
    ],
  },
  'GAMBLE_GAME.IDLE': {
    transitions: [
      {
        to: 'GAMBLE_GAME.GAMBLE_START',
        condition: 'isGamblePressed',
      },
      {
        to: 'GAMBLE_GAME.FINISH',
      },
    ],
  },
  'GAMBLE_GAME.GAMBLE_START': {
    transitions: [
      {
        to: 'GAMBLE_GAME.GAMBLE_STOP',
      },
    ],
  },
  'GAMBLE_GAME.GAMBLE_STOP': {
    transitions: [
      {
        to: 'GAMBLE_GAME.FINISH',
        condition: 'isGambleFinished',
      },
      {
        to: 'GAMBLE_GAME.IDLE',
      },
    ],
  },
  'GAMBLE_GAME.FINISH': {
    transitions: [
      {
        to: 'MAIN_GAME.IDLE',
      },
    ],
  },
  'MAIN_GAME.SPIN_START': {
    transitions: [
      {
        to: 'MAIN_GAME.SPIN_STOP',
      },
    ],
  },
  'MAIN_GAME.SPIN_STOP': {
    transitions: [
      {
        to: 'MAIN_GAME.SHOW_WIN',
        condition: 'isWin',
      },
      {
        to: 'MAIN_GAME.IDLE',
      },
    ],
  },
  'MAIN_GAME.SHOW_WIN': {
    transitions: [
      {
        to: 'MAIN_GAME.CAN_GAMBLE',
        condition: 'isCanGamble',
      },
      {
        to: 'MAIN_GAME.IDLE',
      },
    ],
  },
  'MAIN_GAME.CAN_GAMBLE': {
    transitions: [
      {
        to: 'GAMBLE_GAME.START',
        condition: 'isGamble',
      },
      {
        to: 'MAIN_GAME.IDLE',
        condition: 'isSpin',
      },
    ],
    clearConditions: true,
  },
  'BONUS_GAME.START': {
    transitions: [
      {
        to: 'BONUS_GAME.IDLE',
      },
    ],
  },
  'BONUS_GAME.IDLE': {
    transitions: [
      {
        to: 'BONUS_GAME.IDLE',
      },
    ],
  },
};

export default class Fsm {
  constructor(config) {
    this.config = config || defaultConfig;
    this.serviceType = 'fsm';
    this.name = 'Fsm';
    this.states = Object.keys(this.config);
    this.conditions = {};
    this.currentStateIndex = 0;
    this.currentState = 'NULL';
    this.subscriberCount = {};

    this.finishedSubscribersCounts = {};
    this.statesSubscribers = {};
  }

  assertStates() {
    const troubles = [];
    Object.entries(this.config).forEach(([name, config]) => {
      config.transitions.forEach(({ to, priority }) => {
        if (!this.config[to]) {
          troubles.push({
            name,
            to,
            priority,
          });
        }
      });
    });
    if (troubles.length) {
      App.logger.error(
        `FSM config has troubles! Troubles: ${JSON.stringify(troubles)}`,
      );
      throw new Error(`FSM config has troubles!`);
    }
  }

  init() {
    this.assertStates();
    this.states.forEach((state) => {
      this.statesSubscribers[state] = [];
    });
    App.logger.state(
      `FSM initialized! StatesSubscribers: ${JSON.stringify(this.statesSubscribers)}`,
    );
  }

  async start() {
    App.logger.state(`FSM starts. States: ${this.states}`);
    App.events.emit(EVENTS.CORE.FSM_STARTED);
    await delay(1000);
    this.transitionTo(this.states[this.currentStateIndex]);
  }

  // todo: component type
  subscribeToState(state, component) {
    if (!this.statesSubscribers[state]) {
      App.logger.state(
        `Component ${this.name} can not subscribe to state ${state}`,
      );
      return;
    }

    this.statesSubscribers[state].push(component);
    App.logger.state(
      `Component ${component.name} subscribed to state ${state}`,
    );
  }

  transitionTo(state) {
    const stateEvent = {
      current: this.currentState,
      next: state,
      onFinish: (name, conditions = null) => {
        if (typeof name !== 'string') {
          App.logger.error(
            `Wrong ${this.currentState} FSM onFinish call`,
            name,
            conditions,
          );
        }
        // todo: add conditions validation
        if (conditions) {
          this.conditions = { ...this.conditions, ...conditions };
        }
        this.subscriberFinished(name, state);
      },
    };
    this.finishedSubscribersCounts[state] = 0;
    if (this.config[state].clearConditions) {
      this.conditions = {};
    }
    App.events.emit('STATE_CHANGE', stateEvent);
    App.logger.state(`${this.currentState} -> ${state}`, {
      subscribers: this.statesSubscribers[state],
      conditions: this.conditions,
    });

    // todo: remove coupling with components. leave only events
    this.statesSubscribers[state].forEach((component) => {
      App.logger.debug(`Transitioning in ${component.name} to ${state}`);
      component.onStateChange(stateEvent);
    });
    this.currentState = state;

    if (this.statesSubscribers[state].length === 0) {
      App.logger.state(
        `State ${state} has no subscribers. Will go to next state`,
      );
      this.nextState(state);
    }
  }

  nextState(currentState) {
    App.logger.debug(`Transitioning in ${currentState}`);
    const finishedCount = this.finishedSubscribersCounts[currentState];
    const allCount = this.statesSubscribers[currentState].length;
    if (finishedCount === allCount) {
      App.logger.state(
        `All subscribers finished for ${currentState}, ${finishedCount} - ${allCount}`,
        this.statesSubscribers[currentState].map(({ name }) => name),
      );
      const nextState = this.getNextState(currentState);
      this.transitionTo(nextState);
    } else {
      App.logger.state(
        `Waiting for all subscribers to finish for ${currentState}, ${finishedCount} - ${allCount}`,
        this.statesSubscribers[currentState].map(({ name }) => name),
      );
    }
  }

  // todo: need logic with conditions
  // temporary take first
  getNextState(currentState) {
    const { transitions } = this.config[currentState];
    if (transitions.length === 1) {
      return transitions[0].to;
    }

    for (const transition of transitions) {
      if (transition.condition && this.conditions[transition.condition]) {
        return transition.to;
      }
    }

    return transitions[transitions.length - 1].to;
  }

  addSubscriber(state) {
    if (!this.subscriberCount[state]) {
      this.subscriberCount[state] = 0;
      this.finishedSubscribersCounts[state] = 0;
    }
    this.subscriberCount[state]++;
  }

  subscriberFinished(name, state) {
    if (typeof name !== 'string') {
      throw Error(`Subscriber name '${name}' must be a string.`);
    }
    App.logger.state(`Subscriber ${name} finished ${state}`);
    if (!this.currentState === state) {
      App.logger.error(
        `Subscriber ${name} finishes not current state ${state}. Current state ${this.currentState}`,
      );
      return;
    }
    if (
      !this.statesSubscribers[state].find(
        (component) => component.name === name,
      )
    ) {
      App.logger.state(
        `Subscriber ${name} is not subscribed but finished ${state}`,
      );
      return;
    }
    if (!this.finishedSubscribersCounts[state]) {
      this.finishedSubscribersCounts[state] = 0;
    }

    this.finishedSubscribersCounts[state]++;
    this.nextState(state);
  }
}

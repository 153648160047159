export const SETTINGS = {
  viewport: {
    width: 2048,
    height: 568,
  },
  renderer: {
    width: 844,
    height: 390,
    backgroundColor: 0x0005500,
    resolution: 2,
  },
};

import { Assets, Container } from '@growe/lightcore';
import { Spine } from 'pixi-spine';

export default class UIButtonView extends Container {
  constructor(skin) {
    super();
    this.spine.skeleton.setSkinByName(skin);
    this.addChild(this.spine);
    this.spine.eventMode = 'static';
    this.spine.cursor = 'pointer';
    this.spine.on('pointerover', this.handleHover, this);
    this.spine.on('pointerout', this.handleOut, this);
    this.spine.on('pointerdown', this.handleDown, this);
    this.spine.on('pointerup', this.handleUp, this);
    this.spine.on('pointerupoutside', this.handleUpOutside, this);
  }

  set disabled(disabled) {
    if (disabled === this._disabled) return;
    this._disabled = disabled;
    if (this._disabled) {
      this.spine.state.setAnimation(1, 'disabled', true);
      this.spine.scale.set(1);
      this.spine.cursor = 'auto';
    } else {
      this.spine.state.clearTrack(1);
      this.spine.skeleton.setSlotsToSetupPose();
      this.spine.cursor = 'pointer';
    }
  }

  get disabled() {
    return this._disabled;
  }

  set activated(activated) {
    if (activated === this._activated) return;
    this._activated = activated;
    if (this._activated) {
      this.spine.state.setAnimation(2, 'activated', true);
    } else {
      this.spine.state.clearTrack(2);
      this.spine.skeleton.setSlotsToSetupPose();
    }
  }

  get assetName() {
    return 'ui_button';
  }

  get spine() {
    if (!this._spine) {
      this._spine = new Spine(Assets.get(this.assetName).spineData);
    }
    return this._spine;
  }

  handleHover() {
    this.spine.state.setAnimation(0, 'hover', false);
  }

  handleOut() {
    this.spine.state.setAnimation(0, 'out', false);
  }

  handleDown() {
    if (this._disabled) return;
    this.spine.scale.set(0.9);
    this.emit('pointerdown');
  }

  handleUp() {
    if (this._disabled) return;
    this.spine.scale.set(1);
    this.emit('pointerup');
  }

  handleUpOutside() {
    this.emit('pointerupoutside');
    if (this._disabled) return;
    this.spine.scale.set(1);
  }
}

import { Assets } from '@growe/lightcore';

import { WINS_THRESHOLD } from '../../../config/constants';
import Lines from './Lines.js';

/**
 * @typedef {Object} LineData
 * @property {string} 0 - type string.
 * @property {number} 1 - win.
 * @property {number[]} 2 - map.
 * @property {number} 3 - lineIndex.
 */

//payouts
// :
// Array(3)
// 0
// :
// {symbol: 4, count: 2, payline: 0, amount: 5}
// 1
// :
// {symbol: 2, count: 2, payline: 3, amount: 10}
// 2
// :
// {symbol: 4, count: 2, payline: 5, amount: 5}

export default class SpinResult {
  static parseScreen(screen) {
    return screen.map((reel) => {
      return reel.map((index) => {
        return {
          key: Assets.get('slotMachine').symbolsKeys[+index],
          value: 0,
        };
      });
    });
  }

  constructor(rawData) {
    this.config = Assets.get('game_config');
    this.rawMap = rawData.game_result.spin.window;
    this.map = this._parseScreen(rawData.game_result.spin);
    this.pool = this._parseScreen(rawData.game_result.spin);
    this.bet = rawData.game_result.spin.wager || 0;

    this.winLines = this._parseWinLines(
      rawData.game_result.spin.payouts,
    );
    this.allWinSlots = this._parseAllWinSlots();
    this.win = this._parseWin(rawData.game_result);
    this.totalWin = this._parseTotalWin(rawData.game_result.spin);

    this.scattersAward = rawData.game_result.spin.scatter_award || 0;

    this.winScatters = this._parseWinScatters(
      rawData.game_result.spin.scatters,
    );
    this.anticipation = this._parseAnticipation(
      rawData.game_result.spin.scatters,
    );
    this.bonus = rawData.game_result.spin.bonus || undefined;
    this.specialSymbol =
      this.config.gold_symbols[this.bonus?.special - 1] || undefined;
    this.specialWin = rawData.game_result.spin.special_win || 0;
    this.specialReels = rawData.game_result.spin.special_reels || [];
    this.freespinsCredited = this.bonus
      ? rawData.game_result.spin.freespins
      : 0;
    this.freespinsLeft = rawData.game_result?.spin?.freespins || 0;

    this._isBonusGameTriggered =
      rawData.game_result.spin.scatters &&
      rawData.game_result.spin.scatters.length > 2;
  }

  _parseScreen(outcome) {
    const map = outcome.window.map((reel) => {
      return reel.map((index) => {
        return {
          key: this.config.symbols[+index - 1],
          value: 0,
        };
      });
    });
    return map;
  }

  /**
   * Parse the win lines from the outcome object.
   *
   * @param {object} payouts - The outcome object containing win lines information.
   * @returns {LineData[]} - An array of win lines filtered by type 'line'.
   */
  _parseWinLines(payouts) {
    if (!payouts) return [];
    console.log(payouts);
    return payouts.map((item) => {
      const map = Lines.get(item.payline);
      map.length = item.count;
      return ['line', item.amount, map, item.payline];
    });
  }

  _parseAllWinSlots() {
    const lines = this.winLines;
    const map = [];
    lines.forEach(([type, win, slots]) => {
      slots.forEach((slotIndex, i) => {
        if (!map[i]) {
          map[i] = [slotIndex];
          return;
        }
        if (map[i].includes(slotIndex)) return;
        map[i].push(slotIndex);
      });
    });
    return map;
  }

  _parseTotalWin(spin) {
    const win = spin.award || 0;
    const scattersWin = spin.scatter_award || 0;
    const specialWin = spin.special_win || 0;
    return win + scattersWin + specialWin;
  }

  _parseWinScatters(scatters) {
    if (!scatters) return [];

    let map = new Array(this.config.reels).fill(null).map(() => []);

    console.log(map);
    scatters.forEach((position) => {
      map[position.X].push(position.Y);
    });

    return map;
  }

  _parseWinWilds(outcome) {
    if (!outcome.special_symbols.wild) return [];
    const wild = outcome.special_symbols.wild[this.config.wild];
    if (!wild) {
      return [];
    }

    const map = [];
    for (let i = 0; i < this.config.reels; i++) {
      map.push([]);
    }
    wild.forEach(([reel, slot]) => {
      map[reel].push(slot);
    });

    return map;
  }

  _parseAnticipation(scatters) {
    const map = new Array(this.config.reels).fill(false);
    if (!scatters) return map;

    if (!scatters[1]) return map;

    const startReel = scatters[1].X + 1;
    if (startReel === this.config.reels) return map;

    for (let i = startReel; i < this.config.reels; i++) {
      map[i] = true;
    }

    return map;
  }

  _parseWin(result) {
    return result.spin.award || 0;
  }

  extractRecordFromPool(reel) {
    return this.pool[reel].pop();
  }

  isReelPoolEmpty(reel) {
    return this.pool[reel].length === 0;
  }

  isWinLines() {
    return this.winLines.length > 0;
  }

  isBigWin() {
    return this.totalWin / this.bet >= WINS_THRESHOLD.BIG;
  }

  isMegaWin() {
    return this.totalWin / this.bet >= WINS_THRESHOLD.MEGA;
  }

  isSuperWin() {
    return this.totalWin / this.bet >= WINS_THRESHOLD.SUPER;
  }

  isBonusGameTriggered() {
    return this._isBonusGameTriggered;
  }
}

const LOG_LEVELS = [
  'all',
  'debug',
  'state',
  'network',
  'event',
  'info',
  'warn',
  'error',
  'none',
];
const DEFAULT_COLORS = {
  state: {
    bg: '#001f3f',
    text: '#ffffff',
  },
  network: {
    bg: '#3c8104',
    text: '#ffffff',
  },
  event: {
    bg: '#05b6ae',
    text: '#233993',
  },
  debug: {
    bg: '#7FDBFF',
    text: '#000000',
  },
  info: {
    bg: '#39CCCC',
    text: '#000000',
  },
  warn: {
    bg: '#FFDC00',
    text: '#000000',
  },
  error: {
    bg: '#FF4136',
    text: '#ffffff',
  },
};

export default class Logger {
  constructor(settings) {
    this.serviceType = 'logger';
    this.name = 'Logger';
    this.settings = settings;
    this.colors = settings?.colors || DEFAULT_COLORS;
  }

  log(...args) {
    this.info(...args);
  }

  network(...args) {
    if (!this.shouldLog('network')) return;
    console.log(
      `%c Network:`,
      `background: ${this.colors.network.bg}; color: ${this.colors.network.text}`,
      ...args,
    );
  }

  event(...args) {
    if (!this.shouldLog('event')) return;
    console.log(
      `%c Event:`,
      `background: ${this.colors.event.bg}; color: ${this.colors.event.text}`,
      ...args,
    );
  }

  state(...args) {
    if (!this.shouldLog('state')) return;
    console.log(
      `%c State:`,
      `background: ${this.colors.state.bg}; color: ${this.colors.state.text}`,
      ...args,
    );
  }

  debug(...args) {
    if (!this.shouldLog('debug')) return;
    console.log(
      `%c Debug:`,
      `background: ${this.colors.debug.bg}; color: ${this.colors.debug.text}`,
      ...args,
    );
  }

  info(...args) {
    if (!this.shouldLog('info')) return;
    console.log(...args);
  }

  warn(...args) {
    if (!this.shouldLog('warn')) return;
    console.warn(
      `%c Warn:`,
      `background: ${this.colors.warn.bg}; color: ${this.colors.warn.text}`,
      ...args,
    );
  }

  error(...args) {
    if (!this.shouldLog('error')) return;
    console.error(
      `%c Error:`,
      `background: ${this.colors.error.bg}; color: ${this.colors.error.text}`,
      ...args,
    );
  }

  shouldLog(level) {
    if (!this.settings?.enabled) return false;
    const currentLevelIndex = LOG_LEVELS.indexOf(this.settings.level);
    const levelIndex = LOG_LEVELS.indexOf(level);
    return currentLevelIndex <= levelIndex && currentLevelIndex !== -1;
  }

  setLevel(newLevel) {
    if (LOG_LEVELS.includes(newLevel)) {
      this.settings.level = newLevel;
    } else {
      console.warn('Invalid logging level:', newLevel);
    }
  }
}

import { App, EVENTS } from '@growe/lightcore';
import fscreen from 'fscreen';

import Bets from './model/Bets.js';

export default class UiModel {
  constructor() {
    this._balance = 100000;
    this._lastWin = 0;
    this._turboMode = false;
    this._autospinsBlocked = false;
    this.selectedAutoSpinsPreset = 0;
  }

  init() {
    this._balance = App.repository.getCache('init').get('balance');
  }

  get autoSpinsPresets() {
    return [10, 25, 50, 100];
  }

  get autoSpinsPresetAmount() {
    return this.autoSpinsPresets[this.selectedAutoSpinsPreset];
  }

  setAutoSpins(n) {
    this._autospinsAmount = n > 0 ? n : 0; // (n > 2 ? 2: n ) : 0; //todo: remove hardcode
    App.repository.getCache('ui').set('isAutospins', n > 0);
  }

  get currentAutoSpin() {
    return this._autospinsAmount;
  }

  startAutoSpin() {
    this.setAutoSpins(this.currentAutoSpin - 1);
  }

  set balance(balance) {
    this._balance = balance;
  }

  get balance() {
    return this._balance;
  }

  get bet() {
    return Bets.current;
  }

  set lastWin(lastWin) {
    this._lastWin = lastWin;
  }

  get lastWin() {
    return this._lastWin;
  }

  // todo: fix naming
  isAutoGameActivated() {
    return this._autospinsAmount > 0;
  }

  isAutoGameAvailable() {
    return !this._autospinsBlocked;
  }

  isTurboGameActivated() {
    return this._turboMode;
  }

  isTurboGameAvailable() {
    return true;
  }

  isBetSwitchingAvailable() {
    return this._betSwitchingBlocked;
  }

  isBetMax() {
    return Bets.isLastBet();
  }

  isBetMin() {
    return Bets.isFirstBet();
  }

  nextBet() {
    Bets.next();
    App.repository.getCache('ui').set('bet', this.bet);
  }

  previousBet() {
    Bets.previous();
    App.repository.getCache('ui').set('bet', this.bet);
  }

  toggleSpeedMode() {
    this._turboMode = !this._turboMode;
    App.repository.getCache('ui').set('turboMode', this._turboMode);
    App.events.emit(EVENTS.UI.TURBO_BUTTON_DOWN, {
      turboMode: this._turboMode,
    });
  }

  isFullScreen() {
    return (
      fscreen.fullscreenElement !== null &&
      fscreen.fullscreenElement !== undefined
    );
  }

  isFullScreenEnabled() {
    return fscreen.fullscreenEnabled;
  }

  toggleFullScreen() {
    if (!this.isFullScreen()) {
      fscreen.requestFullscreen(document.body);
    } else {
      fscreen.exitFullscreen();
    }
  }

  isSoundsOn() {
    return !App.audio.mute;
  }

  toggleSound() {
    App.audio.mute = !App.audio.mute;
  }

  set autoSpinsBlocked(v) {
    this._autospinsBlocked = v;
  }

  set betSwitchingBlocked(v) {
    this._betSwitchingBlocked = v;
  }
}

import UIButtonView from './UIButtonView.js';

export default class SoundButtonView extends UIButtonView {
  constructor() {
    super('default');
  }

  get assetName() {
    return 'small_sound_button';
  }
}

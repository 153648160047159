import { Assets, Texture } from 'pixi.js';

import { HackAttachment } from './HackAttachment';

export class SlotAttachment extends HackAttachment {
  createTexture() {
    this.texture = Texture.EMPTY;
  }

  createContent(options) {}

  set image(textureName) {
    this.texture = textureName ? Assets.get(textureName) : Texture.EMPTY;
    this.update();
  }

  update() {
    this.spine.hackTextureBySlotName(this.slotName, this.texture, this.size);
  }
}

import { Sprite, Texture } from '@growe/lightcore';
import { Assets } from '@growe/lightcore';
import { App } from '@growe/lightcore';
import { gsap } from 'gsap';

import { SETTINGS } from '../../config/settings.js';
import Rules from '../components/rules/Rules.js';
import Activity from './Activity.js';

export default class ShowRules extends Activity {
  constructor() {
    super('ShowRules');
  }

  async execute() {
    Activity.add(this);
    this.logName();
    this.showOverlay();
    await this.load();
    this.create();
  }

  showOverlay() {
    this.overlay = new Sprite(Texture.WHITE);
    this.overlay.width = SETTINGS.app.renderer.width;
    this.overlay.height = SETTINGS.app.renderer.height;
    App.viewport.addChild(this.overlay);
    this.overlay.eventMode = 'static';
    this.overlay.tint = 0x000000;
    this.overlay.alpha = 0;
    this.overlay.zIndex = 11;
    gsap.to(this.overlay, {
      alpha: 0.9,
      duration: 0.3,
    });
  }

  async load() {
    Assets.add({
      alias: 'rules_content',
      srcs: `rules/rules.html`,
    });
    await Assets.load('rules_content');
    this.isLoaded = true;
  }

  create() {
    this.scene = new Rules();
    this.scene.zIndex = 12;
    App.viewport.addChild(this.scene);
    this.scene.on('close', this.handleCloseBtnDown, this);
    this.resize();
  }

  resize() {
    this.scene && this.scene.resize();
  }

  update() {
    this.scene && this.scene.update(App.clock.deltaMS * 0.001);
  }

  hideViews() {
    return gsap
      .timeline()
      .add(this.hideScene(), 0)
      .add(this.hideOverlay(), 0);
  }

  showScene() {
    gsap.to(this.scene, {
      alpha: 1,
      duration: 0.3,
    });
  }

  hideScene() {
    return gsap.to(this.scene, {
      alpha: 0,
      duration: 0.3,
    });
  }

  hideOverlay() {
    return gsap.to(this.overlay, {
      alpha: 0,
      duration: 0.3,
    });
  }

  handleCloseBtnDown() {
    this.hideViews().add(() => {
      this.scene.destroy();
      this.overlay.destroy();
      super.execute();
    });
  }
}

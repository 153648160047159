import EventEmitter from 'eventemitter3';
import { Spine } from 'pixi-spine';
import { Container, Graphics, RenderTexture, Texture } from 'pixi.js';

import { App } from '../../app';

export class HackAttachment extends EventEmitter {
  #boundaries;

  static debugParams = {
    counter: 0,
  };

  constructor(size, options = {}) {
    super();
    this.size = size;
    this.options = options;
    this.container = new Container();
    this.createTexture();
    this.createContent(options);
    this.createBoundaries();
  }

  createTexture() {
    this.texture = RenderTexture.create({
      ...this.size,
      resolution: this.options.resolution || App.renderer.resolution,
    });
  }

  createContent(options) {}

  createBoundaries() {
    this.#boundaries = new Graphics();
    this.container.addChild(this.#boundaries);
  }

  /**
   *
   * @param {Spine} spine
   * @param {*} slotName
   */
  attachTo(spine, slotName) {
    this.slotName = slotName;
    this.spine = spine;
    spine.hackTextureBySlotName(slotName, this.texture, this.size);
  }

  detach() {
    this.spine?.hackTextureBySlotName(this.slotName, Texture.EMPTY);
    this.spine = null;
  }

  destroy() {
    this.size = null;
    this.texture.destroy(true);
    this.container.destroy({
      children: true,
    });
  }

  update() {
    App.renderer.render(this.container, {
      renderTexture: this.texture,
    });
  }

  set boundaries(visible) {
    this.#boundaries.visible = visible;
    if (visible) this.drawBoundaries();
    this.update();
  }

  drawBoundaries() {
    this.#boundaries
      .clear()
      .lineStyle(2, 0xff0000)
      .drawRect(0, 0, this.size.width, this.size.height);
  }

  resize(size) {
    this.size = size;
    this.texture.resize(size.width, size.height);
    this.drawBoundaries();
  }
}

import { App, Component } from '@growe/lightcore';

import BackgroundView from './BackgroundView';

export class Background extends Component {
  subscribeToFsm() {
    this.fsm.subscribeToState('FEATURE_PREVIEW', this);
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'FEATURE_PREVIEW') {
      App.logger.debug('Loaded state change', stateEvent);
      await this.show();
      stateEvent.onFinish(this.name);
    }
  }

  async show() {
    this.view = new BackgroundView();
    App.layers.getLayer('background').addChild(this.view);
    this.view.setMainEnvironment();
    this.view.resize();
    this.view.once('pointerdown', (e) => {
      App.events.emit('BACKGROUND.POINTER_DOWN');
    });
  }
}

import { App } from '../../index';

const config = {
  mobile: {
    landscape: {
      vertical: {
        center: { x: 0.5, alpha: 0.5, color: 0xffffff },
      },
      horizontal: {
        center: { x: 0.5, alpha: 0.5, color: 0xffffff },
      },
    },
    portrait: {
      vertical: {
        center: { x: 0.5, alpha: 0.5, color: 0xffffff },
      },
      horizontal: {
        center: { x: 0.5, alpha: 0.5, color: 0xffffff },
      },
    },
  },
  tablet: {
    landscape: {
      vertical: {
        center: { x: 0.5, alpha: 0.5, color: 0xffffff },
      },
      horizontal: {
        center: { x: 0.5, alpha: 0.5, color: 0xffffff },
      },
    },
    portrait: {
      vertical: {
        center: { x: 0.5, alpha: 0.5, color: 0xffffff },
      },
      horizontal: {
        center: { x: 0.5, alpha: 0.5, color: 0xffffff },
      },
    },
  },
  desktop: {
    landscape: {
      vertical: {
        center: { x: 0.5, alpha: 0.5, color: 0xffffff },
      },
      horizontal: {
        center: { x: 0.5, alpha: 0.5, color: 0xffffff },
      },
    },
    portrait: {
      vertical: {
        center: { x: 0.5, alpha: 0.5, color: 0xffffff },
      },
      horizontal: {
        center: { x: 0.5, alpha: 0.5, color: 0xffffff },
      },
    },
  },
};

export class GridModel {
  constructor() {
    this.config = config;
    this.customLines = {};
    this.recalculatePositions();
  }

  recalculatePositions() {
    this.gridWidth = App.device.width;
    this.gridHeight = App.device.height;
    this.lines = {
      landscape: this.calculatePositions(
        this.config[App.device.type]?.landscape,
      ),
      portrait: this.calculatePositions(this.config[App.device.type]?.portrait),
    };
  }

  calculatePositions(config) {
    if (!config) {
      return [];
    }

    let lines = [];

    // Calculate vertical line positions based on config
    for (let key in config.vertical) {
      let x = config.vertical[key].x * this.gridWidth;
      const line = {
        name: key,
        start: { x: x, y: 0 },
        end: { x: x, y: this.gridHeight },
      };
      line.color = config.vertical[key].color;
      line.alpha = config.vertical[key].alpha;
      lines.push(line);
    }

    // Calculate horizontal line positions based on config
    for (let key in config.horizontal) {
      let y = config.horizontal[key].x * this.gridHeight;
      const line = {
        name: key,
        start: { x: 0, y: y },
        end: { x: this.gridWidth, y: y },
      };
      line.color = config.horizontal[key].color;
      line.alpha = config.horizontal[key].alpha;
      lines.push(line);
    }

    for (let key in config.custom) {
      const line = config.custom[key].calculationCallBack();
      line.name = key;
      lines.push(line);
    }

    return lines;
  }

  getLines(mode) {
    return this.lines[mode];
  }

  addLine(device, orientation, lineName, calculationCallBack) {
    if (!this.config[device]) {
      this.config[device] = {};
    }

    if (!this.config[device][orientation]) {
      this.config[device][orientation] = {};
    }

    if (!this.config[device][orientation]['custom']) {
      this.config[device][orientation]['custom'] = {};
    }

    this.config[device][orientation]['custom'][lineName] = {
      calculationCallBack,
    };

    this.recalculatePositions();
  }

  getLineY(name) {
    const line = this.lines[App.device.orientation].find(
      (line) => line.name === name,
    );
    if (!line)
      throw new Error(
        `Layout Grid line ${name} not found in ${App.device.mode}`,
      );
    return line.start.y;
  }

  getLineX(name) {
    const line = this.lines[App.device.orientation].find(
      (line) => line.name === name,
    );
    if (!line)
      throw new Error(
        `Layout Grid line ${name} not found in ${App.device.mode}`,
      );
    return line.start.x;
  }
}

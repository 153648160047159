import { Assets, Container } from '@growe/lightcore';
import { TextAttachment } from '@growe/lightcore';
import { Spine } from 'pixi-spine';

export default class BasicWin extends Container {
  constructor() {
    super();
    this._create();
  }

  _create() {
    this.spine = new Spine(Assets.get('basic_win').spineData);
    this.spine.autoUpdate = false;
    this.addChild(this.spine);

    this.textAttachment = new TextAttachment({
      width: 400,
      height: 100,
    });
    this.textAttachment.autoScaleToFit = true;
    this.textAttachment.boundaries = false;
    this.textAttachment.text = '0.00';
    this.textAttachment.style = {
      dropShadow: true,
      dropShadowAlpha: 2,
      dropShadowAngle: 0,
      dropShadowColor: '#8a3700',
      dropShadowDistance: 0,
      fill: [
        '#ff8a00',
        '#fecc15',
        '#fcff3e',
        '#fffef8',
        '#fee135',
        '#ff8a00',
        '#ffb224',
      ],
      fontFamily: 'Inter-Medium',
      fontSize: 95,
      stroke: '#8d3e04',
      strokeThickness: 3,
    };
    this.textAttachment.center();
    this.textAttachment.attachTo(this.spine, 'numbers');
    this.textAttachment.attachTo(this.spine, 'numbers_additive');

    this.value = '0.00';
    this.isShown = false;
  }

  set value(value) {
    this._value = value;
    this.textAttachment.text = value;
    this.textAttachment.update();
  }

  update(dt) {
    this.spine.update(dt);
  }

  async show() {
    if (this.isShown) return;
    this.spine.state.setAnimation(0, 'in', false, 0);
    await this.spine.state.addAnimation(0, 'idle', true, 0);
    this.isShown = true;
  }

  hide() {
    if (!this.isShown) return;
    this.isShown = false;
    this.spine.state.setAnimation(0, 'out');
    this.spine.state.addAnimation(0, 'hidden');
  }

  hidden() {
    this.isShown = false;
    this.spine.state.setAnimation(0, 'hidden');
  }

  destroy(_options) {
    this.spine.destroy();
    this.textAttachment.destroy();
    super.destroy(_options);
  }
}

import { Assets, Container } from '@growe/lightcore';
import { Spine } from 'pixi-spine';

export default class SettingSwitcher extends Container {
  constructor() {
    super();
    this._checked = false;
    this.spine = new Spine(Assets.get('ui_switcher').spineData);
    this.addChild(this.spine);
    this.spine.eventMode = 'static';
    this.spine.cursor = 'pointer';
    this.spine.on('pointerup', this.handlePointerUp, this);
    this.spine.on('pointerdown', this.handlePointerDown, this);
    this.spine.stateData.defaultMix = 0.2;
    this.spine.state.setAnimation(0, 'off', false);
  }

  set checked(v) {
    if (this._checked === !!v) return;
    const anim = v ? 'on' : 'off';
    this.spine.state.setAnimation(0, anim, false);
    this._checked = !!v;
  }

  get checked() {
    return this._checked;
  }

  get disabled() {
    return this._disabled;
  }

  set disabled(v) {
    this._disabled = !!v;
    this.spine.alpha = v ? 0.3 : 1;
  }

  handlePointerUp() {
    if (this._disabled) return;
    this.emit('pointerup');
  }

  handlePointerDown() {
    if (this._disabled) return;
    this.emit('pointerdown');
  }
}

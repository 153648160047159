import { Component, EVENTS } from '@growe/lightcore';

import OverlayView from './OverlayView';

export class Overlay extends Component {
  subscribeToEvents() {
    this.events.on(EVENTS.GAMBLE.BUTTON_PRESSED, () => {
      this.show();
    });
    this.events.on(EVENTS.GAMBLE.FINISHED, () => {
      this.hide();
    });
  }

  subscribeToFsm() {
    this.fsm.subscribeToState('INIT', this);
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'INIT') {
      this.init();
    }

    stateEvent.onFinish(this.name);
  }

  init() {
    this.view = new OverlayView();
  }

  show() {
    this.view.show({
      onPress: () => {
        this.events.emit(EVENTS.OVERLAY.PRESSED);
      },
    });
    this.events.emit(EVENTS.OVERLAY.SHOWN);
  }

  hide() {
    this.view.hide();
    this.events.emit(EVENTS.OVERLAY.HIDDEN);
  }
}

import { Component } from '@growe/lightcore';

import ReelsBackgroundView from './ReelsBackgroundView';

export class ReelsBackground extends Component {
  subscribeToFsm() {
    this.fsm.subscribeToState('MAIN_GAME.START', this);
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'MAIN_GAME.START') {
      await this.show();
      stateEvent.onFinish(this.name);
    }
  }

  async show() {
    this.view = new ReelsBackgroundView();
    this.view.setMainEnvironment();
    this.view.resize();
  }
}

import { App, Assets } from '@growe/lightcore';

import Currency from '../../../game/models/Currency.js';

/**
 * Class representing a collection of paytables.
 */
export default class Paytable {
  static BET_DIVIDER = 10;

  // todo:
  static init() {
    this.symbols = Assets.get('game_config').symbols;
    this.wildSymbols = Assets.get('game_config').wilds;
    this.scatterSymbols = Assets.get('game_config').scatters;
    this.paytable = Assets.get('game_config').paytable;
  }

  static getPaytableText(key, bet) {
    const index = this.symbols.indexOf(key) + 1;
    if (index === -1) {
      throw new Error(
        `Element ${key} is not found in the array of symbols.`,
      );
    }

    const multipliers = this.paytable[index];
    console.log(multipliers);
    const paytableText = multipliers.reduce(
      (result, multiplier, index) => {
        const calculatedValue = (multiplier * bet) / this.BET_DIVIDER;

        if (calculatedValue !== 0) {
          result.push(
            `${index + 1}: ${Currency.getFormattedValue(calculatedValue)}`,
          );
        }

        return result;
      },
      [],
    );

    return paytableText.join('\n');
  }
}

import {
  LoaderParserPriority,
  checkDataUrl,
  checkExtension,
} from '@growe/lightcore';
import { ExtensionType, extensions, settings } from '@pixi/core';

const validHTMLExtension = '.html';
const validHTMLMIME = 'text/html';

/** Simple loader plugin for loading rules content */
export const loadHtml = {
  name: 'loadHtml',

  extension: {
    type: ExtensionType.LoadParser,
    priority: LoaderParserPriority.Low,
  },

  test(url) {
    return (
      checkDataUrl(url, validHTMLMIME) ||
      checkExtension(url, validHTMLExtension)
    );
  },

  async load(url) {
    const response = await settings.ADAPTER.fetch(url);

    const html = await response.text();

    return html;
  },
};

extensions.add(loadHtml);

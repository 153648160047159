import { App, Assets } from '@growe/lightcore';
import { Spine } from 'pixi-spine';

export default class LogoView extends Spine {
  constructor() {
    super(Assets.get('logo').spineData);
    this.resize();
    this.state.setAnimation(0, 'idle', true);
  }

  setMainMode() {
    this.mode = 'main';
    this.visible = true;
  }

  setBonusMode() {
    this.mode = 'bonus';
    this.visible = App.viewport.cropWidthRatio < 0.8;
  }

  setSkin(skin) {
    this.skeleton.setSkin(null);
    this.skeleton.setSkinByName(skin);
    this.skeleton.setToSetupPose();
  }

  setState(state) {
    this.currentState = this.states[state];
    this.currentState.enter();
  }

  setMainEnvironment() {
    this.state.setAnimation(0, 'idle', true);
    this.resize();
  }

  setAnimation(animation) {
    App.logger.debug('LogoView:', animation);
    if (!animation) return;
    this.state.setAnimation(1, animation, true);
    // this.state.addEmptyAnimation(1, 0.2, 0);
  }

  handleInput(inputs) {
    this.currentState.handleInput(inputs);
    this.visibility.handleInput(inputs);
  }

  resize() {
    this.scale.set(1);
    this.setAnimation('idle');

    if (App.device.orientation === 'landscape') {
      this.visible = true;
      this.setSkin('landscape');
      const scale = Math.min(
        (0.9 *
          (App.device.width -
            App.layout.getGridLineX('reels_right'))) /
          this.width,
        (App.device.height * 0.3) / this.height,
      );
      this.scale.set(scale);
      this.position.y = (8 * App.device.height) / 20;
      this.position.x = App.layout.getGridLineX('logo_center');
    }

    if (App.device.orientation === 'portrait') {
      this.setSkin('portrait');
      const bottom = App.layout.getGridLineY('logo_bottom');
      this.visible = !!bottom;
      this.scale.set((0.8 * bottom) / this.height);
      this.position.y = bottom / 2;
      this.position.x = App.device.width / 2;
    }
  }
}

import DebugActivator from './DebugActivator';

export default class Debug {
  constructor(settings = { enabled: true }) {
    this.serviceType = 'debug';
    this.name = 'Debug';
    this.settings = settings;
    if (settings.enabled) {
      this.activator = new DebugActivator();
    }
  }
}

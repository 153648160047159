import { Text } from 'pixi.js';

import { HackAttachment } from './HackAttachment.js';

export class TextAttachment extends HackAttachment {
  constructor(size, options) {
    super(size, options);
    this.autoScaleToFit = false;
    this.maxScale = 1;
  }

  createContent() {
    this._text = new Text('');
    this.container.addChild(this._text);
  }

  set text(text) {
    this._text.text = text;
  }

  get text() {
    return this._text.text;
  }

  set style(style) {
    this._text.style = style;
  }

  get style() {
    return this._text.style;
  }

  set anchor(anchor) {
    this._text.anchor.set(anchor.x, anchor.y);
  }

  get anchor() {
    return { ...this._text.anchor };
  }

  set x(x) {
    this._text.x = x;
  }

  set y(y) {
    this._text.y = y;
  }

  center() {
    this._text.anchor.set(0.5);
    this._text.x = this.size.width * 0.5;
    this._text.y = this.size.height * 0.5;
  }

  scaleToFit() {
    this._text.scale.set(1);
    const factor = Math.min(
      this.maxScale,
      this.size.width / this._text.width,
      this.size.height / this._text.height,
    );
    this._text.scale.set(factor);
  }

  update() {
    if (this.autoScaleToFit) {
      this.scaleToFit();
    }
    super.update();
  }
}

import { Container } from 'pixi.js';

import { App } from '../index';

const defaultConfig = [
  { name: 'game' },
  { name: 'partner' },
  { name: 'query' },
];

export default class Settings {
  #settings = {};

  constructor(config = defaultConfig) {
    this.serviceType = 'settings';
    this.config = config;
    this.setup();
  }

  setup() {
    this.config.forEach((cacheConfig) => {
      this.#settings[cacheConfig.name] = new Map();
    });
    App.logger.debug(
      `Settings: ${JSON.stringify(Object.keys(this.#settings))}`,
    );
  }

  getSettings(name) {
    return this.#settings[name];
  }

  init(settings, type) {
    const setting = this.getSettings(type);
    if (setting) {
      Object.entries(settings).forEach(([key, value]) => {
        setting.set(key, value);
      });
    }

    this.initQuerySettings();
  }

  initQuerySettings() {
    if (!this.#settings.query) return;

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((value, key) => {
      this.#settings.query.set(key, value);
    });
  }
}

import EventEmitter from 'eventemitter3';

/**
 * The class represents null clock service that Index provides.
 */
export default class NullClock extends EventEmitter {
  constructor() {
    super();
    this.name = 'NullClock';
    this.serviceType = 'clock';
    this.elapsedTime = 0;
    this.deltaMS = 0;
    this.fps = 0;
  }

  run() {}
}

import { App, SpineScene } from '@growe/lightcore';

export default class InitView extends SpineScene {
  constructor() {
    super('preloader');
    this.name = 'Preloader';
    App.layers.getLayer('background').addChild(this);
    this.visible = false;
    this.resize();
    this._progress = null;
  }

  show() {
    this.visible = true;
    this.resize();
  }

  set progress(v) {
    this._progress = v;
    this.updateProgress();
  }

  updateProgress() {
    if (!this._progress) {
      return;
    }

    this.skeleton.findPathConstraint('path').position =
      this._progress;
  }

  resize() {
    this.position.set(
      App.device.width * 0.5,
      App.device.height * 0.5,
    );

    if (
      App.device.type === 'mobile' &&
      App.device.orientation === 'landscape'
    ) {
      this.skeleton.setSkinByName('pc');
      this.skeleton.setToSetupPose();
      this.scale.set(1);
      this.scale.set(App.device.height / this.height);
    }

    if (
      App.device.type === 'mobile' &&
      App.device.orientation === 'portrait'
    ) {
      this.skeleton.setSkinByName('mob');
      this.skeleton.setToSetupPose();
      this.scale.set(1);
      this.scale.set(App.device.width / this.width);
    }

    this.updateProgress();
  }
}

import {
  Assets,
  Circle,
  Container,
  Graphics,
  Rectangle,
} from '@growe/lightcore';
import { TextAttachment } from '@growe/lightcore';
import { App } from '@growe/lightcore';
import { Spine } from 'pixi-spine';

import State from '../../../game/State.js';

const STATES = {
  ACTIVE: 0,
  INACTIVE: 1,
  AUTO_SPINS: 2,
};

export default class SpinButtonView extends Container {
  constructor(model) {
    super();
    this.model = model;
    this.spine = new Spine(Assets.get('spin_button').spineData);
    this.rectSpine = new Spine(Assets.get('grab_button').spineData);
    this.rectSpine.skeleton.setSkinByName('autospin');
    this.spine.skeleton.setSkinByName('spin');
    this.spine.autoUpdate = false;
    this.rectSpine.autoUpdate = true;
    this.rectSpine.visible = false;

    // this.debugRect = new Graphics();
    // this.addChild(this.debugRect);
    this.addChild(this.spine);
    this.addChild(this.rectSpine);

    this.spine.eventMode = 'static';
    this.spine.cursor = 'pointer';
    this.rectSpine.eventMode = 'static';
    this.rectSpine.cursor = 'pointer';
    this.spine.on('pointerover', this.handleHover, this);
    this.spine.on('pointerout', this.handleOut, this);
    this.spine.on('pointerdown', this.handleDown, this);
    this.spine.on('pointerup', this.handleUp, this);
    this.rectSpine.on('pointerup', this.handleUp, this);
    this.spine.on('pointerupoutside', this.handleUpOutside, this);

    this.spine.hitArea = new Circle(0, 0, 105);
    // this.eventMode = 'static';
    // this.cursor = 'pointer';
    this.rectSpine.hitArea = new Rectangle(-105, -105, 210, 210);
    // this.on('pointerdown', () => console.error('IIIIIIIIIIiiiii'));

    this.states = [
      new ActiveState(this),
      new InactiveState(this),
      new AutoSpinsState(this),
    ];

    this.setState(STATES.ACTIVE);
    this.setStatic();
  }

  setState(state) {
    if (this.currentState === this.states[state]) return;
    this.currentState = this.states[state];
    this.currentState.enter();
  }

  set autoSpinsCount(value) {
    if (
      this.autoSpinsIndicator?.text &&
      value !== +this.autoSpinsIndicator.text
    ) {
      console.error(
        'Auto Spins Count Before',
        this.autoSpinsIndicator.text,
        value,
      );
      this.autoSpinsIndicator.text = value;
      this.autoSpinsIndicator.update();
      console.error(
        'Auto Spins Count After',
        this.autoSpinsIndicator.text,
        value,
      );
    }
  }

  update() {
    this.currentState.handleInput();

    this.autoSpinsCount = this.model.currentAutoSpin;

    this.spine &&
      this.spine.visible &&
      this.spine.update(App.clock.deltaMS * 0.001);
    this.rectSpine &&
      this.rectSpine.visible &&
      this.rectSpine.update(App.clock.deltaMS * 0.001);

    // const bounds = this.getLocalBounds();
    // this.debugRect.beginFill(0xFF9900);
    // this.debugRect.drawRect(bounds.x, bounds.y, bounds.width, bounds.height);
    // this.debugRect.endFill();
  }

  setActive() {
    this.spine.visible = true;
    this.spine.skeleton.setSkin(null);
    this.spine.skeleton.setSkinByName('spin');
    this.spine.skeleton.setSlotsToSetupPose();
    this.rectSpine.visible = false;
    this.rectSpine.skeleton.setSkin(null);
    this.rectSpine.skeleton.setSkinByName('grab');
    this.rectSpine.skeleton.setSlotsToSetupPose();
  }

  setAutoSpins() {
    this.setState(STATES.AUTO_SPINS);
    this.spine.visible = false;
    this.rectSpine.visible = true;
    this.rectSpine.skeleton.setSkin(null);
    this.rectSpine.skeleton.setSkinByName('autospin');
    this.rectSpine.skeleton.setSlotsToSetupPose();
    this.rectSpine.state.setAnimation(0, 'static', false);
    this.autoSpinsIndicator = new TextAttachment({
      width: 170,
      height: 180,
    });
    this.autoSpinsIndicator.text = '0';
    this.autoSpinsIndicator.style = {
      fill: '#ff40aa',
      fontFamily: 'Inter-SemiBoldItalic',
      fontSize: 60,
      stroke: '#ffbbe1',
      strokeThickness: 2,
      // dropShadowAlpha: 0.4,
      // dropShadowAngle: 0,
      // dropShadowBlur: 60,
      // dropShadowColor: '#1f0900',
      // dropShadowDistance: 0,
      // fill: [
      //   '#bf7e00',
      //   '#efcb2e',
      //   '#fff3c4',
      //   '#e4db48',
      //   '#e3b229',
      //   '#c78800',
      // ],
      // fillGradientStops: [0.2, 0.4, 0.5],
      // fontFamily: 'RussoOne Regular',
      // fontSize: 90,
      // stroke: '#510e04',
      // strokeThickness: 4,
    };
    this.autoSpinsIndicator.center();
    // this.autoSpinsIndicator.boundaries = true;
    this.autoSpinsIndicator.maxScale = 1 / this.scale.x;
    this.autoSpinsIndicator.autoScaleToFit = true;
    this.autoSpinsIndicator.attachTo(this.rectSpine, 'numbers');
    this.rectSpine.update(App.clock.deltaMS * 0.001);
  }

  setStatic() {
    this.currentState.setStatic();
  }
  setGamble() {
    this.spine.visible = false;
    this.rectSpine.visible = true;
    this.rectSpine.skeleton.setSkin(null);
    this.rectSpine.skeleton.setSkinByName('grab');
    this.rectSpine.skeleton.setSlotsToSetupPose();
    this.rectSpine.state.setAnimation(0, 'static', false);
    this.rectSpine.update(App.clock.deltaMS * 0.001);
  }

  handleHover() {
    if (this._isSpinning) return;
    this.spine.state.setAnimation(0, 'induction', false);
    this.spine.state.addAnimation(0, 'static', false);
  }

  handleOut() {}

  handleDown() {
    this.emit('pointerdown');
  }

  handleUp() {
    console.error('SPIN BTN GAMBLE');
    this.emit('pointerup');
  }

  handleUpOutside() {
    this.emit('pointerupoutside');
  }

  start() {
    this.currentState.start();
    this._isSpinning = true;
  }

  stop() {
    this.setStatic();
  }
}

class ActiveState extends State {
  constructor(btn) {
    super('ActiveState');
    this.btn = btn;
  }

  enter() {
    console.error('enter ActiveState');
    this.btn.setActive();
  }

  setStatic() {
    this.btn.spine.visible = true;
    this.btn.rectSpine.visible = false;
    this.btn.spine.skeleton.setSkinByName('spin');
    this.btn.spine.state.addAnimation(0, 'static', false);
  }

  handleInput() {
    if (this.btn.model.isAutoGameActivated()) {
      this.btn.setState(STATES.AUTO_SPINS);
    }
  }

  start() {
    console.error('start Active spin');
    this.btn.spine.state.setAnimation(0, 'start_spin', false);
    this.btn.spine.state.addAnimation(0, 'spin', true);
  }
}

class InactiveState extends State {
  constructor(btn) {
    super('InactiveState');
    this.btn = btn;
  }
}

class AutoSpinsState extends State {
  constructor(btn) {
    super('AutoSpinsState');
    this.btn = btn;
  }

  enter() {
    console.error('enter Autospins');
    this.btn.setAutoSpins();
  }

  setStatic() {
    this.btn.spine.visible = false;
    this.btn.rectSpine.visible = true;
    // this.spine.skeleton.setSkinByName('spin');
    // this.spine.state.addAnimation(0, 'static', false);
  }

  handleInput() {
    if (!this.btn.model.isAutoGameActivated()) {
      this.btn.setState(STATES.ACTIVE);
    }
  }

  start() {
    console.error('start Autospin');
    this.btn.rectSpine.skeleton.setSkin(null);
    this.btn.rectSpine.skeleton.setSkinByName('autospin');
    this.btn.rectSpine.skeleton.setSlotsToSetupPose();
    this.btn.rectSpine.state.setAnimation(0, 'static', false);
    this.btn.rectSpine.update(App.clock.deltaMS * 0.001);
    // this.btn.rectSpine.state.setAnimation(1, 'start_vfx', false);
  }
}

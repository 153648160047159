export const SETTINGS = {
  app: {
    debug: process.env.NODE_ENV === 'development',
    viewport: {
      width: 1024,
      height: 568,
    },
    renderer: {
      // preserveDrawingBuffer: true,
      hello: true,
      // antialias: true,
      width: window.innerWidth,
      height: window.innerHeight,
      resolution: window.devicePixelRatio,
      // roundPixels: true,
    },
    logger: {
      enabled: true,
      level: 'event',
      // level: 'error',
    },
  },
  init: {
    currency: {
      code: 'USD',
      symbol: '$',
      subunits: 100,
      exponent: 2,
    },
  },
};

import { App } from '../index';
import Clock from './Clock.js';

export default class DebugClock extends Clock {
  constructor() {
    super();
    this.isRun = false;
    this.scale = 1;

    window.addEventListener('keyup', (e) => {
      if (e.key === 'p') {
        this.isRun = !this.isRun;
        if (this.isRun) {
          this.elapsedTime = 0;
          this.deltaMS = 0;
          this.start = 0;
          this.fps = 0;
          this.run();
        }
      }

      if (e.key === 'w') {
        this.scale += 0.1;
      }

      if (e.key === 's') {
        if (this.scale < 0.15) {
          this.scale -= 0.01;
        } else {
          this.scale -= 0.1;
        }

        if (this.scale < 0.01) {
          this.scale = 0.01;
        }
      }

      App.logger.debug(
        `Clock key pressed. Key: ${e.key}, scale: ${this.scale.toFixed(2)}, runs: ${this.isRun}`,
      );
    });
  }

  run() {
    this.isRun = true;
    super.run();
  }

  tick(timestamp) {
    if (!this.isRun) return;

    if (this.start === 0) {
      this.start = timestamp;
      this.previousTimeStamp = timestamp;
    }
    this.elapsedTime = timestamp - this.start;
    this.deltaMS = (timestamp - this.previousTimeStamp) * this.scale;
    this.previousTimeStamp = timestamp;
    this.emit('tick');
    requestAnimationFrame((delta) => this.tick(delta));
  }
}

export const SYMBOL_TEXTURES_PREFIX = {
  STATIC: 'static_',
  BLUR: 'blur_',
};

export const SPACE_KEY = 32;

export const WINS_THRESHOLD = {
  BIG: 25,
  MEGA: 50,
  SUPER: 100,
};

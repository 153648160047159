import UIButtonView from './UIButtonView.js';

export default class SmallSettingsButtonView extends UIButtonView {
  constructor() {
    super('default');
  }

  get assetName() {
    return 'small_settings_button';
  }
}

import { App, EVENTS } from '@growe/lightcore';

/**
 * Class representing a collection of bets.
 */
export default class Bets {
  /**
   * Initializes the bet list.
   * @param {number[]} bets An array of bet objects.
   * @throws {Error} The bet list is incorrect. Please check init response.
   */
  static init() {
    const bets = App.repository.getCache('ui').get('bets');
    const currentBet = App.repository.getCache('ui').get('bet');
    if (!Array.isArray(bets) || bets.length === 0) {
      throw new Error(
        'The bet list is incorrect. Please check init response.',
      );
    }
    this._bets = bets.slice();
    if (this._bets.length > 25) this._bets.length = 25;
    this._currentIndex = this._bets.indexOf(currentBet);
    this._inited = true;
  }

  /**
   * Returns the bet list.
   * @returns {number[]} The bet list.
   */
  static list() {
    return this._bets.slice();
  }

  /**
   * Returns the current bet.
   * @returns {number} The current bet object.
   * @throws {Error} You have to init bets. Do Bets.init(betList).
   */
  static get current() {
    !this._inited && this._notInit();
    return this._bets[this._currentIndex];
  }

  /**
   * Setter for current bet.
   * @param {number} value Bet to be setByIndex as current.
   * @throws {Error} You have to init bets. Do Bets.init(betList).
   */
  static set current(value) {
    !this._inited && this._notInit();
    const index = this._bets.indexOf(value);
    if (index < 0) {
      this._betIsNotInListWarn(value);
      return;
    }
    this._currentIndex = index;
    App.events.emit('betUpdated');
  }

  /**
   * Sets the bet at specified index as current.
   * @param {Number} index Index of bet to setByIndex as current.
   * @throws {Error} You have to init bets. Do Bets.init(betList).
   */
  static setByIndex(index) {
    !this._inited && this._notInit();
    if (index < 0 || index > this._bets.length - 1) {
      this._betIsNotInListWarn('index: ' + index);
      return;
    }
    this._currentIndex = index;
    App.events.emit(EVENTS.UI.BET_UPDATED, { bet: this.current });
  }

  /**
   * Returns the current bet index.
   * @throws {Error} You have to init bets. Do Bets.init(betList).
   */
  static get currentBetIndex() {
    !this._inited && this._notInit();
    return this._currentIndex;
  }

  /**
   * Sets the maximum bet as current.
   * @throws {Error} You have to init bets. Do Bets.init(betList).
   */
  static setMax() {
    !this._inited && this._notInit();
    this._currentIndex = this._bets.length - 1;
    App.events.emit(EVENTS.UI.BET_UPDATED, { bet: this.current });
  }

  /**
   * Moves the current bet to the next bet if not at the last bet.
   * @return {Object} Current bet after move.
   * @throws {Error} You have to init bets. Do Bets.init(betList).
   */
  static next() {
    !this._inited && this._notInit();
    !this.isLastBet() && ++this._currentIndex;
    App.events.emit(EVENTS.UI.BET_UPDATED, { bet: this.current });
    return this.current;
  }

  /**
   * Moves the current bet to the previous bet if not at the first bet.
   * @return {number} Current bet after move.
   * @throws {Error} You have to init bets. Do Bets.init(betList).
   */
  static previous() {
    !this._inited && this._notInit();
    !this.isFirstBet() && --this._currentIndex;
    App.events.emit(EVENTS.UI.BET_UPDATED, { bet: this.current });
    return this.current;
  }

  /**
   * Checks if the current bet is the last bet in the list.
   * @returns {Boolean} True if current bet is the last bet, false otherwise.
   */
  static isLastBet() {
    return this._currentIndex >= this._bets.length - 1;
  }

  /**
   * Checks if the current bet is the first bet in the list.
   * @returns {Boolean} True if current bet is the first bet, false otherwise.
   */
  static isFirstBet() {
    return this._currentIndex === 0;
  }

  /**
   * Throws an error indicating the bets need to be initialized.
   * @throws {Error} You have to init bets. Do Bets.init(betList).
   */
  static _notInit() {
    throw new Error('You have to init bets. Do Bets.init(betList).');
  }

  /**
   * Logs the warning message.
   * @param {number | string} value Bet value that is not found in the list.
   */
  static _betIsNotInListWarn(value) {
    console.warn(`This bet (${value}) is not in the list.`);
  }
}

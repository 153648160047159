import { App, Component, EVENTS } from '@growe/lightcore';

import { delay } from '../../utils/utils';
import UiModel from './UiModel';
import UiView from './UiView';

export class Ui extends Component {
  subscribeToFsm() {
    this.fsm.subscribeToState('FEATURE_PREVIEW', this);
    this.fsm.subscribeToState('MAIN_GAME.START', this);
    this.fsm.subscribeToState('MAIN_GAME.IDLE', this);
    this.fsm.subscribeToState('MAIN_GAME.SPIN_START', this);
    this.fsm.subscribeToState('MAIN_GAME.SHOW_WIN', this);
    this.fsm.subscribeToState('MAIN_GAME.CAN_GAMBLE', this);
  }

  subscribeToEvents() {
    this.events.on(EVENTS.UI.STOP_AUTOSPINS, () =>
      this.handleStopAutospins(),
    );
    this.events.on(EVENTS.UI.START_AUTOSPINS, () =>
      this.handleStartAutospins(),
    );
    this.events.on(EVENTS.UI.SPIN_BUTTON_UP, () =>
      this.handleSpinButtonPressed(),
    );
  }

  addIdleListeners(onFinish) {
    const onSpinPressed = () => {
      onFinish(this.name, {
        isSpin: true,
        isGamble: false,
      });
      removeListeners();
    };

    const onGamblePressed = () => {
      onFinish(this.name, {
        isSpin: false,
        isGamble: true,
      });
      removeListeners();
    };

    const eventsMap = {
      [EVENTS.UI.SPIN_BUTTON_UP]: onSpinPressed,
      [EVENTS.UI.START_AUTOSPINS]: onSpinPressed,
      [EVENTS.GAMBLE.BUTTON_PRESSED]: onGamblePressed,
    };

    const removeListeners = () => {
      Object.entries(eventsMap).forEach(([event, callback]) => {
        App.events.removeListener(event, callback);
      });
    };

    Object.entries(eventsMap).forEach(([event, callback]) => {
      App.events.once(event, callback);
    });
  }

  addCanGambleListeners(onFinish) {
    const onSpinPressed = () => {
      App.logger.error('SPIN BUTTON EVENT');
      onFinish(this.name, {
        isSpin: true,
        isGamble: false,
      });
      App.events.removeListener(
        EVENTS.GAMBLE.BUTTON_PRESSED,
        onGamblePressed,
      );
    };

    const onGamblePressed = () => {
      App.logger.error('GAMBLE BUTTON EVENT');
      onFinish(this.name, {
        isSpin: false,
        isGamble: true,
      });
      App.events.removeListener(
        EVENTS.UI.SPIN_BUTTON_UP,
        onSpinPressed,
      );
    };

    App.events.once(EVENTS.UI.SPIN_BUTTON_UP, onSpinPressed);
    App.events.once(EVENTS.UI.START_AUTOSPINS, onSpinPressed);
    App.events.once(EVENTS.GAMBLE.BUTTON_PRESSED, onGamblePressed);
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'FEATURE_PREVIEW') {
      await this.create();
      stateEvent.onFinish(this.name);
    }

    if (stateEvent.next === 'MAIN_GAME.START') {
      await this.show();
      stateEvent.onFinish(this.name);
    }

    if (stateEvent.next === 'MAIN_GAME.IDLE') {
      await this.setMainGameIdle();
      if (this.model.currentAutoSpin) {
        stateEvent.onFinish(this.name, {
          isSpin: true,
          isGamble: false,
        });
        return;
      }
      this.addIdleListeners(stateEvent.onFinish);
    }

    if (stateEvent.next === 'MAIN_GAME.SPIN_START') {
      await this.setMainGameSpinStart();
      stateEvent.onFinish(this.name);
    }

    if (stateEvent.next === 'MAIN_GAME.SHOW_WIN') {
      await this.setMainGameShowWin();
      stateEvent.onFinish(this.name);
    }

    if (stateEvent.next === 'MAIN_GAME.CAN_GAMBLE') {
      if (this.model.currentAutoSpin) {
        stateEvent.onFinish(this.name, {
          isSpin: true,
          isGamble: false,
        });
        return;
      }
      await this.setMainGameCanGamble();
      this.addCanGambleListeners(stateEvent.onFinish);
    }

    // if (stateEvent.next === 'MAIN_GAME.SPIN_START') {
    //   // await this.setMainGameIdle();
    //   App.events.once('spinBtnUp', () => stateEvent.onFinish(this.name));
    // }
    //
    // if (stateEvent.next === 'MAIN_GAME.SPIN_STOP') {
    //   // await this.setMainGameIdle();
    //   App.events.once('spinBtnUp', () => stateEvent.onFinish(this.name));
    // }
  }

  async create() {
    this.model = new UiModel();
    await this.model.init();
    this.view = new UiView(this.model);
    App.layers.getLayer('ui').addChild(this.view);
    this.view.resize();
    this.view.setIdle();
    this.view.visible = false;
  }

  async show() {
    this.resize();
    this.view.visible = true;
  }

  async setMainGameIdle() {
    this.model.balance = App.repository.getCache('ui').get('balance');
    this.view.setIdle();
  }

  async setMainGameSpinStart() {
    this.model.balance -= this.model.bet;
    if (this.model.currentAutoSpin) {
      this.model.startAutoSpin();
    }
    this.view.setSpinStart();
  }

  async setMainGameShowWin() {
    this.model.lastWin = App.repository
      .getCache('spinCurrent')
      .get('linesAward');
    this.model.balance = App.repository.getCache('ui').get('balance');
    this.view.setSpinStop();
  }

  async setMainGameCanGamble() {
    // this.model.balance -= this.model.bet;
    this.view.setCanGamble();
  }

  handleStopAutospins() {
    this.view.setMainGameIdle();
    this.model.setAutoSpins(0);
  }
  handleStartAutospins() {
    this.view.setAutoSpinsMode();
  }
  handleSpinButtonPressed() {
    this.model.setAutoSpins(0);
  }

  update(deltaMS) {
    this.view && this.view.visible && this.view.update(deltaMS);
  }

  resize() {
    this.view && this.view.resize();
  }
}

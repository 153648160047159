import { App, Component, EVENTS } from '@growe/lightcore';

import { delay } from '../../utils/utils';
import ReelsetModel from './model/ReelsetModel';
import ReelsetView from './view/ReelsetView';

export class Reelset extends Component {
  subscribeToFsm() {
    this.fsm.subscribeToState('MAIN_GAME.START', this);
    this.fsm.subscribeToState('MAIN_GAME.IDLE', this);
    this.fsm.subscribeToState('MAIN_GAME.SPIN_START', this);
    this.fsm.subscribeToState('MAIN_GAME.SPIN_STOP', this);
    this.fsm.subscribeToState('MAIN_GAME.SHOW_WIN', this);
  }

  subscribeToEvents() {
    this.events.on(EVENTS.UI.TURBO_BUTTON_DOWN, (event) => {
      this.view.setTurboMode(event.turboMode);
    });
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'MAIN_GAME.START') {
      await this.show();
    }

    if (stateEvent.next === 'MAIN_GAME.IDLE') {
      await this.idle();
    }

    if (stateEvent.next === 'MAIN_GAME.SPIN_START') {
      await this.spinStart();
    }

    if (stateEvent.next === 'MAIN_GAME.SPIN_STOP') {
      await this.spinStop();
    }

    if (stateEvent.next === 'MAIN_GAME.SHOW_WIN') {
      await this.showWin();
    }

    stateEvent.onFinish(this.name);
  }

  async show() {
    this.model = new ReelsetModel();
    this.view = new ReelsetView(this.model);
    App.layers.getLayer('reelset').addChild(this.view);
    this.view.resize();
  }

  async spinStart() {
    this.model.clearSpinResult();
    this.view.stopToggleWinLines();
    await this.view.hideWins();
    await this.view.spin();
  }

  async idle() {
    this.view.idle();
  }

  async spinStop() {
    const spinResult = this.model.getSpinResult();
    await this.view.stop(spinResult);
    // await delay(500);
  }

  async showWin() {
    await Promise.race([this.view.showWins(), delay(1000)]);
    const linesAward = App.repository
      .getCache('spinCurrent')
      .get('linesAward');
    const awardMultiplier =
      linesAward / App.repository.getCache('ui').get('bet');
    // todo: magic number bigwin
    if (awardMultiplier > 50) {
      console.error({ awardMultiplier });
      await this.view.showWinPopup(awardMultiplier, linesAward);
    }

    if (!App.repository.getCache('ui').get('isAutoSpin')) {
      this.view.startToggleWinLines();
    }
  }

  update(deltaMS) {
    this.view && this.view.visible && this.view.update(deltaMS);
  }
}

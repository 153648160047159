export const EVENTS = {
  CORE: {
    FSM_STARTED: 'CORE.FSM_STARTED',
  },
  UI: {
    SPIN_BUTTON_UP: 'UI.SPIN_BUTTON_UP',
    SPIN_BUTTON_DOWN: 'UI.SPIN_BUTTON_DOWN',
    TURBO_BUTTON_DOWN: 'UI.TURBO_BUTTON_DOWN',
    BET_UPDATED: 'UI.BET_UPDATED',
    START_AUTOSPINS: 'UI.START_AUTOSPINS',
    STOP_AUTOSPINS: 'UI.STOP_AUTOSPINS',
  },
  STATE: {
    CHANGE: 'STATE.CHANGE',
  },
  SPIN: {
    START: 'SPIN.START',
    STOP: 'SPIN.STOP',
  },
  GAMBLE: {
    BUTTON_PRESSED: 'GAMBLE.BUTTON_PRESSED',
    FINISHED: 'GAMBLE.FINISHED',
  },
  OVERLAY: {
    PRESSED: 'OVERLAY.PRESSED',
    SHOWN: 'OVERLAY.SHOWN',
    HIDDEN: 'OVERLAY.HIDDEN',
  },
  LAYOUT: {
    TOGGLE_GRID: 'LAYOUT.TOGGLE_GRID',
  },
};

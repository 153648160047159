import {
  Assets,
  Container,
  NineSlicePlane,
  Sprite,
  Text,
  Texture,
} from '@growe/lightcore';

export default class CompactIndicator extends Container {
  constructor(iconTexture) {
    super();
    const bg = new NineSlicePlane(
      Assets.get('compact-indicator-bg'),
      8,
      8,
      8,
      8,
    );
    this.addChild(bg);
    const icon = new Sprite(iconTexture || Texture.WHITE);
    icon.anchor.set(0, 0.5);
    this.addChild(icon);
    const text = new Text(0, {
      fill: 0xffffff,
      fontFamily: 'Impact',
      fontSize: 16,
    });
    text.anchor.set(0.5, 0.5);
    this.addChild(text);

    this.icon = icon;
    this.bg = bg;
    this.text = text;

    this.resize();
  }

  set value(v) {
    this.text.text = v;
    this.text.scale.set(1);
    this.text.scale.set(
      Math.min(1, (this.bg.width - 35) / this.text.width),
    );
  }

  resize(width = 300, height = 45) {
    this.bg.width = width;
    this.bg.height = height;
    this.icon.position.x = 5;
    this.icon.position.y = height / 2;
    this.text.x = this.bg.width * 0.5 + 5;
    this.text.y = height / 2;
  }
}

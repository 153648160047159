import { App, Rectangle, Sprite, Texture } from '@growe/lightcore';

export default class OverlayView {
  constructor() {
    this.overlay = new Sprite(Texture.WHITE);
    this.overlay.tint = 0x000000;
    this.overlay.alpha = 0.5;
    this.overlay.eventMode = 'static';
    App.layers.getLayer('overlay').addChild(this.overlay);
    // App.layers.getLayer('overlay').interactiveChildren = true;
    this.resize();
    this.hide();
  }

  show({ onPress }) {
    this.overlay.visible = true;
    if (onPress) {
      this.overlay.on('pointerup', onPress);
    }
  }

  hide() {
    this.overlay.visible = false;
  }

  update() {}

  resize() {
    this.overlay.width = App.device.width;
    this.overlay.height = App.device.height;
    this.overlay.hitArea = new Rectangle(
      0,
      0,
      this.overlay.width,
      this.overlay.height,
    );
  }
}

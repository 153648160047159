import {
  App,
  Assets,
  Cache,
  Container,
  Rectangle,
  Texture,
} from '@growe/lightcore';
import { MotionBlurFilter } from '@pixi/filter-motion-blur';
import Promise from 'bluebird';
import { Spine } from 'pixi-spine';

import { SYMBOL_TEXTURES_PREFIX } from '../../config/constants';
import Activity from '../../game/activities/Activity.js';
import Currency from '../../game/models/Currency';
import Lines from '../../game/models/Lines';
import Bets from '../ui/model/Bets';
import Paytable from '../ui/view/Paytable';

export default class InitService {
  constructor(view) {
    this.view = view;
  }

  async initTextures() {
    const bundleNames = [
      'fonts',
      'configs',
      'featurePreview',
      'gameplay',
      'win_popups',
      'ui',
      'symbols',
    ];

    Activity.add(this);

    const progresses = new Array(bundleNames.length).fill(0);

    // todo: separate what is needed for bonus, gamble etc
    await Promise.map(
      bundleNames,
      (name, i) => {
        return Assets.loadBundle(name, (progress) => {
          progresses[i] = progress;
          this.view.progress =
            progresses.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue,
              0,
            ) / bundleNames.length;
        });
      },
      { concurrency: 2 },
    );

    this.createSymbolsTextures();
  }

  createSymbolsTextures() {
    const width = 250;
    const height = 250;
    const symbols = Object.entries(Assets.get('symbols'));
    console.log({ symbols });
    if (!symbols) {
      throw new Error("the symbols config file isn't loaded");
    }
    const renderer = App.renderer;
    const container = new Container();
    const blur = new MotionBlurFilter([0, -50], 15, 7);

    const columns = Math.floor(Math.sqrt(symbols.length));
    const rows = Math.ceil(symbols.length / columns);
    const region = new Rectangle(
      0,
      0,
      width * columns,
      height * rows,
    );
    let staticAtlas, blurAtlas;
    for (let i = 0; i < symbols.length; i++) {
      const col = Math.floor(i % columns);
      const row = Math.floor(i / columns);
      const spine = new Spine(
        Assets.get(symbols[i][1].spine).spineData,
      );
      spine.autoUpdate = false;
      spine.skeleton.setSkinByName(symbols[i][1].skin);
      spine.state.setAnimation(0, 'idle', false);
      // spine.scale.setByIndex(symbols[i][1].scale || 1)
      spine.update(0);
      spine.position.set(width * (col + 0.5), height * (row + 0.5));
      container.addChild(spine);
    }

    staticAtlas = renderer.generateTexture(container, {
      resolution: renderer.resolution,
      region,
    });

    Cache.set(SYMBOL_TEXTURES_PREFIX.STATIC + 'symbols', staticAtlas);

    for (let i = 0; i < symbols.length; i++) {
      const col = Math.floor(i % columns);
      const row = Math.floor(i / columns);
      const texture = new Texture(staticAtlas);
      texture.frame = new Rectangle(
        width * col,
        height * row,
        width,
        height,
      );
      Cache.set(
        SYMBOL_TEXTURES_PREFIX.STATIC + symbols[i][0],
        texture,
      );
    }

    container.children.forEach((ch) => (ch.filters = [blur]));
    blurAtlas = renderer.generateTexture(container, {
      resolution: renderer.resolution,
      region,
    });
    Cache.set(SYMBOL_TEXTURES_PREFIX.BLUR + 'symbols', blurAtlas);
    for (let i = 0; i < symbols.length; i++) {
      const col = Math.floor(i % columns);
      const row = Math.floor(i / columns);
      const texture = new Texture(blurAtlas);
      texture.frame = new Rectangle(
        width * col,
        height * row,
        width,
        height,
      );
      Cache.set(SYMBOL_TEXTURES_PREFIX.BLUR + symbols[i][0], texture);
    }

    container.destroy({
      children: true,
    });
    blur.destroy();
  }

  // todo: move logic to network
  // todo: here just await that init was handled
  async getInitData() {
    // todo make data can be awaited
    const rawData = App.repository.getCache('init').get('raw');

    const { session_token, wager_levels, default_wager, balance } =
      rawData;

    App.network.sessionToken = session_token;
    const initSettings = App.settings.getSettings('game').get('init');

    App.repository
      .getCache('init')
      .set('sessionToken', session_token);

    // todo: should be from init response
    App.repository.getCache('init').set('lines', initSettings.lines);
    App.repository
      .getCache('init')
      .set('currency', initSettings.currency);
    App.repository.getCache('ui').set('bets', wager_levels);
    App.repository.getCache('ui').set('balance', balance);
    App.repository.getCache('ui').set('bet', default_wager);
    Lines.init();
  }

  initEntities() {
    Currency.init();
    Paytable.init();
    Bets.init();
    Lines.init();
  }
}

import { Assets, Container } from '@growe/lightcore';
import { App } from '@growe/lightcore';

import Paytable from '../../../components/ui/view/Paytable.js';
import { SETTINGS } from '../../../config/settings.js';
import '../../dom/RulesWindow.js';
import HTMLContainer from '../HTMLContainer.js';

export default class Rules extends Container {
  constructor() {
    super('rules_slider');
    this.autoUpdate = false;

    this.htmlContainer = new HTMLContainer(
      document.body.querySelector('.content'),
      'rules',
      {
        backgroundColor: 'transparent',
      },
    );
    this.htmlContainer.width = 100;
    this.htmlContainer.height = 300;
    this.htmlContainer.x = 300;
    this.htmlContainer.y = 300;
    this.htmlContainer.anchor.set(0.5, 0.5);
    let content = Assets.get('rules_content');
    console.log(typeof content);
    console.log(this.findTableStrings(content));
    this.findTableStrings(content).forEach((tablePlaceholder) => {
      let matches = tablePlaceholder.match(/\{table-(.)\}/);
      let key = matches ? matches[1] : null;
      console.log(key);
      const string = Paytable.getPaytableText(key, 100)
        .split('\n')
        .map((s) => `<p>${s}</p>`)
        .reverse()
        .join('\n');
      content = content.replace(tablePlaceholder, string);
    });

    this.htmlContainer.addContent(content);
    this.htmlContainer.element
      .querySelector('rules-window')
      .addEventListener('close', (e) => {
        this.emit('close');
      });
  }

  findTableStrings(text) {
    return text.match(/\{table-[A-Z]\}/g);
  }

  resize() {
    this.position.set(
      SETTINGS.app.viewport.width * 0.5,
      SETTINGS.app.viewport.height * 0.5,
    );
    this.htmlContainer.x = SETTINGS.app.viewport.width * 0.5;
    this.htmlContainer.y = SETTINGS.app.viewport.height * 0.5;
    this.htmlContainer.scale.set(App.viewport.heightRatio);
    this.htmlContainer.width =
      SETTINGS.app.viewport.width * App.viewport.cropWidthRatio;
    this.htmlContainer.height = SETTINGS.app.viewport.height;
  }

  destroy(_options) {
    this.htmlContainer.destroy();
    super.destroy(_options);
  }

  update(dt) {
    this.htmlContainer.alpha = this.alpha;
  }
}

import { App } from '@growe/lightcore';
import { ObservablePoint } from '@pixi/core';

import { SETTINGS } from '../../config/settings.js';

export default class HTMLContainer {
  constructor(parent, className = undefined, styles = {}) {
    this.element = document.createElement('div');
    this.element.style.position = 'fixed';
    this.element.style.display = 'block';
    Object.assign(this.element.style, styles);
    this.anchor = new ObservablePoint(
      this.updateTransform,
      this,
      0,
      0,
    );
    this.scale = new ObservablePoint(
      this.updateTransform,
      this,
      1,
      1,
    );
    parent.appendChild(this.element);
    if (className) {
      this.element.className = className;
    }
  }

  updateTransform() {
    this.element.style.transformOrigin = `${this.anchor.x * 100}% ${this.anchor.y * 100}%`;
    let transform = `translate(-${this.anchor.x * 100}%, -${this.anchor.y * 100}%) scale(${this.scale.x}, ${this.scale.y}`;
    this.element.style.transform = transform;
  }

  set x(value) {
    const relatedX =
      (window.innerWidth -
        SETTINGS.app.renderer.width * App.viewport.heightRatio) *
      0.5;
    this.element.style.left =
      relatedX + value * App.viewport.heightRatio + 'px';
  }

  get x() {
    this.element.style.left;
  }

  set y(value) {
    const relatedY =
      (window.innerHeight -
        SETTINGS.app.renderer.height * App.viewport.heightRatio) *
      0.5;
    this.element.style.top =
      relatedY + value * App.viewport.heightRatio + 'px';
  }

  get y() {
    this.element.style.top;
  }

  set width(value) {
    this.element.style.width = value + 'px';
  }

  get width() {
    return this.element.style.width;
  }

  set height(value) {
    this.element.style.height = value + 'px';
  }

  get height() {
    return this.element.style.height;
  }

  set alpha(value) {
    this.element.style.opacity = value;
  }

  set visible(value) {
    if (value) {
      this.element.style.display = 'block';
    } else {
      this.element.style.display = 'none';
    }
  }

  addContent(content) {
    this.element.innerHTML = content;
  }

  destroy() {
    this.element.remove();
  }
}

import { App } from '@growe/lightcore';

/**
 * The `State` class represents a state in a state machine architecture.
 * It contains the generic structure of a state including its lifecycle hooks: enter, handleInput, update, and leave.
 * Each state is identified with a unique name.
 * @export
 * @class State
 */
export default class State {
  /**
   * Creates a new instance of the `State` class.
   * @param {string} name - A string that uniquely represents the state.
   */
  constructor(name) {
    /**
     * A variable to store the name of the state.
     * @type {string}
     */
    this.name = name;
  }

  /**
   * Method called when the state is entered. It is intended to setByIndex up anything that the state needs.
   * To be overridden by subclasses with specific logic.
   *
   * Logs the message 'Enter <state_name>'.
   */
  enter() {
    App.logger.log('Enter ' + this.name);
  }

  /**
   * A hook for handling input when the state is active.
   * To be overridden by subclasses with specific logic.
   */
  handleInput() {}

  /**
   * A hook for updating state in every game update function call or game loop when the state is active.
   * To be overridden by subclasses with specific logic.
   */
  update() {}

  /**
   * Method called when the state is exited. It is intended to perform cleanup or reset activities.
   * To be overridden in subclasses with specific logic.
   */
  leave() {}
}

import { App, Component, EVENTS } from '@growe/lightcore';

import GambleView from './view/GambleView';

export class Gamble extends Component {
  subscribeToFsm() {
    this.fsm.subscribeToState('FEATURE_PREVIEW', this);
    this.fsm.subscribeToState('MAIN_GAME.IDLE', this);
    this.fsm.subscribeToState('MAIN_GAME.CAN_GAMBLE', this);
    this.fsm.subscribeToState('GAMBLE_GAME.START', this);
    this.fsm.subscribeToState('GAMBLE_GAME.IDLE', this);
    this.fsm.subscribeToState('GAMBLE_GAME.FINISH', this);
    this.fsm.subscribeToState('MAIN_GAME.START', this);
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'FEATURE_PREVIEW') {
      this.init();
    }

    if (stateEvent.next === 'MAIN_GAME.CAN_GAMBLE') {
      if (!App.repository.getCache('ui').get('isAutospins')) {
        await this.showButton();
      }
    }

    if (stateEvent.next === 'MAIN_GAME.IDLE') {
      await this.hideButton();
    }

    if (stateEvent.next === 'GAMBLE_GAME.START') {
      await this.showPopup();
    }

    if (stateEvent.next === 'GAMBLE_GAME.IDLE') {
      await this.waitForChoice();
    }

    if (stateEvent.next === 'GAMBLE_GAME.FINISH') {
      await this.hidePopup();
    }

    stateEvent.onFinish(this.name);
  }

  init() {
    this.view = new GambleView();
  }

  async showButton() {
    const isCanGamble = App.repository
      .getCache('spinCurrent')
      .get('isCanGamble');
    if (!isCanGamble) {
      return;
    }
    this.view.showButton();
    this.view.resize();
  }

  async hideButton() {
    this.view && this.view.hideButton();
  }

  async showPopup() {
    this.view.hideButton();
    this.view.showPopup();
  }

  waitForChoice() {
    return new Promise((resolve) => {
      this.events.once(EVENTS.OVERLAY.PRESSED, () => {
        this.view.closePopup();
        resolve(true);
      });

      this.events.once(EVENTS.UI.SPIN_BUTTON_UP, () => {
        this.view.closePopup();
        resolve(true);
      });
    });
  }

  async hidePopup() {
    await this.view.hidePopup();
    this.events.emit(EVENTS.GAMBLE.FINISHED);
  }
}

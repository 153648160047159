import { ExtensionType } from '@growe/lightcore';
import { App } from '@growe/lightcore';
import { extensions } from '@pixi/core';
import { EventSystem } from '@pixi/events';

/**
 * A class representing the Global Events System.
 * System emits pointer events through App.events
 * @extends EventSystem
 */
export default class GlobalPointerEvents extends EventSystem {
  static extension = {
    name: 'globalEvents',
    type: [
      ExtensionType.RendererSystem,
      ExtensionType.CanvasRendererSystem,
    ],
  };

  /**
   * Handles the pointer down event.
   *
   * @param {Event} nativeEvent - The native event object.
   */
  onPointerDown(nativeEvent) {
    if (!this.features.click) return;

    const events = this.normalizeToPointerData(nativeEvent);

    /*
     * No need to prevent default on natural pointer events, as there are no side effects
     * Normalized events, however, may have the double mousedown/touchstart issue on the native android browser,
     * so still need to be prevented.
     */

    // Guaranteed that there will be at least one event in events, and all events must have the same pointer type

    if (this.autoPreventDefault && events[0].isNormalized) {
      const cancelable =
        nativeEvent.cancelable || !('cancelable' in nativeEvent);

      if (cancelable) {
        nativeEvent.preventDefault();
      }
    }

    for (let i = 0, j = events.length; i < j; i++) {
      const nativeEvent = events[i];
      const federatedEvent = this.bootstrapEvent(
        this.rootPointerEvent,
        nativeEvent,
      );

      App.events.emit('globalpointerdown', federatedEvent);
    }
  }

  onPointerMove(nativeEvent) {
    this.rootBoundary.rootTarget = this.renderer.lastObjectRendered;

    const normalizedEvents = this.normalizeToPointerData(nativeEvent);

    for (let i = 0, j = normalizedEvents.length; i < j; i++) {
      const event = this.bootstrapEvent(
        this.rootPointerEvent,
        normalizedEvents[i],
      );

      App.events.emit('globalpointermove', event);
    }
  }

  onPointerUp(nativeEvent) {
    if (!this.features.click) return;
    this.rootBoundary.rootTarget = this.renderer.lastObjectRendered;

    let target = nativeEvent.target;

    // if in shadow DOM use composedPath to access target
    if (
      nativeEvent.composedPath &&
      nativeEvent.composedPath().length > 0
    ) {
      target = nativeEvent.composedPath()[0];
    }

    const outside = target !== this.domElement ? 'outside' : '';
    const normalizedEvents = this.normalizeToPointerData(nativeEvent);

    for (let i = 0, j = normalizedEvents.length; i < j; i++) {
      const event = this.bootstrapEvent(
        this.rootPointerEvent,
        normalizedEvents[i],
      );

      event.type += outside;

      App.events.emit('globalpointerup', event);
    }
  }

  onPointerOverOut() {}

  onWheel() {}

  init(options) {
    super.init(options);
  }
}

extensions.add(GlobalPointerEvents);

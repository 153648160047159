import { EVENTS, App, SpineScene, TextAttachment } from '@growe/lightcore';

import Currency from '../../../../game/models/Currency';
import {
  delay,
  waitForEventOnce,
  waitForFirstEmittedEvent,
} from '../../../../utils/utils';

export default class WinPopup extends SpineScene {
  constructor(assetName) {
    super(assetName);
    // todo: magic numbers to config
    this.autoClose = true;
    this.autoCloseDelay = 6000;
    this.valueAttachment = new TextAttachment({
      width: 850,
      height: 300,
    });
    this.valueAttachment.boundaries = false;
    this.valueAttachment.style = this.getStyle();
    this.valueAttachment.center();
    this.valueAttachment.autoScaleToFit = true;
    this.valueAttachment.attachTo(this, 'placeholder');
    this.valueAttachment.attachTo(this, 'placeholder_additive');
    this.state.addListener({
      event: (_, event) => {
        this.emit(event.data.name);
      },
    });
    this.state.addAnimation(0, 'in', false, 0);
    this.update(0);
    this.eventMode = 'static';
    this.autoUpdate = false;
  }

  set value(value) {
    this._desiredValue = value;
    this._value = 0;
  }

  async show() {
    this.state.setAnimation(0, 'in', false);
    this.state.addAnimation(0, 'idle', true, 0);
    this.startCounter();
    const buttonsPromise = await waitForFirstEmittedEvent([
      {
        target: this,
        event: 'pointerup',
      },
      {
        target: App.events,
        event: EVENTS.UI.SPIN_BUTTON_UP,
      }
    ]);
    if (!this.autoClose) {
      await buttonsPromise;
    } else {
      await Promise.race([
        buttonsPromise,
        delay(this.autoCloseDelay),
      ]);
    }
  }

  startCounter() {
    const parts = 80;
    const part = this._desiredValue / parts;
    const interval = setInterval(() => {
      this._value += part;
      if (this._value > this._desiredValue) {
        this._value = this._desiredValue;
      }
      this.valueAttachment.text = Currency.getFormattedValue(
        Math.floor(this._value),
      );
      this.valueAttachment.update();
      if (this._value === this._desiredValue) {
        clearInterval(interval);
      }
    }, 40);
  }

  hide() {
    this.state.addAnimation(0, 'out', false);
  }

  destroy(options) {
    this.valueAttachment.detach();
    super.destroy(options);
    this.valueAttachment.destroy();
  }

  resize() {
    const reelsTop = App.layout.getGridLineY('reels_top');
    const reelsBottom = App.layout.getGridLineY('reels_bottom');
    const availableHeight = (reelsBottom - reelsTop) * 0.92;
    const reelsCenterY = (reelsTop + reelsBottom) / 2 + 4;
    this.position.set(App.device.width * 0.5, reelsCenterY);

    const scaleFactor = App.device.orientation === 'portrait' ? 0.35 : 0.5;
    this.scale.set(scaleFactor);
  }

  getStyle() {
    return {};
  }
}

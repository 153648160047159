import { Spine } from 'pixi-spine';

import { Assets } from '../../index';

export class SpineScene extends Spine {
  constructor(assetName) {
    super(Assets.get(assetName).spineData);
    this.name = assetName;
  }

  // get resizeSkinPrefix() {
  //   return '';
  // }
  //
  // get resizeSkin() {
  //   return (
  //     this.resizeSkinPrefix + Math.floor(App.viewport.cropWidthRatio * 10) * 10
  //   );
  // }
  //
  // resize() {
  //   const x = App.viewport.width * 0.5;
  //   const y = App.viewport.height * 0.5;
  //   const resizeSkin = this.resizeSkin;
  //   App.logger.debug(
  //     `Resize ${this.name}, skin: ${resizeSkin}, coords: (${x}, ${y})`,
  //   );
  //   this.position.set(x, y);
  //   this.skeleton.setSkinByName(resizeSkin);
  // }
}

import { Assets, Circle, Container } from '@growe/lightcore';
import { App, EVENTS } from '@growe/lightcore';
import { Spine } from 'pixi-spine';

export default class GambleButton extends Container {
  constructor(model) {
    super();
    this.model = model;
    this.spine = new Spine(Assets.get('spin_button').spineData);
    this.spine.skeleton.setSkinByName('gamble');
    this.spine.autoUpdate = false;
    this.addChild(this.spine);

    this.spine.eventMode = 'static';
    this.spine.cursor = 'pointer';
    this.spine.on('pointerover', this.handleHover, this);
    // this.spine.on('pointerout', this.handleOut, this);
    // this.spine.on('pointerdown', this.handleDown, this);
    this.spine.on('pointerup', this.handleUp, this);
    // this.spine.on('pointerupoutside', this.handleUpOutside, this);

    this.spine.hitArea = new Circle(0, 0, 60);
    this.visible = false;
    this.enabled = false;
    // this.enable();
    this.resize();
  }

  resize() {
    this.scale.set(1);
    if (App.device.orientation === 'landscape') {
      const spinTop = App.layout.getGridLineY('ui_top');
      const spinBottom = App.layout.getGridLineY('ui_bottom');
      const scale = (spinBottom - spinTop) / 224;
      this.scale.set(scale);
      this.position.y =
        (App.layout.getGridLineY('reels_bottom') +
          App.layout.getGridLineY('reels_3/4')) /
        2;
      this.position.x = App.layout.getGridLineX('x2_center');
    }

    if (App.device.orientation === 'portrait') {
      const spinTop = App.layout.getGridLineY('ui_spin_top');
      const spinBottom = App.layout.getGridLineY('ui_bottom');
      const scale = (0.8 * (spinBottom - spinTop)) / 224;
      this.scale.set(scale);
      this.position.y =
        (App.layout.getGridLineY('reels_bottom') +
          App.layout.getGridLineY('ui_top')) /
        2;
      this.position.x = (8 * App.device.width) / 9;
    }
  }

  update(dt) {
    this.spine.update(dt);
  }

  enable() {
    this.spine.state.setAnimation(0, 'in', false);
    this.resize();
    this.update();
    this.visible = true;
    this.enabled = true;
  }

  disable() {
    if (!this.enabled) {
      return;
    }
    this.spine.state.setAnimation(0, 'out', false);
    this.x = -500;
    this.enabled = false;
  }

  handleHover() {
    if (this.enabled) {
      this.spine.state.setAnimation(0, 'induction', false);
      this.spine.state.addAnimation(0, 'static', false, 0);
    }
  }

  handleUp() {
    if (this.enabled) {
      App.events.emit(EVENTS.GAMBLE.BUTTON_PRESSED);
    }
  }
}

import { Container, Sprite, Texture } from '@growe/lightcore';
import { App } from '@growe/lightcore';

import { SETTINGS } from '../../config/settings.js';
import InputView from './view/InputView.js';
import PortraitIndicators from './view/PortraitIndicators.js';
import SettingsWindow from './view/SettingsWindow.js';
import AutoSpinsSettingsWindow from './view/autospins/AutoSpinsSettingsWindow.js';
import AutoSpinsWindow from './view/autospins/AutoSpinsWindow';
import MinusButton from './view/input/MinusButton';
import PaytableButton from './view/input/PaytableButton';
import PlusButton from './view/input/PlusButton';
import SoundButton from './view/input/SoundButton';
import SpinButton from './view/input/SpinButton';
import TurboButton from './view/input/TurboButton';

export default class UiView extends Container {
  constructor(model) {
    super();
    this.model = model;

    this._create();
    this.init();
  }

  update() {
    this.input.update();
    this.portraitIndicators.update();
    this.autospinsWindow.update();
    this.settingsWindow.update();
    this.spinBtn && this.spinBtn.update();
  }

  resize() {
    this.input.resize();
    this.settingsWindow.resize();
    this.autospinsWindow.resize();
    this.portraitIndicators.resize();
    this.portraitBar.y =
      App.device.orientation === 'portrait'
        ? App.device.height - 30
        : App.device.height;
  }

  setIdle() {
    this.settingsWindow.visible = false;
    this.autospinsWindow.visible = false;
    this.input.interactiveChildren = true;
    this.input.spinBtn.setStatic();
    this.overlay.visible = false;
    this.portraitBar.visible = false;
  }

  setSpinStart() {
    this.settingsWindow.visible = false;
    this.autospinsWindow.visible = false;
    this.input.interactiveChildren = true;
    this.input.spinBtn.start();
    this.overlay.visible = false;
    this.portraitBar.visible = false;
  }

  setSpinStop() {
    this.settingsWindow.visible = false;
    this.autospinsWindow.visible = false;
    this.input.interactiveChildren = true;
    this.input.spinBtn.stop();
    this.overlay.visible = false;
    this.portraitBar.visible = false;
  }

  setCanGamble() {
    this.settingsWindow.visible = false;
    this.autospinsWindow.visible = false;
    this.input.interactiveChildren = true;
    this.input.spinBtn.setGamble();
    this.overlay.visible = false;
    this.portraitBar.visible = false;
  }

  setAutoSpinsMode() {
    this.input.spinBtn.setAutoSpins();
  }

  showAutoSpinsSettings() {
    this.input.interactiveChildren = false;
    this.settingsWindow.visible = false;
    this.autospinsWindow.visible = true;
    this.overlay.visible = true;
    // this.portraitIndicators.y = 120;
    this.portraitBar.visible = true;
    this.portraitBar.tint = 0x2a3443;
  }

  showSettings() {
    this.input.interactiveChildren = false;
    this.settingsWindow.visible = true;
    this.autospinsWindow.visible = false;
    this.overlay.visible = true;
    this.portraitIndicators.y = 120;
    this.portraitBar.visible = true;
    this.portraitBar.tint = 0x19222f;
  }

  _create() {
    this.input = new InputView(this.model);

    this.overlay = new Sprite(Texture.WHITE);
    this.overlay.width = SETTINGS.app.viewport.width;
    this.overlay.height = SETTINGS.app.viewport.height;
    this.overlay.tint = 0x000000;
    this.overlay.alpha = 0.5;
    this.overlay.eventMode = 'static';

    this.portraitBar = new Sprite(Texture.WHITE);
    this.portraitBar.width = SETTINGS.app.viewport.width;
    this.portraitBar.height = 30;
    this.portraitBar.tint = 0x19222f;
    this.portraitBar.y = SETTINGS.app.viewport.height - 25;

    this.autospinsWindow = new AutoSpinsSettingsWindow(this.model);
    this.settingsWindow = new SettingsWindow(this.model);
    this.portraitIndicators = new PortraitIndicators(this.model);

    this.addChild(
      this.input,
      this.overlay,
      this.portraitBar,
      this.autospinsWindow,
      // this.settingsWindow,
      this.portraitIndicators,
    );

    this.input.autoBtn.on('pointerup', () => {
      this.showAutoSpinsSettings();
    });
    this.input.smallSettingsButton.on('pointerup', () => {
      this.showSettings();
    });
    this.input.settingButton.on('pointerup', () => {
      this.showSettings();
    });
    this.overlay.on('pointerup', () => {
      this.setIdle();
    });
    this.autospinsWindow.on('start', () => {
      this.setIdle();
    });
    this.autospinsWindow.on('close', () => {
      this.setIdle();
    });
    this.settingsWindow.on('close', () => {
      this.setIdle();
    });
  }

  init() {
    this.turboBtn = new TurboButton(this.model, this.input.turboBtn);
    this.minusBtn = new MinusButton(this.model, this.input.minusBtn);
    this.plusBtn = new PlusButton(this.model, this.input.plusBtn);
    this.spinBtn = new SpinButton(this.model, this.input.spinBtn);
    this.soundBtn = new SoundButton(this.model, this.input.soundBtn);
    this.autoSpinsWindow = new AutoSpinsWindow(
      this.model,
      this.autospinsWindow,
    );
    this.settingsWindow = new SettingsWindow(
      this.model,
      this.settingsWindow,
    );
    this.paytableBtn = new PaytableButton(
      this.model,
      this.input.infoBtn,
    );
  }
}

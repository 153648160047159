import { Grid } from './grid/grid';

export default class Layout {
  constructor() {
    this.serviceType = 'layout';
    this._grid = new Grid({ name: 'Grid' });
  }

  get grid() {
    return this._grid;
  }

  addGridLine(...args) {
    this.grid.addLine(...args);
  }

  getGridLineX(...args) {
    return this.grid.getLineX(...args);
  }

  getGridLineY(...args) {
    return this.grid.getLineY(...args);
  }

  resize() {
    this.grid.resize();
  }
}

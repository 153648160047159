import { App, Component } from '@growe/lightcore';

import JackpotsView from './view/JackpotsView';

export class Jackpots extends Component {
  subscribeToFsm() {
    this.fsm.subscribeToState('MAIN_GAME.START', this);
  }

  async onStateChange(stateEvent) {
    if (stateEvent.next === 'MAIN_GAME.START') {
      await this.show();
      stateEvent.onFinish(this.name);
    }
  }

  async show() {
    this.view = new JackpotsView();
    App.layers.getLayer('foreground').addChild(this.view);
    this.view.show();
  }
}
